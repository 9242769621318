import React, { useState, useEffect } from 'react';
import { Button, CardBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { strings } from '../../constants/localization';
import { Sundhedplus } from '../../config';
import LoadingSpinner from './common/LoadingSpinner/LoadingSpinner';
import {
  getDanishTextForOnboardingStepTitle,
  getOnboardingErrorReasonString,
  getErrorEndpointName,
} from '../../constants/utils';
import { creditApplicationAborted } from '../../actions/onboardingActions';
import { ReactComponent as InformationIcon } from '../../resources/icons/info-circle.svg';

const TechnicalError = ({ hasJsError }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.onboarding?.data);
  const visitedSteps = useSelector((state) => state.onboarding?.visitedSteps);
  const serverErrorDetails = useSelector(
    (state) => state.ui?.serverErrorDetails
  );
  // need to check if the application was already aborted, in order to call only once
  const creditApplicationAbortedApplication = useSelector(
    (state) => state.onboarding?.creditApplicationAbortedApplication
  );
  const creditApplicationDeniedApplication = useSelector(
    (state) => state.onboarding?.creditApplicationDeniedApplication
  );
  const [loading, setLoading] = useState(false);
  const [lastStepStored, setLastStepStored] = useState(null);
  const [denyReason, setDenyReason] = useState('');
  const [cprMismatch, setCprMismatch] = useState(false);

  const { creditApplicationUuid, uuid } = data || {};

  useEffect(() => {
    document.title = `Sundhed+ | ${strings.onboardingTechnicalErrorTitle}`;
    // if credit was already aborted, we don't need to push to dataLayer again
    if (!creditApplicationAbortedApplication) {
      window.dataLayer = window?.dataLayer || [];
      window.dataLayer.push({
        customPageUrl: '/ansoegning-afbrudt',
        stepTitle: 'onboarding - ansoegning afbrudt',
        event: 'onboarding',
      });
    }
  }, [creditApplicationAbortedApplication]);

  useEffect(() => {
    if (visitedSteps && visitedSteps?.length > 0) {
      const lastStep = visitedSteps[visitedSteps.length - 1];
      if (lastStep === 0) {
        if (visitedSteps.length > 1) {
          setLastStepStored(visitedSteps[visitedSteps.length - 2]);
        }
      } else {
        setLastStepStored(lastStep);
      }
    }
    //we should show the standard text
    setDenyReason('NONE');
  }, [visitedSteps]);

  useEffect(() => {
    let finalReason = '';
    // we only call /aborted if we have cpr, cuuid and the last step is after eSkat
    // and if there is no denied application and no aborted application
    if (
      denyReason &&
      creditApplicationUuid &&
      uuid &&
      lastStepStored >= 10 &&
      !creditApplicationDeniedApplication &&
      !creditApplicationAbortedApplication
    ) {
      setLoading(true);
      // if the error message is still empty, then set the custom reason
      const stepTitle = getDanishTextForOnboardingStepTitle(lastStepStored);
      const endpointName = getErrorEndpointName(serverErrorDetails);
      const { message, cause } = serverErrorDetails?.data || {};
      const simpleStringError = getOnboardingErrorReasonString(
        cause ?? message
      );

      if (cause || message) {
        if (endpointName.includes('checkEskatConsent')) {
          if (simpleStringError === 'Cpr missmatch') {
            setCprMismatch(true);
            finalReason = 'CPR missmatch - potential fraud';
          } else {
            finalReason = simpleStringError;
          }
        } else {
          finalReason = simpleStringError;
        }
      } else {
        finalReason = `Aborted at step ${lastStepStored} - ${stepTitle} (${
          hasJsError ? 'JS error' : 'API error'
        })${endpointName ? ' - ' + endpointName : ''}`;
      }

      dispatch(
        creditApplicationAborted(creditApplicationUuid, uuid, finalReason)
      ).finally(() => {
        setLoading(false);
      });
    }
  }, [denyReason]);

  return (
    <>
      {loading && (
        <LoadingSpinner
          title={strings.onboardingSpinnerTitle}
          hasBackBtn={false}
        />
      )}
      {cprMismatch && !loading && (
        <CardBody className='step-content'>
          <div className='step-title'>
            {strings.onboardingTechnicalErrorTitle}
          </div>
          <div className='step-subtitle center bold pl-5 pr-5 pb-3'>
            {strings.onboardingTechnicalErrorSubtitle}
          </div>
          <section className='warning-error-box'>
            <div className='head'>
              <div className='icon'>
                <InformationIcon className='infoIcon' />
              </div>

              {strings.onboardingDeniedCreditWarningTitle}
            </div>
            <div className='body'>
              <div className='message'>
                {strings.onboardingDeniedForCprMismatch}
              </div>
              <div className='message mb-0'>
                {strings.onboardingDeniedForCprMismatchP2}
              </div>
            </div>
          </section>

          <div className='actions-buttons step-buttons credit-denied-actions'>
            <Button
              className='btn btn-blue btn-success full-width'
              tag='a'
              href={Sundhedplus.presentationSite}
            >
              {strings.onboardingActionButtonToSundhed}
            </Button>
          </div>
          <footer className='step-footer'>
            <p className='m-0 p-0'>{strings.onboardingDeniedCreditHelp}</p>

            {strings.formatString(
              strings.onboardingDeniedCreditHelpPhone,
              <a
                className='color'
                href={`tel:${Sundhedplus.contactPhone}`}
                style={{ whiteSpace: 'nowrap' }}
              >
                {Sundhedplus.contactPhoneHtmlFormated}
              </a>
            )}
            <p className='m-0 p-0'>{strings.onboardingDeniedCreditHelpTime}</p>
          </footer>
        </CardBody>
      )}
      {!cprMismatch && !loading && (
        <CardBody className='step-content'>
          <div className='step-title'>
            {strings.onboardingTechnicalErrorTitle}
          </div>
          <div className='step-subtitle center bold pl-5 pr-5 pb-3'>
            {strings.onboardingTechnicalErrorSubtitle}
          </div>
          <section className='error-box'>
            <p>{strings.onboardingTechnicalErrorP1}</p>
            <p>
              {strings.formatString(
                strings.onboardingTechnicalErrorP2,
                <a className='color' href={`tel:${Sundhedplus.contactPhone}`}>
                  {Sundhedplus.contactPhoneHtmlFormated}
                </a>,
                <a
                  className='color'
                  href={`mailto:${Sundhedplus.contactEmail}`}
                >
                  {Sundhedplus.contactEmail}
                </a>
              )}
            </p>
          </section>
          <section>
            <p>{strings.onboardingTechnicalErrorP3}</p>
          </section>
          <div className='subsection mb-4'>
            <div className='title'>
              {strings.onboardingTechnicalSubsectionHeadline}
            </div>
            <p>
              {strings.formatString(
                strings.onboardingTechnicalSubsectionP,
                <a className='color' href={`mailto:${data?.email}`}>
                  {data?.email}
                </a>
              )}
            </p>
          </div>

          <div className='actions-buttons step-buttons'>
            <Button
              className='btn btn-blue btn-success full-width'
              tag='a'
              href={Sundhedplus.advantagesPublicPage}
            >
              {strings.onboardingDeniedCreditBtn}
            </Button>
          </div>
        </CardBody>
      )}
    </>
  );
};

export default TechnicalError;
