import React from 'react';
import DentiModal from '../../../common/DentiModal';
import { strings } from '../../../../constants/localization';
import { ReactComponent as LoadingGif } from '../../../../resources/images/loading.svg';

const ApplicationEndpointRequestLoadingModal = () => {
  return (
    <div>
      <DentiModal
        modalClass='modal-dialog-centered'
      >
        <div className='loading text-center'>
          <p className='waiting-text'>{strings.loadingMessage}</p>
          <LoadingGif className='loadingGif' />
        </div>
      </DentiModal>
    </div>
  )
}

export default ApplicationEndpointRequestLoadingModal;
