import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { strings } from '../../../../constants/localization';
import DentiModal from '../../../common/DentiModal';
import ConfirmationModal from '../../../common/ConfirmationModal';
import {
  denyCreditApplication,
  setApplicationDenyReason,
} from '../../../../actions/onboardingApplicationsActions';

const DenyCreditModal = ({ closeModal, reloadApplication }) => {
  const [loadingAction, setLoadingAction] = useState(false);
  const [denyReason, setDenyReason] = useState('');
  const dispatch = useDispatch();

  const activeApplicationListItem = useSelector(
    (state) => state.onboardingApplication?.activeApplicationListItem
  );
  const { clientData, creditApplication } = activeApplicationListItem || {};
  const creditApplicationUuid = creditApplication?.creditApplicationUuid;
  const uuid = clientData?.leadUuid;

  // when the modal opens clear the old deny reason
  useEffect(() => {
    dispatch(setApplicationDenyReason(''));
  }, []);

  useEffect(() => {
    if (denyReason) {
      dispatch(setApplicationDenyReason(denyReason));
    }
  }, [denyReason]);

  return (
    <DentiModal
      key={1}
      close={() => closeModal()}
      modalClass='confirm-modal'
      modalClassName='confirm-action'
    >
      <ConfirmationModal
        loading={loadingAction}
        confirmAction={() => {
          setLoadingAction(true);
          dispatch(denyCreditApplication(creditApplicationUuid, uuid)).finally(
            () => {
              setLoadingAction(false);
              reloadApplication();
              closeModal();
            }
          );
        }}
        confirmBtnText={strings.applicationDenyBtn}
        cancelAction={() => closeModal()}
        cancelBtnText={strings.applicationCancelActionBtn}
      >
        <div>
          <p className='title'>
            {strings.applicationApproveDenyReasonHeadline}
          </p>
          <p className='confirm-message'>
            <span className='reason-wrapper'>
              <textarea
                className='form-control'
                value={denyReason}
                placeholder={strings.applicationApproveDenyReasonPlaceholer}
                onChange={(e) => {
                  setDenyReason(e.target.value);
                }}
              />
            </span>
          </p>
        </div>
      </ConfirmationModal>
    </DentiModal>
  );
};

export default DenyCreditModal;
