import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody } from 'reactstrap';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepSuccess from './StepSuccess';
import StepExpiredCredit from './StepExpiredCredit';
import StepNotFound from './StepNotFound';
import StepClosedCredit from './StepClosedCredit';
import StepSendAppLink from './StepSendAppLink';
import StepSendAppSuccess from './StepSendAppSuccess';
import StepClientAssigned from './StepClientAssigned';
import { moment } from '../../constants';
import {
  addPatientCredit,
  createCreditPageByEmail,
  createCreditPageBySms,
} from '../../actions/createCreditActions';
import { toggleServerModalError } from '../../actions/uiElementsActions';
import { ReactComponent as LoadingGif } from '../../resources/images/loading.svg';
import { getLeads } from '../../actions/leadActions';

const AddPatient = (props) => {
  const { email, phone, toggleModal } = props || {};
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const activeCompany = user?.activeCompany;
  const [state, setState] = useState({
    activeStep: props.activeStep || 1,
    phone: phone || '', // for no credit patient, send app link should have prefilled email and phone
    email: email || '',
    loadingStep: false,
  });
  const [patientCpr, setPatientCpr] = useState(null);

  const nextStep = (updateData = null) => {
    let hasCredit = false;
    let userInfo = null;
    let contactInfo = null;
    let appLink = null;

    if (updateData !== null) {
      setState(updateData);

      if (updateData.hasCredit) {
        hasCredit = updateData.hasCredit;
      }

      if (updateData.clientCpr) {
        setPatientCpr(updateData.clientCpr);
        userInfo = {
          ...updateData,
          companyReferenceNumber: updateData.companyReferenceNumber,
        };
      }

      if (updateData.email || updateData.phone) {
        contactInfo = updateData;
      }

      if (updateData.appLink !== null) {
        appLink = updateData.appLink;
      }
    }
    switch (state.activeStep) {
      case 1:
        if (hasCredit) {
          // StepTwo
          setState({
            activeStep: 2,
          });
        } else {
          // StepSendAppLink
          setState({
            activeStep: 7,
          });
        }
        break;
      case 2:
        setState({
          loadingStep: true,
        });
        if (userInfo !== null && Object.keys(userInfo).length > 0) {
          dispatch(addPatientCredit(userInfo))
            .then((response) => {
              if (response.status === 200 && response.data !== null) {
                const data = response.data;

                if (data.firstName === null && data.lastName === null) {
                  // StepNotFound
                  setState({
                    loadingStep: false,
                    activeStep: 5,
                    ...data,
                  });
                } else if (
                  data.creditFacilityStatus === null &&
                  data.expirationDate === null
                ) {
                  // StepClientAssigned
                  setState({
                    loadingStep: false,
                    activeStep: 9,
                    ...data,
                  });
                } else if (
                  [
                    'SUSPENDED',
                    'LATE_PAYMENT',
                    'DEPRECATED',
                    'DELETED',
                  ].indexOf(data.creditFacilityStatus) > -1
                ) {
                  // StepClosedCredit
                  setState({
                    loadingStep: false,
                    activeStep: 6,
                    ...data,
                  });
                } else if (
                  data.expirationDate !== null &&
                  moment(data.expirationDate, 'YYYY-MM-DD').diff(
                    moment(new Date()).format('YYYY-MM-DD')
                  ) < 0
                ) {
                  // StepExpiredCredit
                  setState({
                    loadingStep: false,
                    activeStep: 4,
                    ...data,
                  });
                } else {
                  // StepSuccess
                  dispatch(getLeads());
                  setState({
                    loadingStep: false,
                    activeStep: 3,
                    ...data,
                  });
                }
              } else if (response.status === 400) {
                dispatch(toggleServerModalError(false));
                // StepNotFound
                setState({
                  loadingStep: false,
                  activeStep: 5,
                });
              }
            })
            .catch(() => {
              dispatch(toggleModal());
            });
        }
        break;
      case 5:
        if (appLink) {
          // StepSendAppLink
          setState({
            loadingStep: false,
            activeStep: 7,
          });
        } else {
          // StepTwo
          setState({
            loadingStep: false,
            activeStep: 2,
          });
        }
        break;
      case 7:
        // if the contact info is from previous step use that, otherwise use the one from the props if any
        const finalContactInfo =
          contactInfo !== null
            ? contactInfo
            : {
              email: email,
              phone: phone,
            };
        // fix for activeStep becomming undfined between steps
        setState({
          activeStep: 7,
        });
        if (finalContactInfo && Object.keys(finalContactInfo).length > 0) {
          let sendBySmsFailed = false;

          if (finalContactInfo?.phone) {
            dispatch(
              createCreditPageBySms(finalContactInfo.phone, updateData.clinic)
            )
              .then(() => {
                // StepSendAppSuccess
                setState({
                  phone: finalContactInfo.phone,
                  activeStep: 8,
                });
              })
              .catch((error) => {
                sendBySmsFailed = true;
                console.error(error);
                dispatch(toggleModal());
              });
          }

          if (finalContactInfo?.email) {
            dispatch(
              createCreditPageByEmail(finalContactInfo.email, updateData.clinic)
            )
              .then(() => {
                // StepSendAppSuccess
                if (!sendBySmsFailed) {
                  setState({
                    email: finalContactInfo.email,
                    activeStep: 8,
                  });
                }
              })
              .catch((error) => {
                console.error(error);
                dispatch(toggleModal());
              });
          }
        }
        break;
      default:
        setState(() => ({
          activeStep: state.activeStep + 1,
        }));
        break;
    }
  };

  const renderActiveStep = () => {
    switch (state.activeStep) {
      case 1:
        return <StepOne nextStep={nextStep} user={user} />;
      case 2:
        return <StepTwo nextStep={nextStep} user={user} />;
      case 3:
        return (
          <StepSuccess
            closeModal={toggleModal}
            user={user}
            stateInfo={state}
            patientCpr={patientCpr}
          />
        );
      case 4:
        return <StepExpiredCredit closeModal={toggleModal} stateInfo={state} />;
      case 5:
        return (
          <StepNotFound
            closeModal={toggleModal}
            nextStep={nextStep}
            stateInfo={state}
          />
        );
      case 6:
        return <StepClosedCredit closeModal={toggleModal} stateInfo={state} />;
      case 7:
        return (
          <StepSendAppLink
            nextStep={nextStep}
            user={user}
            activeCompany={activeCompany}
            email={email}
            phone={phone}
          />
        );
      case 8:
        return (
          <StepSendAppSuccess
            closeModal={toggleModal}
            email={state.email || ''}
            phone={state.phone || ''}
          />
        );
      case 9:
        return (
          <StepClientAssigned
            closeModal={toggleModal}
            stateInfo={state}
            patientCpr={patientCpr}
          />
        );
      default:
        return <div />;
    }
  };
  return (
    <Card
      className={`add-patient-container ${state.activeStep === 400 ? 'error' : ''
        }`}
    >
      {state.loadingStep ? (
        <CardBody className='d-flex justify-content-center align-items-center'>
          <LoadingGif className='loadingGif' />
        </CardBody>
      ) : (
        <CardBody>{renderActiveStep()}</CardBody>
      )}
    </Card>
  );
};

export default AddPatient;
