import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

const DentiModal = (props) => {
  const {
    close,
    modalClass,
    modalClassName,
    children,
    backdrop,
    closeBtn,
    footerCloseBtn,
    footerCloseBtnText,
  } = props || {};

  const modalClassCombined = modalClass
    ? `${modalClass} modal-container denti-modal`
    : 'modal-container denti-modal';

  return (
    <CSSTransition appear exit timeout={600}>
      <div>
        <Modal
          isOpen
          toggle={close}
          className={modalClassCombined}
          modalClassName={modalClassName}
          backdrop={backdrop}
          keyboard={false}
          autoFocus={false}
        >
          {closeBtn && (
            <span className='close' onClick={close}>
              <i className='lnir lnir-close' />
            </span>
          )}

          <ModalBody>{children}</ModalBody>

          {footerCloseBtn && (
            <div className='button-actions-footerCloseBtn'>
              <Button color='blue' className='btn btn-cancel' onClick={close}>
                {footerCloseBtnText}
              </Button>
            </div>
          )}
        </Modal>
      </div>
    </CSSTransition>
  );
};

DentiModal.propTypes = {
  close: PropTypes.func,
  modalClassName: PropTypes.string,
  modalClass: PropTypes.string,
  children: PropTypes.node,
  footerCloseBtnText: PropTypes.string,
  closeBtn: PropTypes.bool,
  footerCloseBtn: PropTypes.bool,
};

export default DentiModal;
