import React, { useEffect, useState } from 'react';
import { Button, Fade } from 'reactstrap';
import './renewLinkModal.scss';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { ReactComponent as WarningIcon } from '../../../resources/icons/warning.svg';
import { ReactComponent as ArrowUp } from '../../../resources/icons/arrow-up.svg';
import { ReactComponent as Clock } from '../../../resources/icons/clock-alt-1.svg';

import { strings } from '../../../constants/localization';
import SimpleCheckbox from '../../common/SimpleCheckbox/SimpleCheckbox';
import { useDispatch } from 'react-redux';
import { sendRenewLinkTolead } from '../../../actions/leadActions';
// eslint-disable-next-line import/no-unresolved
import { AppDispatch } from 'redux-custom';
import ButtonLoader from '../../common/ButtonLoader/ButtonLoader';

interface SendLinkToRenewProps {
  email: string;
  phone: string;
  creditFacilityInformation: {
    number: string;
  };
  closeModal: () => void;
}

const SendLinkToRenew: React.FC<SendLinkToRenewProps> = ({
  email,
  phone,
  creditFacilityInformation,
  closeModal,
}) => {
  const [step, setStep] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [checkSendSms, setCheckSendSms] = useState<boolean>(true);
  const [checkSendEmail, setCheckSendEmail] = useState<boolean>(true);
  const [noCheckBoxSelected, setNoCheckBoxSelected] = useState<boolean>(false);

  const dispatch: AppDispatch = useDispatch<AppDispatch>();

  const sendRenewLink = () => {
    dispatch(
      sendRenewLinkTolead(
        creditFacilityInformation.number,
        checkSendEmail,
        checkSendSms
      )
    ).then(() => {
      setLoading(false);
      setStep(2);
    });
  };

  // useEffect that if no checkbox is selected, it will set nocheckboxselected to true
  useEffect(() => {
    if (!checkSendEmail && !checkSendSms) {
      setNoCheckBoxSelected(true);
    } else {
      setNoCheckBoxSelected(false);
    }
  }, [checkSendEmail, checkSendSms]);

  const renderRenewLinkOptions = () => {
    return (
      <>
        <div className='step-header'>
          <div className='logo'>
            <i className='lnil lnil-message-forward' />
          </div>
          <div className='title-text-header'>
            <h2>{strings.sendRenewLinkModalTitle}</h2>
          </div>
          <div className='step-subtitle'>
            <p>{strings.sendRenewLinkModalP1}</p>
            <p>{strings.sendRenewLinkModalP2}</p>
          </div>
        </div>
        <div className='step-options'>
          <div className='title-text'>
            <h3>{strings.sendRenewLinkModalCheckBoxTitle}</h3>
          </div>
          <div className='checkbox-options'>
            <SimpleCheckbox
              customStyles='input-group'
              content={strings.formatString(
                strings.sendRenewLinkModalCheckBoxSMS,
                phone
              )}
              checked={checkSendSms}
              handleClick={() => {
                setCheckSendSms(!checkSendSms);
              }}
            />
          </div>
          <div className='checkbox-options'>
            <SimpleCheckbox
              customStyles='input-group'
              content={strings.formatString(
                strings.sendRenewLinkModalCheckBoxEmail,
                email
              )}
              checked={checkSendEmail}
              handleClick={() => {
                setCheckSendEmail(!checkSendEmail);
              }}
            />
          </div>
        </div>
        <div className='button-actions'>
          <Button className='btn btn-cancel' onClick={() => closeModal()}>
            {strings.sendRenewLinkModalBtnCancel}
          </Button>
          <Button
            color='blue'
            className='btn '
            disabled={loading || noCheckBoxSelected}
            onClick={() => {
              setLoading(true);
              sendRenewLink();
            }}
          >
            {loading ? <ButtonLoader /> : strings.sendRenewLinkModalBtnSend}
          </Button>
        </div>
      </>
    );
  };

  const linkWasSuccessfullySent = () => {
    return (
      <>
        <div className='step-header link-sent'>
          <div className='checkmark'>
            <i className='lnil lnil-checkmark-circle' />
          </div>
          <div className='title-text-header'>
            <h2>{strings.sendRenewLinkModalSuccessTitle}</h2>
          </div>
          <div className='step-subtitle text-center'>
            <h3>{strings.sendRenewLinkModalSuccessSubTitle}</h3>
          </div>
        </div>
        <div className='step-options'>
          <div className='checkbox-options information'>
            <div className='container'>
              <div className='icon clock'>
                <Clock className='icon clock' />
              </div>
              <div className='text'>
                {strings.sendRenewLinkModalSuccessInfoBox1}
              </div>
            </div>
          </div>
          <div className='checkbox-options information'>
            <div className='container'>
              <div className='icon arrow'>
                <ArrowUp className='icon arrow turn-down' />
                <ArrowUp className='icon arrow arrow-right' />
              </div>
              <div className='text'>
                {strings.sendRenewLinkModalSuccessInfoBox2}
              </div>
            </div>
          </div>
          <div className='checkbox-options information'>
            <div className='container'>
              <div className='icon warning'>
                <WarningIcon className='icon warning' />
              </div>

              <div className='text'>
                {strings.sendRenewLinkModalSuccessInfoBox3}
              </div>
            </div>
          </div>
        </div>
        <div className='button-actions'>
          <Button color='blue' className='btn ' onClick={() => closeModal()}>
            {strings.sendRenewLinkModalBtnClose}
          </Button>
        </div>
      </>
    );
  };

  const renderComponent = () => {
    switch (step) {
      case 1:
        return renderRenewLinkOptions();
      case 2:
        return linkWasSuccessfullySent();
      default:
        return renderRenewLinkOptions();
    }
  };

  return (
    <div className='renrew-modal-container'>
      <div className='renew-modal-content'>
        <TransitionGroup appear timeout={600} className='slide-group'>
          <CSSTransition
            key={step}
            timeout={300}
            classNames='fade'
            className='transition-container'
          >
            <Fade show={step}>{renderComponent()}</Fade>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </div>
  );
};

export default SendLinkToRenew;
