import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Button } from 'reactstrap';
import { config } from './../../../../config';
import DentiModal from '../../../common/DentiModal';
import ConfirmationModal from '../../../common/ConfirmationModal';
import { ROUTE_COMPANIES } from '../../../../constants/routes';
import { getCustomerIOLink } from '../../../../constants/utils';
import {
  moment,
  ROLE_ADMIN,
  ROLE_DENTIST,
  ROLE_MANAGER,
} from '../../../../constants';
import { strings } from '../../../../constants/localization';
import KebabMenu from '../../../common/KebabMenu/KebabMenu';
import AddUserModal from './AddUser/AddUserModal';
import './style.scss';
import {
  reinviteUser,
  changePassword,
  deleteUser,
} from '../../../../actions/userActions';
import LoadingMessage from '../../../statusMessages/LoadingMessage';

function ListOfAssociatedUsers({ companyChainId, update }) {
  const companiList =
    useSelector((state) => state.companyChain?.chainLocations) || [];
  const usersAssociated =
    useSelector((state) => state.companyChain?.usersAssociated) || [];
  const [addUser, setAddUser] = useState(false);
  const [deleteConfirmationPopup, setDeleteConfirmationPopup] = useState(false);
  const [resetPasswordConfirmation, setResetPasswordConfirmation] =
    useState(false);
  const [reinviteUserConfirmation, setReinviteUserConfirmation] =
    useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const chainUsersLoading =
    useSelector((state) => state.companyChain?.chainUsersLoading) || false;
  const dispatch = useDispatch();

  const toggleAddNewUser = () => {
    setAddUser(!addUser);
    setSelectedUser({});
    setEditMode(false);
  };
  const clickDelete = (user) => {
    setSelectedUser(user);
    setDeleteConfirmationPopup(true);
  };

  const clickEdit = (user) => {
    setSelectedUser(user);
    setAddUser(true);
    setEditMode(true);
  };

  const clickReinvite = (user) => {
    setSelectedUser(user);
    setReinviteUserConfirmation(true);
  };

  const clickResetPassword = (user) => {
    setResetPasswordConfirmation(true);
    setSelectedUser(user);
  };

  const deleteConfirm = () => {
    dispatch(deleteUser(selectedUser.username))
      .then((response) => {
        if (response?.payload.status === 200) {
          update();
          setDeleteConfirmationPopup(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const resetPassword = () => {
    setResetPasswordConfirmation(false);
    dispatch(changePassword(selectedUser.username))
      .then((response) => {
        if (response?.payload.status === 200) {
          setReinviteUserConfirmation(false);
          update();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const reinvite = () => {
    dispatch(reinviteUser(selectedUser.username))
      .then((response) => {
        if (response?.payload.status === 200) {
          setReinviteUserConfirmation(false);
          update();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Card className='custom-card list_of_associated_users table-responsive-md'>
      <div className='chains_table table-size'>
        <div className='header_wrapper'>
          <div className='lead_wrapper'>
            <div className='name_field'>{strings.name}</div>
            <div className='email_field'>{strings.email}</div>
            <div className='role_field'>{strings.chainUserRole}</div>
            <div className='login_field'>{strings.chainUserLatestLogin}</div>
            <div className='seeDetailsButton' />
          </div>
        </div>
        <div className='table_separator' />
        <div className='contentWrapper'>
          {chainUsersLoading && <LoadingMessage />}
          {usersAssociated &&
            usersAssociated.map((user, i) => (
              <div className='lead_row' key={`user-${i}`}>
                <div className='name_field'>{`${
                  user.firstname ? `${user.firstname} ` : ''
                }${user.lastname ? user.lastname : ''}`}</div>
                <div className='email_field'>{user.username}</div>
                <div className='role_field'>
                  {Object.entries(user.user_role).map(([key, value], j) => (
                    <div className='role' key={`${key}${j}`}>
                      {value.map((item) => (
                        <div key={item}>
                          {item === ROLE_DENTIST && (
                            <>
                              {`${strings.chainEmployee} (`}
                              <Link
                                to={{
                                  pathname: ROUTE_COMPANIES,
                                  state: { ref: key },
                                }}
                              >
                                <span className='has-tooltip'>
                                  {key}
                                  <span className='tooltip'>
                                    {user.company_name}
                                  </span>
                                </span>
                              </Link>
                              {')'}
                            </>
                          )}
                          {item === ROLE_ADMIN && (
                            <>{strings.chainAdministrator}</>
                          )}
                          {item === ROLE_MANAGER && <>{strings.chainManager}</>}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
                <div className='login_field'>
                  {user.user_status === 'ACTIVE' &&
                    user.last_login_date !== null &&
                    moment(user.last_login_date).format('DD-MM-yyyy')}
                  {user.user_status === 'ACTIVE' &&
                    user.last_login_date === null && (
                      <span>{strings.chainNever}</span>
                    )}
                  {user.user_status === 'PENDING' && (
                    <span className='pending'>
                      {strings.chainPendingInvitation}
                    </span>
                  )}
                </div>
                <div className='seeDetailsButton'>
                  {/* for the last item in the list, make the menu appear on top */}
                  <KebabMenu
                    menuPlacement={
                      i === usersAssociated.length - 1 ? 'top' : ''
                    }
                  >
                    <div
                      className='KebabMenu-item'
                      onClick={() => {
                        clickEdit(user);
                      }}
                    >
                      <span className='icon'>
                        <i className='lnir lnir-pencil' />
                      </span>
                      <span className='text'>{strings.edit}</span>
                    </div>
                    <div
                      className='KebabMenu-item'
                      onClick={() => {
                        const linkFilters = getCustomerIOLink(new Date());
                        const customerIoURL = config.customerIO.replace(
                          '$0',
                          user.id
                        );
                        window.open(
                          `${customerIoURL}/${linkFilters}`,
                          '_blank'
                        );
                      }}
                    >
                      <span className='icon'>
                        <i className='lnir lnir-reply' />
                      </span>
                      <span className='text'>{strings.seeCustomerIO}</span>
                    </div>
                    {user.user_status !== 'PENDING' && (
                      <div
                        className='KebabMenu-item'
                        onClick={() => {
                          clickResetPassword(user);
                        }}
                      >
                        <span className='icon'>
                          <i className='lnir lnir-lock-alt' />
                        </span>
                        <span className='text'>
                          {strings.chainResetPAssword}
                        </span>
                      </div>
                    )}

                    {user.user_status === 'PENDING' && (
                      <div
                        className='KebabMenu-item'
                        onClick={() => {
                          clickReinvite(user);
                        }}
                      >
                        <span className='icon'>
                          <i className='lnir lnir-undo' />
                        </span>
                        <span className='text'>
                          {strings.chainResendInvitation}
                        </span>
                      </div>
                    )}

                    <div
                      className='KebabMenu-item'
                      onClick={() => {
                        clickDelete(user);
                      }}
                    >
                      <span className='icon'>
                        <i className='lnir lnir-trash-can' />
                      </span>
                      <span className='text'>{strings.delete}</span>
                    </div>
                  </KebabMenu>
                </div>
              </div>
            ))}
          {companiList.length > 0 && (
            <div className='button_area'>
              <Button color='blue' onClick={toggleAddNewUser}>
                <i className='lnir lnir-circle-plus' />
                {strings.chainAddUser}
              </Button>
            </div>
          )}
          {companiList.length === 0 && (
            <div className='NoCompanis'>
              <div className='NoCompanis_container'>
                {strings.userNoCompanySelected}
              </div>
            </div>
          )}
        </div>
      </div>

      {addUser && (
        <AddUserModal
          selectedUser={selectedUser}
          closeModal={toggleAddNewUser}
          companiList={companiList}
          companyChainId={companyChainId}
          update={update}
          editMode={editMode}
        />
      )}
      {deleteConfirmationPopup && (
        <DentiModal
          key={1}
          close={() => {
            setDeleteConfirmationPopup(false);
          }}
          modalClass='confirm-modal'
          modalClassName='confirm-action'
        >
          <ConfirmationModal
            confirmAction={() => {
              deleteConfirm();
            }}
            cancelAction={() => {
              setDeleteConfirmationPopup(false);
            }}
          >
            <div>
              <p className='title'>{strings.deleteInvoiceTitle}</p>
              <p className='confirmfirstline-message'>
                {strings.chainDeleteUserNote}
                {selectedUser.username && (
                  <>
                    <b>{selectedUser.username}</b>
                  </>
                )}
                ?
              </p>
              <p className='confirm-message'>
                {strings.chainInvoicesRegisteredNote}
              </p>
            </div>
          </ConfirmationModal>
        </DentiModal>
      )}
      {resetPasswordConfirmation && (
        <DentiModal
          key={1}
          close={() => {
            setResetPasswordConfirmation(false);
          }}
          modalClass='confirm-modal'
          modalClassName='confirm-action'
        >
          <ConfirmationModal
            confirmAction={() => {
              resetPassword();
            }}
            cancelAction={() => {
              setResetPasswordConfirmation(false);
            }}
          >
            <div>
              <p className='title'>{strings.chainUserConfirmResetTitle}</p>
              <p className='confirm-message'>
                {strings.chainSendUserNote1}
                {selectedUser.username && (
                  <>
                    <b>{selectedUser.username}</b>
                  </>
                )}
                {strings.chainSendUserNote2}
              </p>
            </div>
          </ConfirmationModal>
        </DentiModal>
      )}
      {reinviteUserConfirmation && (
        <DentiModal
          key={1}
          close={() => {
            setReinviteUserConfirmation(false);
          }}
          modalClass='confirm-modal'
          modalClassName='confirm-action'
        >
          <ConfirmationModal
            confirmAction={() => {
              reinvite();
            }}
            cancelAction={() => {
              setReinviteUserConfirmation(false);
            }}
          >
            <div>
              <p className='title'>{strings.chainResendInvitation}</p>
              <p className='confirm-message'>
                {strings.chainResendInvitationNote}
                {selectedUser.username && (
                  <>
                    <b>{selectedUser.username}</b>
                  </>
                )}
                ?
              </p>
            </div>
          </ConfirmationModal>
        </DentiModal>
      )}
    </Card>
  );
}

ListOfAssociatedUsers.propTypes = {
  usersAssociated: PropTypes.array,
  companiList: PropTypes.array,
  companyChainId: PropTypes.number,
  update: PropTypes.func,
};

export default ListOfAssociatedUsers;
