import React, { useEffect, useRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useSelector, useDispatch, connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import LeadInformation from './LeadInformation/LeadInformation';
import PaymentStatus from './PaymentStatus';
import CalendarCreditInfo from './lead-options/CalendarCreditInfo/CalendarCreditInfo';
import CreditAmount from './lead-options/CreditAmount/CreditAmount';
import NoPatientCredit from './lead-options/NoPatientCredit/NoPatientCredit';
import LeadOptions from './LeadOptions';
import RequiresActionAlert from './RequiresActionAlert';
import InternalNote from '../../common/InternalNote';
import { REQUIRES_ACTION } from '../../../constants';
import { isAdmin, isDeletedOrDeprecated } from '../../../constants/utils';
import {
  setCurrentLead,
  updateLeadInternalNote,
  updateActivePatientInternalNote,
  updatePatientInLeadsList,
} from '../../../actions/leadActions';
import {
  trackVerticalStyle,
  thumbVerticalStyle,
} from '../../../constants/styleScrollbar';

import DunningStatusCard from './DunningStatusCard';
import LoadingMessage from '../../statusMessages/LoadingMessage';
import { strings } from '../../../constants/localization';
import PaymentAgreementsList from '../../invoices/PaymentAgreementsList';
import InvoiceHistoryList from '../../invoices/InvoiceHistoryList';

const LeadDetails = (props) => {
  const scrollRef = useRef(null);
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.user);
  const isUserAdmin = isAdmin(currentUser);
  const lead = useSelector((state) => state.lead.activeLead);
  const clientLoading = useSelector((state) => state.lead.clientLoading);
  let creditIsValid = true;
  // lead is member, no credit yet
  if (lead.creditFacilityInformation === null) {
    creditIsValid = false;
  } else if (
    (lead.creditFacilityInformation.status === 'DISABLED' ||
      lead.creditFacilityInformation.status === 'REJECTED') &&
    lead.creditFacilityInformation.installmentLoanInformations.length === 0
  ) {
    creditIsValid = false;
  }

  const resetScrollPosition = () => {
    if (scrollRef.current !== null) {
      scrollRef.current.view.scrollTop = 0;
    }
  };

  // reset scroll position on mounting
  useEffect(() => {
    resetScrollPosition();
  }, []);

  // reset scroll position on lead change
  useEffect(() => {
    resetScrollPosition();
  }, [lead]);

  return (
    <>
      <Scrollbars
        ref={scrollRef}
        renderTrackVertical={({ style, ...props }) => (
          <div {...props} style={{ ...style, ...trackVerticalStyle }} />
        )}
        renderThumbVertical={({ style, ...props }) => (
          <div {...props} style={{ ...style, ...thumbVerticalStyle }} />
        )}
      >
        <div className='main-content'>
          {/* alert for require action */}
          {lead.leadStatus === REQUIRES_ACTION && <RequiresActionAlert />}

          <button
            type='button'
            className='btn btn-white go-back-to-list'
            onClick={() => dispatch(setCurrentLead(undefined))}
          >
            <i className='lnil lnil-chevron-left'></i>
            <span>{strings.goToClientsList}</span>
          </button>

          {clientLoading && <LoadingMessage />}

          {/* lead information form */}
          <Row>
            <Col className='col-lg-12'>
              <LeadInformation activeLead={lead} user={currentUser} />
            </Col>
          </Row>

          {/* ONLY FOR CLIENTS WITH CREDIT FACILITY */}
          {lead.creditFacilityInformation && (
            <>
              {/* dunning status card */}
              {isUserAdmin &&
                lead.leadClientInformation.clientDunningStatus && (
                  <Row>
                    <Col className='col-lg-12'>
                      <DunningStatusCard
                        currentClient={Object.assign({}, lead)}
                      />
                    </Col>
                  </Row>
                )}

              {/* internal note */}
              {isUserAdmin && (
                <Row>
                  <Col
                    xs='12'
                    lg={
                      creditIsValid &&
                        lead.creditFacilityInformation.installmentLoanInformations
                          .length > 0
                        ? '6'
                        : '12'
                    }
                  >
                    <InternalNote
                      id={lead.id}
                      activeObject={Object.assign({}, lead)}
                      saveInternalNote={props.updateLeadInternalNote}
                      updateInternalNote={props.updateActivePatientInternalNote}
                      updateInfoInList={props.updatePatientInLeadsList}
                    />
                  </Col>
                  {creditIsValid &&
                    lead.creditFacilityInformation.installmentLoanInformations
                      .length > 0 && (
                      <Col xs='12' lg='6'>
                        <PaymentStatus lead={lead} />
                      </Col>
                    )}
                </Row>
              )}

              {(creditIsValid || isUserAdmin) && (
                <Row>
                  {/* calendar credit info */}
                  <Col xs='12' lg='6'>
                    <CalendarCreditInfo
                      creditDetails={lead.creditFacilityInformation}
                    />
                  </Col>
                  {/* calendar credit available amount */}
                  <Col xs='12' lg='6'>
                    <CreditAmount
                      creditDetails={lead.creditFacilityInformation}
                    />
                  </Col>
                </Row>
              )}
              {/* payment agreement list */}
              {isUserAdmin &&
                !isDeletedOrDeprecated(
                  lead.creditFacilityInformation.status
                ) && (
                  <Row>
                    <Col xs='12' lg='12'>
                      <PaymentAgreementsList lead={lead} />
                    </Col>
                  </Row>
                )}

              {/* invoice history */}
              {creditIsValid &&
                !isDeletedOrDeprecated(
                  lead.creditFacilityInformation.status
                ) && (
                  <Row>
                    <Col xs='12' lg='12'>
                      <InvoiceHistoryList lead={lead} />
                    </Col>
                  </Row>
                )}

              {/* lead tabs */}
              {isUserAdmin && (
                <Row>
                  <Col xs='12' lg='12'>
                    <LeadOptions lead={lead} />
                  </Col>
                </Row>
              )}
            </>
          )}

          {/* no credit */}
          {!creditIsValid && !isUserAdmin && <NoPatientCredit />}
        </div>
      </Scrollbars>
    </>
  );
};
export default connect(null, {
  updateLeadInternalNote,
  updateActivePatientInternalNote,
  updatePatientInLeadsList,
})(LeadDetails);
