import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getPublicCompanyCategories } from '../../actions/companyActions';
import { getCompanyStatuses } from '../../actions/statusActions';
import CompaniesList from './companies-list/CompaniesList';
import LoadingMessage from '../statusMessages/LoadingMessage';
import CompanyDetails from './company-details/CompanyDetails';
import { isAuthenticated } from '../../constants/utils';
import { VIEW_ALL } from '../../constants';
import WelcomePage from '../common/WelcomePage';

const Companies = (props) => {
  const { location, history } = props || {};
  const dispatch = useDispatch();
  const activeCompany = useSelector((state) => state.company.activeCompany);
  const companiesList = useSelector((state) => state.company?.companiesList);
  const globalLoading = useSelector((state) => state.company.globalLoading);

  useEffect(() => {
    dispatch(getPublicCompanyCategories());
    if (isAuthenticated()) {
      // it also works well with:  this.history.location.state
      if (location.state === undefined) {
        dispatch(getCompanyStatuses(VIEW_ALL));
      }
    }
  }, [dispatch, location]);

  return (
    <>
      {globalLoading && <LoadingMessage />}
      <Row className='app-row-height'>
        <Col
          xs='12'
          md='12'
          xl='4'
          className='entities-list-section p-0 app-col-height'
        >
          <CompaniesList
            activeCompany={activeCompany}
            toggleMenu={props.toggleMenu}
            displayCompany={location.state}
            location={location}
            history={history}
          />
        </Col>
        <Col
          xs='12'
          md='12'
          xl='8'
          className={`entity-details-section ${activeCompany !== undefined && 'active'
            }`}
        >
          {companiesList && companiesList.content.length > 0 ? (
            <>
              {activeCompany !== undefined ? (
                <CompanyDetails
                  activeCompany={activeCompany}
                  history={history}
                />
              ) : (
                <WelcomePage
                  toggleModal={props.toggleModal}
                  modalShow={props.modalShow}
                  toggleCreateNewInvoice={props.toggleCreateNewInvoice}
                  createNewInvoice={props.createNewInvoice}
                />
              )}
            </>
          ) : (
            <WelcomePage
              toggleModal={props.toggleModal}
              modalShow={props.modalShow}
              toggleCreateNewInvoice={props.toggleCreateNewInvoice}
              createNewInvoice={props.createNewInvoice}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default Companies;
