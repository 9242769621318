import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { success, error as notifError } from 'react-notification-system-redux';
import { strings } from '../../constants/localization';
import {
  moment,
  momentFormatLong,
  notificationDismissDuration,
} from '../../constants';
import {
  currencyFormatDA,
  formatCurrencyDaStandard,
  addNotification,
  formatInterestRate,
} from '../../constants/utils';
import { paymentAgreementStatus } from '../../constants/mappings';
import {
  getLoanDetails,
  getInstallmentByNumber,
  settleLoan,
  getLoanSignatureData,
} from '../../actions/installmentLoanActions';
import { getUpdatedPatientCreditFacility } from '../../actions/leadActions';
import { toggleServerModalError } from '../../actions/uiElementsActions';
import { getLogsCreditFacility } from '../../actions/logActions';
import KebabMenu from '../common/KebabMenu/KebabMenu';
import DentiModal from '../common/DentiModal';
import ConfirmationModal from '../common/ConfirmationModal';
import LoanDetails from './LoanDetails';
import InvoicePaymentPlan from './InvoicePaymentPlan';
import AgreementModalOptions from './AgreementModalOptions';
import { ReactComponent as PercentageIcon } from '../../resources/icons/percentage-alt.svg';
import { ReactComponent as DollarSymbol } from '../../resources/icons/dollar-symbol.svg';

const PaymentAgreementsListItem = (props) => {
  const { invoice, lead } = props || {};
  const dispatch = useDispatch();
  const signatureData = useSelector(
    (state) => state.installmentLoan?.signatureData
  );
  const [loanPlan, setLoanPlan] = useState();
  const [loanPlanModal, setLoanPlanModal] = useState(false);
  const [invoicePrintDetails, setInvoicePrintDetails] = useState({});
  const [showConfirmSettleModal, setShowConfirmSettleModal] = useState(false);
  const [loadingConfirmBtn, setLoadingConfirmBtn] = useState(false);
  const [currentAgreement, setCurrentAgreement] = useState(false);
  const [showAgreementModal, setShowAgreementModal] = useState(false);

  const paymentPlanWrapper = {
    width: '80%',
    margin: '20px auto',
  };

  const openLoanDetailsModal = () => {
    if (props.isNotClientPortal) {
      dispatch(getLoanDetails(invoice.number))
        .then((result) => {
          if (result.payload.data) {
            setLoanPlan(result.payload.data);
            setLoanPlanModal(true);
          }
        })
        .catch((error) => new Error(error));
    }
  };

  const openForPrint = () => {
    setShowAgreementModal(!showAgreementModal);
    //get the loan signature data
    dispatch(getLoanSignatureData(invoice?.number)).catch((err) => {
      console.log(err);
      dispatch(toggleServerModalError(false));
    });
  };

  const openAgreement = () => {
    dispatch(getInstallmentByNumber(invoice.number))
      .then((result) => {
        if (
          result.payload.data &&
          result.payload.data.number === invoice.number
        ) {
          setInvoicePrintDetails(result.payload.data);
          const source = document.getElementById(invoice.number);
          const pri = document.getElementById(
            'iframeContentToPrint'
          ).contentWindow;

          pri.document.open();
          pri.document.write(source.innerHTML);
          pri.document.close();
          pri.focus();
          // add the timeout to wait for the images to load
          // alternative we can track images loading finished and then trigger print
          setTimeout(() => pri.print(), 300);
          setCurrentAgreement(false);
          setShowAgreementModal(!showAgreementModal);
        }
      })
      .catch(() => {
        setInvoicePrintDetails(null);
        setCurrentAgreement(false);
        setShowAgreementModal(!showAgreementModal);
      });
  };

  const confirmSettle = () => {
    const activePatientCredit = lead.creditFacilityInformation;
    setLoadingConfirmBtn(true);
    dispatch(settleLoan(invoice.number))
      .then(() => {
        setShowConfirmSettleModal(false);
        setLoadingConfirmBtn(false);
        dispatch(getUpdatedPatientCreditFacility(activePatientCredit.id));
        dispatch(getLogsCreditFacility(activePatientCredit.number));
        dispatch(
          success(
            addNotification(
              `${strings.formatString(
                strings.successSettleText,
                invoice.number
              )}`,
              strings.successSettleTitle,
              notificationDismissDuration
            )
          )
        );
      })
      .catch(() => {
        setShowConfirmSettleModal(false);
        setLoadingConfirmBtn(false);
        dispatch(
          notifError(
            addNotification(
              `${strings.formatString(
                strings.errorSettleText,
                invoice.number
              )}`,
              strings.errorSettleTitle,
              notificationDismissDuration
            )
          )
        );
      });
  };

  return (
    <>
      <div
        className={`loan-list-item table-row-div
      ${
        paymentAgreementStatus[invoice.status] !== undefined
          ? paymentAgreementStatus[invoice.status].className
          : ``
      } ${props.firstInGroup ? 'first' : ''} ${
          props.lastInGroup ? 'last' : ''
        } ${props.onlyOne ? 'single' : 'multiple'}
      `}
        onClick={() => openLoanDetailsModal()}
      >
        <div className='table-cell-div'>
          {moment(invoice.startDate).format(momentFormatLong).toLowerCase()}
        </div>
        <div className='table-cell-div loan-type'>
          {/* (delbetaling)"/" (BNPL)" is for all positive cases(Approved/Paid out), where the loan has zero interest. */}
          {invoice.paidOutDate &&
          invoice.installmentInterestRate === 0 &&
          ['ACCEPTED', 'PAYED'].includes(invoice.status) ? (
            <i className='lnil lnil-pie-chart-alt' />
          ) : !['CONSOLIDATED', 'RESTRUCTURED'].includes(invoice.status) ? (
            <PercentageIcon className='percentage-icon' />
          ) : null}
        </div>
        <div className='table-cell-div status'>
          {strings[paymentAgreementStatus[invoice.status].statusText]}
        </div>
        <div className='table-cell-div'>
          {formatInterestRate(invoice?.installmentInterestRate)}
        </div>
        <div className='table-cell-div'>
          {invoice.remainingBalance &&
            currencyFormatDA(parseFloat(invoice.remainingBalance), true, true)}
        </div>
        <div className='table-cell-div'>
          {invoice.loanAmount &&
            currencyFormatDA(parseFloat(invoice.loanAmount), true, true)}
        </div>
        <div
          className='table-cell-div'
          onClick={(event) => event.stopPropagation()}
        >
          <div className='actions-menu'>
            <KebabMenu scrollToBottom={props.scrollToBottom}>
              {/* open loan details modal */}
              <div
                type='button'
                color='light'
                className='KebabMenu-item'
                onClick={() => openLoanDetailsModal()}
              >
                <span className='icon'>
                  <i className='lnil lnil-list' />
                </span>
                <span className='text'>{strings.loanDetailsTooltip}</span>
              </div>

              {/* open for print */}
              <div
                type='button'
                color='light'
                className='KebabMenu-item'
                onClick={() => openForPrint()}
              >
                <span className='icon'>
                  <i className='lnil lnil-download' />
                </span>
                <span className='text'>{strings.printInvoiceTooltip}</span>
              </div>

              {/* settle action*/}
              {invoice.status === 'ACCEPTED' &&
                invoice.paidOutDate !== null && (
                  <div
                    type='button'
                    color='light'
                    className='KebabMenu-item'
                    onClick={() => {
                      setShowConfirmSettleModal(true);
                    }}
                  >
                    <span className='icon'>
                      <DollarSymbol className='settle-icon' />
                    </span>
                    <span className='text'>{strings.settleTooltip}</span>
                  </div>
                )}
            </KebabMenu>
          </div>
        </div>
      </div>

      {/* initial or current agreements */}
      {showAgreementModal && (
        <DentiModal
          key={1}
          close={() => {
            setShowAgreementModal(!showAgreementModal);
            setCurrentAgreement(false);
          }}
          modalClass='modal-dialog agreementModalOptions'
          modalClassName='confirm-action'
        >
          <AgreementModalOptions
            firstAction={() => {
              setCurrentAgreement(false);
              openAgreement();
            }}
            secondAction={() => {
              setCurrentAgreement(true);
              openAgreement();
            }}
            firstActionText={strings.agreementModalInitial}
            secondActionText={strings.agreementModalCurrent}
          >
            <div>
              <p className='title'>
                {strings.formatString(
                  strings.agreementModalTitle,
                  !signatureData ? (
                    <small>{strings.agreementModalNoSignatureData}</small>
                  ) : (
                    ''
                  )
                )}
              </p>
              <p className='confirm-message'>
                {/* {strings.formatString(
                  strings.canceldLeadConfirmContent,
                  `${activeLead.leadClientInformation.firstName} ${activeLead.leadClientInformation.lastName}`
                )} */}
              </p>
            </div>
          </AgreementModalOptions>
        </DentiModal>
      )}

      {/* Loan details modal */}
      {loanPlanModal && (
        <DentiModal
          key={2}
          close={() => setLoanPlanModal(false)}
          modalClass='loan-details-modal modal-dialog-scrollable'
          modalClassName='loan-details-action'
          // closeBtn
          footerCloseBtn
          footerCloseBtnText={strings.loanDetailsClose}
        >
          <LoanDetails
            loanNumber={invoice.number}
            loanDetails={loanPlan}
            isClientPortal={false}
          />
        </DentiModal>
      )}

      {/* Print payment agreement modal */}
      {invoicePrintDetails !== null &&
        invoicePrintDetails.number === invoice.number && (
          <div
            id={invoice.number}
            className='hidden'
            style={paymentPlanWrapper}
          >
            <InvoicePaymentPlan
              invoice={invoice}
              invoiceDetails={invoicePrintDetails}
              lead={lead}
              currentAgreement={currentAgreement}
              signatureData={signatureData}
            />
          </div>
        )}

      {showConfirmSettleModal && (
        <DentiModal
          key={1}
          close={() => setShowConfirmSettleModal(false)}
          modalClass='confirm-modal'
          modalClassName='confirm-action'
        >
          <ConfirmationModal
            confirmAction={() => {
              confirmSettle();
            }}
            cancelAction={() => setShowConfirmSettleModal(false)}
            loading={loadingConfirmBtn}
          >
            <div>
              <p className='title'>{strings.settleConfirmationTitle}</p>
              <p className='confirm-message'>
                {strings.formatString(
                  strings.settleConfirmationText,
                  <b>
                    {formatCurrencyDaStandard(invoice?.remainingBalance || '-')}
                  </b>
                )}
              </p>
            </div>
          </ConfirmationModal>
        </DentiModal>
      )}
    </>
  );
};
export default PaymentAgreementsListItem;
