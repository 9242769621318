import React from 'react';
import PropTypes from 'prop-types';
import { strings } from '../../constants/localization';
import ButtonLoader from './ButtonLoader/ButtonLoader';

function ConfirmActionModal(props) {
  const {
    confirmAction,
    cancelAction,
    loading,
    confirmBtnText,
    cancelBtnText,
    children,
  } = props;

  return (
    <div className='status-modal'>
      {children}
      <div className='bottom-section action-btn cf'>
        <button
          className='submit-btn btn btn-blue center-block'
          type='button'
          onClick={confirmAction}
          disabled={loading}
        >
          {!loading && (
            <>
              <i className='di di-accept' />
              <span>{confirmBtnText ? confirmBtnText : strings.confirm}</span>
            </>
          )}
          {loading && <ButtonLoader />}
        </button>
        <button
          className='cancel-btn btn btn-light center-block'
          type='button'
          onClick={cancelAction}
          disabled={loading}
        >
          <i className='di di-cancel' />
          <span>{cancelBtnText ? cancelBtnText : strings.cancel}</span>
        </button>
      </div>
    </div>
  );
}

ConfirmActionModal.propTypes = {
  confirmAction: PropTypes.func,
  cancelAction: PropTypes.func,
  loading: PropTypes.bool,
  confirmBtnText: PropTypes.string,
  cancelBtnText: PropTypes.string,
  children: PropTypes.node,
};

export default ConfirmActionModal;
