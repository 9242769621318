import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../common/LoadingSpinner/LoadingSpinner';
import { lookupClientCreditByCpr } from '../../../actions/onboardingActions';
import {
  getLoggedInUserDetails,
  isAdmin,
  isAuthenticated,
} from '../../../constants/utils';
import { strings } from '../../../constants/localization';

const CheckDebtorRegister = ({ setNextStep, step }) => {
  const userFromStorage = getLoggedInUserDetails();
  const dispatch = useDispatch();
  const savedData = useSelector((state) => state.onboarding?.data);
  const visitedSteps = useSelector((state) => state.onboarding?.visitedSteps);
  const { uuid, creditApplicationUuid, bisnodeId } = savedData || {};

  // when the component mounts, lookup the client credit by cpr
  useEffect(() => {
    document.title = `Sundhed+ | ${strings.onboardingCheckDebtorRegisterTitle}`;
    if (!visitedSteps?.includes(step)) {
      window.dataLayer = window?.dataLayer || [];
      window.dataLayer.push({
        customPageUrl: '/validering-af-debitor-register',
        stepTitle: 'onboarding - validering af debitor register',
        event: 'onboarding',
      });
    }
    if (
      isAuthenticated() &&
      isAdmin(userFromStorage) &&
      process.env.REACT_APP_STAGE === 'staging'
    ) {
      setNextStep(step + 1);
    } else {
      dispatch(
        lookupClientCreditByCpr(bisnodeId, creditApplicationUuid, uuid)
      ).then(() => {
        // redirect to manual intervention step
        setNextStep(step + 1);
      });
    }
  }, []);

  return <LoadingSpinner title={strings.onboardingCheckDebtorRegisterTitle} />;
};
export default CheckDebtorRegister;
