import {
  ADD_CLINIC_WEBSOCKET_MESSAGE,
  ADD_CLIENT_WEBSOCKET_MESSAGE,
  ADD_DOCUMENTATION_WEBSOCKET_MESSAGE,
  REMOVE_CLINIC_WEBSOCKET_MESSAGE,
  REMOVE_CLIENT_PORTAL_WEBSOCKET_MESSAGE,
  REMOVE_CLIENT_WEBSOCKET_MESSAGE,
  REMOVE_DOCUMENTATION_WEBSOCKET_MESSAGE,
  ADD_CLIENT_PORTAL_WEBSOCKET_MESSAGE,
} from '../constants';

const defaultState = {
  client: [],
  clinic: [],
  documentation: [],
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case ADD_CLIENT_WEBSOCKET_MESSAGE:
      return {
        ...state,
        client: action.payload
          ? state.client.concat(action.payload)
          : state.client,
      };
    case REMOVE_CLIENT_WEBSOCKET_MESSAGE:
      const filteredArray = state.client.filter(
        (item, index) => index !== action.payload
      );
      return {
        ...state,
        client: filteredArray,
      };
    case ADD_CLIENT_PORTAL_WEBSOCKET_MESSAGE:
      return {
        ...state,
        clientPortalWs: action.payload
          ? state.clientPortalWs.concat(action.payload)
          : state.clientPortalWs,
      };
    case REMOVE_CLIENT_PORTAL_WEBSOCKET_MESSAGE:
      return {
        ...state,
        clientPortalWs: action.payload,
      };
    case ADD_CLINIC_WEBSOCKET_MESSAGE:
      return {
        ...state,
        clinic: action.payload
          ? state.clinic.concat(action.payload)
          : state.clinic,
      };
    case REMOVE_CLINIC_WEBSOCKET_MESSAGE:
      return {
        ...state,
        clinic: action.payload,
      };
    case ADD_DOCUMENTATION_WEBSOCKET_MESSAGE:
      return {
        ...state,
        documentation: action.payload
          ? state?.documentation.concat(action.payload)
          : state?.documentation,
      };
    case REMOVE_DOCUMENTATION_WEBSOCKET_MESSAGE:
      return {
        ...state,
        documentation: action.payload,
      };
    default:
      return state;
  }
}
