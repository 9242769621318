import React, { useEffect, useState } from 'react';
import AdditionalPayment from './steps/AdditionalPayment';
import AdjustMonthlyPayment from './steps/AdjustMonthlyPayment';
import AcceptNewLoanAgreement from './steps/AcceptNewLoanAgreement';
import ConfirmPayment from './steps/ConfirmPayment';
import PaymentProcessing from './steps/PaymentProcessing';
import LoadingMessage from '../statusMessages/LoadingMessage';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Card, CardBody } from 'reactstrap';
import sundhedLogo from '../../resources/images/sundhedplus-logo-invert.svg';
import { useDispatch } from 'react-redux';
import { getClientDataForPartialRedemption } from '../../actions/leadActions';

import StepError from '../acceptance/StepError';
import { strings } from '../../constants/localization';

type PaymentFlowProps = {
  isClientPortal?: boolean;
  hasLeadUuid?: string;
  hasCreditFacilityNumber?: string;
  deviceType?: string;
  closeModal?: () => void;
  isSettlement?: boolean;
  numberOfReepayInvoicesToday: number;
};

interface ClientDataProps {
  client: {
    firstName?: string;
    lastName?: string;
  };
  creditFacility: {
    remainingBalance?: number;
    creditFacilityInterestRate?: number;
    numberOfReepayInvoicesToday?: number;
  };
  creditCard: {
    expirationDate?: string;
    maskedCardNumber?: string;
  };
}

interface NewPaymentDetailProps {
  monthlyPayment: number;
  apr: number;
  firstPaymentDate: string;
  lastPaymentDate: string;
  duration: number;
  extraPayment: number;
  newRemainingBalance: number;
  interestUntilFirstPayment: number;
}

const PaymentFlow: React.FC<PaymentFlowProps> = ({
  isClientPortal,
  hasLeadUuid,
  numberOfReepayInvoicesToday,
  hasCreditFacilityNumber,
  deviceType,
  closeModal,
  isSettlement,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [prevStep, setPrevStep] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paymentIsLoading, setPaymentIsLoading] = useState<boolean>(false);
  const [additionalPayment, setAdditionalPayment] = useState<string>('');
  const [clientData, setClientData] = useState<ClientDataProps>({
    creditFacility: {},
    creditCard: {},
    client: {},
  });
  const [hasRedirect, setHasRedirect] = useState<boolean>(false);
  const [hasLogo, setHasLogo] = useState<boolean>(true); // Initialize as true
  const [source, setSource] = useState<string>('');
  const [device, setDevice] = useState<string>('');

  const [leadUuid, setLeadUuid] = useState<string>('');
  const [creditFacilityNumber, setCreditFacilityNumber] = useState<string>('');
  const [isPaymentConfirmed, setIsPaymentConfirmed] = useState<boolean>(false);
  const [newPaymentDetails, setNewPaymentDetails] =
    useState<NewPaymentDetailProps>();

  const dispatch = useDispatch();

  useEffect(() => {
    if (isSettlement) {
      setCurrentStep(4);
    }
  }, [isSettlement]);

  // Helper function to extract query parameters
  const extractUrlParam = (param: string): string | null =>
    new URLSearchParams(window.location.search).get(param);

  const fetchClientData = async (uuid: string, facilityNumber: string) => {
    try {
      const res = await dispatch(
        getClientDataForPartialRedemption(uuid, facilityNumber)
      );

      if (res.status === 200) {
        setClientData(res.data);
        setIsLoading(false);
      } else {
        setCurrentStep(null);
      }
    } catch (error) {
      console.log('error', error);
      setCurrentStep(null);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const initializeData = () => {
      setIsLoading(true);

      const leadUuidFromUrl = extractUrlParam('leadUuid');
      const creditFacilityFromUrl = extractUrlParam('creditFacilityNumber');
      const sourceFromUrl =
        extractUrlParam('source') || deviceType ? 'portal' : '';
      const deviceFromUrl = extractUrlParam('device') || deviceType;

      if (sourceFromUrl) setSource(sourceFromUrl);
      if (deviceFromUrl) setDevice(deviceFromUrl);

      const uuid = isClientPortal ? hasLeadUuid : leadUuidFromUrl;
      const facilityNumber = isClientPortal
        ? hasCreditFacilityNumber
        : creditFacilityFromUrl;

      if (uuid && facilityNumber) {
        setLeadUuid(uuid);
        setCreditFacilityNumber(facilityNumber);
        fetchClientData(uuid, facilityNumber);
      } else {
        setIsLoading(false);
      }
    };

    initializeData();
  }, [dispatch, isClientPortal, hasLeadUuid, hasCreditFacilityNumber]);

  // Logic for managing hasLogo and hasRedirect
  useEffect(() => {
    if (source === 'portal' && device === 'mobile') {
      setHasLogo(false);
      setHasRedirect(true);
    } else if (source === 'portal' && device === 'desktop') {
      setHasLogo(false);
      setHasRedirect(false);
    } else {
      setHasLogo(true);
      setHasRedirect(false);
    }
  }, [source, device]);

  // Store previous step before the state change
  const handleNextStep = () => {
    setPrevStep(currentStep); // Capture current step as previous step
    setCurrentStep((prevStep) => prevStep + 1);
    window.parent.postMessage({ step: 2 }, '*');
  };

  const handlePreviousStep = () => {
    if (isSettlement) {
      closeModal && closeModal();
    } else {
      setPrevStep(currentStep); // Capture current step as previous step
      setCurrentStep((prevStep) => prevStep - 1);
      window.parent.postMessage({ step: 1 }, '*');
    }
  };

  // detect if the user is using an iOS device, then prevent the zoom in/out
  useEffect(() => {
    const iOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (iOS) {
      document.addEventListener(
        'gesturestart',
        function (e) {
          e.preventDefault();
        },
        false
      );
    }
    if (!isClientPortal) {
      document.body.classList.add('public-content');
    } else {
      document.body.classList.add('private-content');
    }
    document.body.classList.add('public-flow');
    return () => {
      if (!isClientPortal) {
        document.body.classList.remove('public-flow');
      } else {
        document.body.classList.add('private-content');
      }
      document.body.classList.remove('public-content');
    };
  }, []);

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <AdditionalPayment
            onNextStep={handleNextStep}
            setAdditionalPayment={setAdditionalPayment}
            additionalPayment={additionalPayment}
            remainingBalance={clientData?.creditFacility.remainingBalance}
            hasCancelButton={source === 'portal' ? true : false}
            hasRedirect={hasRedirect}
            closeModal={closeModal}
          />
        );
      case 2:
        return (
          <AdjustMonthlyPayment
            onNextStep={handleNextStep}
            additionalPayment={additionalPayment}
            setNewPaymentDetails={setNewPaymentDetails}
            handlePreviousStep={handlePreviousStep}
            newPaymentDetails={newPaymentDetails}
          />
        );
      case 3:
        return (
          <AcceptNewLoanAgreement
            onNextStep={handleNextStep}
            setIsLoading={setIsLoading}
            newPaymentDetails={newPaymentDetails}
            clientData={clientData}
            extraPayment={additionalPayment}
            setNewPaymentDetails={setNewPaymentDetails}
            handlePreviousStep={handlePreviousStep}
          />
        );
      case 4:
        return (
          <ConfirmPayment
            onNextStep={handleNextStep}
            setPaymentIsLoading={setPaymentIsLoading}
            newPaymentDetails={newPaymentDetails}
            clientData={clientData}
            handlePreviousStep={handlePreviousStep}
            setIsPaymentConfirmed={setIsPaymentConfirmed}
            isSettlement={isSettlement}
          />
        );
      case 5:
        return (
          <PaymentProcessing
            additionalPayment={additionalPayment}
            newPaymentDetails={newPaymentDetails}
            isPaymentConfirmed={isPaymentConfirmed}
            leadUuid={leadUuid}
            creditFacilityNumber={creditFacilityNumber}
            hasCancelButton={source === 'portal' ? true : false}
            hasRedirect={hasRedirect}
            closeModal={closeModal}
            isSettlement={isSettlement}
            clientData={clientData}
            numberOfReepayInvoicesToday={numberOfReepayInvoicesToday}
          />
        );
      default:
        return <StepError hasRedirect={hasRedirect} />;
    }
  };

  const directionClassName = currentStep > prevStep ? 'move-next' : 'move-prev';

  return (
    <>
      <div id='acceptance-flow' className='flow-wrapper'>
        {isLoading ? (
          <LoadingMessage />
        ) : (
          <TransitionGroup
            appear
            timeout={600}
            className={`slide-group ${directionClassName} step-${currentStep} ${paymentIsLoading ? 'loading-payment' : ''
              }`}
          >
            <CSSTransition
              key={currentStep}
              appear
              timeout={300}
              classNames='slide'
              className='transition-container'
            >
              <div className='sundhed-wrapper'>
                {hasLogo && (
                  <div className='sundhed-plus-logo'>
                    <img
                      className='sundhed-logo'
                      src={sundhedLogo}
                      alt='Sundhed+'
                    />
                  </div>
                )}
                <Card
                  className={`flow-container ${currentStep === 1
                    ? 'additional-payment'
                    : currentStep === 5
                      ? 'payment-processing'
                      : 'partial-redemption-flow'
                    }`}
                >
                  <CardBody>
                    {paymentIsLoading ? (
                      <LoadingMessage message={strings.loadingMessage} />
                    ) : (
                      renderStep()
                    )}
                  </CardBody>
                </Card>
              </div>
            </CSSTransition>
          </TransitionGroup>
        )}
      </div>
    </>
  );
};

export default PaymentFlow;
