import React, {
    createContext,
    useContext,
    useState,
    useMemo,
    useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import DentiModal from '../../common/DentiModal';
import { SERVER_URL } from '../../../constants';
import { strings } from '../../../constants/localization';
import PaymentFlow from '../../paymentFlow/PaymentFlow';
import { ReactComponent as InformationIcon } from '../../../resources/icons/info-circle.svg';

interface ServiceActionContextType {
    openPartialPaymentModal: () => void;
    openSettleLoanModal: () => void;
}

const ServiceActionContext = createContext<ServiceActionContextType>({
    openPartialPaymentModal: () => { },
    openSettleLoanModal: () => { },
});

interface clientDataProps {
    clientData: {
        leadUuid: string;
    };
    creditFacilityData: {
        number: string;
    };
}

interface SundhedCreditProps {
    creditFacilityData: {
        status: string;
        amountAvailable: number;
        expirationDate: string;
        interestRate: number;
        amount: number;
        dunning: boolean;
        creditCardData: {
            status: string;
            expirationDate: string;
        };
        numberOfReepayInvoicesToday: number;
        installmentLoanDataList: [
            {
                number: string;
                status: string;
            }
        ];
    };
    clientData: {
        uuid: string;
    };
}


// eslint-disable-next-line no-unused-vars
enum WarningCase {
    // eslint-disable-next-line no-unused-vars
    LIMIT_REACHED = 'paymentReachedLimit',
    // eslint-disable-next-line no-unused-vars
    INVALID_CARD = 'invalidCard',
    // eslint-disable-next-line no-unused-vars
    DUNNING = 'dunning',
}

export const ServiceActionProvider = ({
    setPartialRedemptionModal,
    setWarningModal,
    children,
}) => {
    const memberData = useSelector(
        (state: { memberPortal: { clientDataInformation: clientDataProps } }) =>
            state.memberPortal?.clientDataInformation
    );

    const creditInformation = useSelector(
        (state: { memberPortal: { clientDataInformation: SundhedCreditProps } }) =>
            state.memberPortal?.clientDataInformation
    );

    const clientCreditInformation = creditInformation.creditFacilityData;

    const { dunning, numberOfReepayInvoicesToday, installmentLoanDataList } =
        clientCreditInformation;
    const { expirationDate, status: creditCardStatus } =
        clientCreditInformation.creditCardData;
    const isCardInactive = creditCardStatus !== 'ACTIVE';

    // get the last accepted invoice
    const lastAcceptedInvoice = installmentLoanDataList
        .filter(
            (invoice) => invoice.status === 'ACCEPTED' || invoice.status === 'ACTIVE'
        )
        .pop();
    const lastAcceptedInvoiceNumber = lastAcceptedInvoice?.number;

    const { userAgent } = window.navigator;

    // Set device to "mobile" if "Mobi" exists in userAgent else set device to "desktop"
    const device = userAgent.includes('Mobi') ? 'mobile' : 'desktop';

    const isCreditCardExpired = () => {
        const [month, year] = expirationDate.split('-');
        // Prepend '20' to the year to interpret it as 2099 instead of 1999
        const fullYear = `20${year}`;
        const expDate = moment(`${month}-${fullYear}`, 'MM-YYYY').endOf('month');
        return expDate.isBefore(moment());
    };
    const [partialPaymentModal, setPartialPaymentModal] =
        useState<boolean>(false);
    const [settleLoanModal, setSettleLoanModal] = useState<boolean>(false);
    const [showWarningNotAllowed, setShowWarningNotAllowed] =
        useState<boolean>(false);
    const [warningCase, setWarningCase] = useState<WarningCase>(
        WarningCase.LIMIT_REACHED
    );
    const currentUrl =
        window.location.hostname === 'localhost'
            ? 'http://localhost:3000'
            : `https://${process.env.REACT_APP_DAM_URL}`;

    useEffect(() => {
        setPartialRedemptionModal(partialPaymentModal);
    }, [partialPaymentModal]);

    useEffect(() => {
        setWarningModal(showWarningNotAllowed);
    }, [showWarningNotAllowed]);

    const renderUpdateCardLink = (linkText: string) => {
        return <a
            href={`${SERVER_URL}/public/installmentloan/changeCreditInformation?installmentLoanNumber=${lastAcceptedInvoiceNumber}`}
            target='_blank'
            rel='noopener noreferrer'
        >
            {linkText}
        </a>
    };

    const renderWarning = () => {
        type FormatType = string | (string | React.JSX.Element)[];
        let headline = '', message: FormatType = '', message2: FormatType = '';
        switch (warningCase) {
            case WarningCase.LIMIT_REACHED:
                headline = strings.partialRedemptionTitleWarningLimitError;
                message = strings.partialRedemptionTextWarningMessage1;
                break;
            case WarningCase.INVALID_CARD:
                headline = strings.partialRedemptionTitleWarningCardError;
                message = strings.formatString(
                    strings.partialRedemptionTextWarningMessage2,
                    renderUpdateCardLink(strings.partialRedemptionTextWarningMessage2Link)
                );
                break;
            case WarningCase.DUNNING:
                headline = strings.partialRedemptionTitleWarningDunningError;
                message = strings.partialRedemptionTextWarningMessage3;
                message2 = strings.formatString(
                    strings.partialRedemptionTextWarningMessage4,
                    renderUpdateCardLink(strings.partialRedemptionTextWarningMessage4Link)
                )
                break;
            default:
                return '';
        }
        return (
            <DentiModal
                key={1}
                close={() => setShowWarningNotAllowed(false)}
                modalClass='notification-modal warning payment-flow'
                modalClassName='confirm-action'
            >
                <div>
                    <div className='icon'>
                        <InformationIcon className='warning-icon' />
                    </div>
                    <div>
                        <h3 className='headline'>
                            {headline}
                        </h3>
                        <p className='notification-message'>
                            {message}
                        </p>
                        {message2 && <p className='notification-message'>
                            {message2}
                        </p>}
                    </div>
                    <div className='bottom-section action-btn mt-3'>
                        <button
                            className='submit-btn btn btn-blue center-block'
                            type='button'
                            onClick={() => setShowWarningNotAllowed(false)}
                        >
                            <span>{strings.loanDetailsClose}</span>
                        </button>
                    </div>
                </div>
            </DentiModal>
        );
    };

    const checkWarningCases = (isSettle, customBehaviourCallback) => {
        if (numberOfReepayInvoicesToday >= 3) {
            setWarningCase(WarningCase.LIMIT_REACHED);
            setShowWarningNotAllowed(true);
        } else if (dunning && !isSettle) {
            // for settle case, we show the flow, but for partial redemption we show warnning
            setWarningCase(WarningCase.DUNNING);
            setShowWarningNotAllowed(true);
        } else if (isCardInactive || isCreditCardExpired()) {
            setWarningCase(WarningCase.INVALID_CARD);
            setShowWarningNotAllowed(true);
        }
        else {
            customBehaviourCallback();
        }
    };

    const openPartialPaymentModal = () => {
        checkWarningCases(false, () => {
            const url = `${currentUrl}/partialRedemption?leadUuid=${memberData?.clientData.leadUuid}&creditFacilityNumber=${memberData?.creditFacilityData?.number}&source=portal&device=${device}`;
            if (device === 'mobile') {
                window.open(url, '_blank');
            } else {
                setPartialPaymentModal(!partialPaymentModal);
            }
        });
    };

    const openSettleLoanModal = () => {
        checkWarningCases(true, () => {
            setSettleLoanModal(!settleLoanModal);
        });
    };

    const value = useMemo(
        () => ({ openPartialPaymentModal, openSettleLoanModal }),
        []
    );

    return (
        <ServiceActionContext.Provider value={value}>
            {children}
            {/* warning partial payment not allowed */}
            {showWarningNotAllowed && renderWarning()}

            {/* Partial payment modal */}
            {!showWarningNotAllowed && partialPaymentModal && (
                <DentiModal
                    key={1}
                    close={() => {
                        setPartialPaymentModal(false);
                        window.location.reload();
                    }}
                    modalClass='loan-details-modal modal-dialog-centered'
                    modalClassName='partial-redemption-modal'
                    closeBtn={true}
                >
                    <div className='partial-redemption-styles'>
                        <PaymentFlow
                            hasLeadUuid={memberData?.clientData.leadUuid}
                            hasCreditFacilityNumber={memberData?.creditFacilityData?.number}
                            isClientPortal={true}
                            deviceType={device}
                            closeModal={() => setPartialPaymentModal(false)}
                            isSettlement={false}
                            numberOfReepayInvoicesToday={numberOfReepayInvoicesToday}
                        />
                    </div>
                </DentiModal>
            )}

            {/* Settle loan modal */}
            {settleLoanModal && (
                <DentiModal
                    key={1}
                    close={() => {
                        setSettleLoanModal(false);
                        window.location.reload();
                    }}
                    modalClass='loan-details-modal modal-dialog-centered'
                    modalClassName='partial-redemption-modal'
                    closeBtn={true}
                >
                    <div className='partial-redemption-styles'>
                        <PaymentFlow
                            hasLeadUuid={memberData?.clientData.leadUuid}
                            hasCreditFacilityNumber={memberData?.creditFacilityData?.number}
                            isClientPortal={true}
                            deviceType={device}
                            closeModal={() => setSettleLoanModal(false)}
                            isSettlement={true}
                            numberOfReepayInvoicesToday={numberOfReepayInvoicesToday}
                        />
                    </div>
                </DentiModal>
            )}
        </ServiceActionContext.Provider>
    );
};

export const useServiceActionContext = () => useContext(ServiceActionContext);
