import DentiModal from '../../../common/DentiModal';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { strings } from '../../../../constants/localization';
import { ReactComponent as InfoCircle } from '../../../../resources/icons/info-circle.svg';

const ApplicationEndpointRequestFailedModal = ({ onClose, headline, subtitle }) => {
    const serverErrorDetails = useSelector(
        (state: {
            ui: {
                serverErrorDetails: {
                    status: number;
                    data: {
                        cause: string;
                        message: string;
                    };
                };
            };
        }) => state.ui.serverErrorDetails
    );

    return (
        <div>
            <DentiModal
                modalClass='modal-dialog-centered application-request--modal'
                key={1}
                close={onClose}
            >
                <div className='error'>
                    <div className='icon'>
                        <InfoCircle className='info-circle' />
                    </div>
                    <div>
                        <h3 className='headline'>{headline}</h3>
                        <p className='subtitle'>
                            {subtitle}
                        </p>
                    </div>
                    <div className='something-went-wrong'>
                        <div
                            className='panel active'>
                            <div>
                                <b>{strings.httpText} </b>
                                {serverErrorDetails && serverErrorDetails?.status
                                    ? serverErrorDetails.status
                                    : ''}
                            </div>

                            <div>
                                <b>{strings.httpMessage} </b>
                                {serverErrorDetails?.data.cause ?? ''}
                                {serverErrorDetails?.data.message &&
                                    !serverErrorDetails?.data.cause
                                    ? serverErrorDetails?.data.message
                                    : ''}
                            </div>
                        </div>
                    </div>
                    <div className='bottom-section action-btn cf'>
                        <Button color='blue' className='btn btn-cancel' onClick={onClose}>
                            {strings.applicationEndpointRequestModalBtnText}
                        </Button>
                    </div>
                </div>
            </DentiModal>
        </div>
    )
}

export default ApplicationEndpointRequestFailedModal;
