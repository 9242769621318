import React, { useState } from 'react';
import { Button, CardBody } from 'reactstrap';
import { strings } from '../../constants/localization';
import { brandConfig } from '../../config';
import ButtonLoader from '../common/ButtonLoader/ButtonLoader';

const ExistingMemberStartOnboarding = ({ step, setNextStep }) => {
    const [buttonLoading, setButtonLoading] = useState(false);
    const phoneNumber =
        strings.getLanguage() === 'en'
            ? brandConfig.contactPhoneHtmlFormated.replace(/\s/g, '\u00A0')
            : brandConfig.contactPhoneHtmlFormated.replace(/\s/g, '\u00A0');

    const handleSubmit = () => {
        setButtonLoading(true);
        setNextStep(step + 1);
    };

    return (
        <CardBody className='step-content'>
            <div className='step-title'>
                {strings.onboardingExistingMemberStartHeadline}
            </div>
            <div className='step-subtitle'>
                <p className='existingMemberSubtitle'>
                    {strings.onboardingExistingMemberStartText1}
                </p>
            </div>
            <div className='step-subtitle '>
                <h2 className='existingMemberTitle bold '>
                    {strings.onboardingExistingMemberStartSubheadline}
                </h2>
                <p className='contactForQuestions'>
                    {strings.formatString(
                        strings.onboardingExistingMemberStartText2,
                        <a
                            className='color'
                            href={`tel:${phoneNumber.replace(/\s/g, '')}`}
                        >
                            {phoneNumber}
                        </a>,
                        <a
                            className='color'
                            href={`mailto:${brandConfig.contactEmail}`}
                        >
                            {brandConfig.contactEmail}
                        </a>
                    )}
                </p>
            </div>

            <div className='actions-buttons mt-auto mb-3'>
                <Button
                    className='btn btn-blue btn-success full-width'
                    onClick={() => handleSubmit()}
                    disabled={buttonLoading}
                >
                    {buttonLoading ? (
                        <ButtonLoader />
                    ) : (
                        strings.onboardingExistingMemberButtonText
                    )}
                </Button>
            </div>
        </CardBody>
    );
};

export default ExistingMemberStartOnboarding;
