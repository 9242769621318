import React, { createRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import {
  trackVerticalStyle,
  thumbVerticalStyle,
} from '../../constants/styleScrollbar';
import './style.scss';
import LoadingMessage from '../statusMessages/LoadingMessage';
import ApplicationList from './ApplicationsList/ApplicationList';
import ApplicationItem from './ApplicationItem.js/ApplicationItem';
import WelcomePage from '../common/WelcomePage';

export default function CreditApplicationsPage(props) {
  const [loading, setLoading] = useState(true);

  const scrollRef = createRef();
  const activeApplication = useSelector(
    (state) => state.onboardingApplication?.activeApplicationListItem
  );
  const applicationLoading = useSelector(
    (state) => state.onboardingApplication?.applicationLoading
  );

  const applicationsData = useSelector(
    (state) => state.onboardingApplication?.data
  );

  useEffect(() => {
    resetScrollPosition();
  }, []);

  useEffect(() => {
    if (activeApplication) {
      resetScrollPosition();
    }
  }, [activeApplication]);

  useEffect(() => {
    if (applicationsData) {
      setLoading(false);
    }
  }, [applicationsData]);

  const resetScrollPosition = () => {
    if (scrollRef.current !== null) {
      scrollRef.current.view.scrollTop = 0;
    }
  };

  return (
    <>
      {loading && <LoadingMessage />}
      <Row className='app-row-height'>
        <Col
          xs='12'
          md='12'
          xl='4'
          className='entities-list-section p-0 app-col-height'
        >
          <ApplicationList toggleMenu={props.toggleMenu} />
        </Col>
        <Col
          xs='12'
          md='12'
          xl='8'
          className={`entity-details-section ${activeApplication !== undefined && 'active'
            }`}
        >
          <Scrollbars
            ref={scrollRef}
            renderTrackVertical={({ style, ...props }) => (
              <div {...props} style={{ ...style, ...trackVerticalStyle }} />
            )}
            renderThumbVertical={({ style, ...props }) => (
              <div {...props} style={{ ...style, ...thumbVerticalStyle }} />
            )}
          >
            {applicationLoading ? (
              <LoadingMessage />
            ) : activeApplication !== undefined ? (
              <ApplicationItem />
            ) : (
              <WelcomePage
                toggleModal={props.toggleModal}
                modalShow={props.modalShow}
                toggleCreateNewInvoice={props.toggleCreateNewInvoice}
                createNewInvoice={props.createNewInvoice}
              />
            )}
          </Scrollbars>
        </Col>
      </Row>
    </>
  );
}
