import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { success, error as notifError } from 'react-notification-system-redux';
import { Row, Col, Label, Button } from 'reactstrap';
import Select from 'react-select';
import { DatePicker } from 'reactstrap-date-picker';
import SimpleCheckbox from '../../../common/SimpleCheckbox/SimpleCheckbox';
import { debounce } from 'lodash';
import { strings } from '../../../../constants/localization';
import {
  updateRestructureLoan,
  getUpdatedPatientCreditFacility,
} from '../../../../actions/leadActions';
import { getLogsCreditFacility } from '../../../../actions/logActions';
import {
  currencyFormatDA,
  paymentListMomentRestructureLoans,
  addNotification,
  formatInterestRate,
} from '../../../../constants/utils';
import { maxAprRestructuring } from '../../../../config';
import {
  moment,
  CURRENCY_FORMAT,
  notificationDismissDuration,
} from '../../../../constants';
import CustomMaskInput from '../../../common/CustomMaskInput';
import DentiModal from '../../../common/DentiModal';
import ExportConfirmationModal from '../../../common/ExportConfirmationModal';

const RestructureForm = ({ totalBalance, setActiveTab }) => {
  const dispatch = useDispatch();
  const activeLead = useSelector((state) => state.lead.activeLead);
  const creditFacility = activeLead?.creditFacilityInformation;
  const leadLoans = useSelector((state) => state.lead.leadLoans);
  const [state, setState] = useState({});
  const [errors, setErrors] = useState({});
  const reasonsForRestructure = [
    {
      value: strings.dropPatientInDunning,
      label: strings.dropPatientInDunning,
    },
    {
      value: strings.dropAmountReturnClinic,
      label: strings.dropAmountReturnClinic,
    },
    {
      value: strings.dropPartialRedemption,
      label: strings.dropPartialRedemption,
    },
    { value: strings.dropClientRequest, label: strings.dropClientRequest },
    { value: strings.dropOther, label: strings.dropOther },
  ];

  const getInitialState = () => {
    //suggested interest rate is one from creditfacility if credit is Active and not expired, otherwise the interestRate of latest Accepted loan
    const expirationDiff = moment(
      creditFacility?.expirationDate,
      'YYYY-MM-DD'
    ).diff(moment(new Date()).format('YYYY-MM-DD'));
    const creditActiveAndNotExpired =
      creditFacility?.status === 'ACTIVE' && expirationDiff > 0;
    let lastAcceptedLoan;
    if (leadLoans && leadLoans.length > 0) {
      lastAcceptedLoan = leadLoans?.find((o) => o.status === 'ACCEPTED');
    }
    const finalInterestRate =
      lastAcceptedLoan && !creditActiveAndNotExpired
        ? lastAcceptedLoan?.interestRate
        : creditFacility?.interestRate;
    //suggested Duration = number of payments of the latest ACCEPTED installment loan, where payment status != PAYED
    let numberOfUnpaidPayments = creditFacility.creditFacilityDuration;
    if (lastAcceptedLoan && lastAcceptedLoan?.payments?.length > 0) {
      numberOfUnpaidPayments = lastAcceptedLoan.payments.filter(
        (o) => o.status !== 'PAYED'
      ).length;
    }
    const initialState = {
      loading: false,
      dropdownOpen: false,
      showConfirmModal: false,
      // these values are for inputs
      restructuringFee: '150,00',
      latePaymentFee: '0,00',
      extraPayment: '0,00',
      firstPaymentDate: moment()
        .add(1, 'months')
        .startOf('month')
        .format('YYYY-MM-DD'),
      interestRate: finalInterestRate.toString().replace('.', ','),
      duration: numberOfUnpaidPayments,
      adjustDuration: false,
      reason: null,
      // these values are used for calculations
      values: {
        restructuringFee: 150.0,
        latePaymentFee: 0.0,
        extraPayment: 0.0,
        firstPaymentDate: moment()
          .add(1, 'months')
          .startOf('month')
          .format('YYYY-MM-DD'),
        interestRate: finalInterestRate,
        duration: numberOfUnpaidPayments,
      },
      apr: 0,
      monthlyPayment: 0,
      errors: {},
      activeError: false,
    };
    return initialState;
  };

  useEffect(() => {
    setState(getInitialState());
  }, []);

  // when the current lead changes, reset the state
  // make the reason required
  useEffect(() => {
    setState(getInitialState());
    setErrors((prevState) => ({
      ...prevState,
      reason: strings.required,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLead, leadLoans]);

  // shouldn't be allowed to select a duration, which results in a monthly amount larger than max monthly payment
  // show red color on the monthly payment if exceeds max monthly payment
  useEffect(() => {
    if (state.monthlyPayment && creditFacility?.maxMonthlyPayment) {
      if (state.monthlyPayment > creditFacility.maxMonthlyPayment) {
        setErrors((prevState) => ({
          ...prevState,
          maxMonthlyPayment: true,
        }));
      } else if (Object.keys(errors).includes('maxMonthlyPayment')) {
        // Create a copy of the error object without the maxMonthlyPayment field
        const updatedErrors = { ...errors };
        delete updatedErrors?.maxMonthlyPayment;
        setErrors(updatedErrors);
      }
    }
  }, [state.monthlyPayment]);

  // when the form values change, re-calculate the payments
  useEffect(() => {
    // this check is to prevent an error form the xirr library, when the total is 0
    if (totalBalance !== 0) {
      debouncedPaymentsCalculation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalBalance, state.values]);

  // debounce function that handle the payments calculation
  const debouncedPaymentsCalculation = debounce(() => {
    //if (Object.keys(errors).length === 0) {
    handlePayments();
    //}
  }, 0);

  const handlePayments = () => {
    const {
      firstPaymentDate,
      interestRate,
      duration,
      restructuringFee,
      extraPayment,
      latePaymentFee,
    } = state.values;
    // 11400
    const totalRemaningBalance =
      parseFloat(totalBalance) +
      parseFloat(restructuringFee) -
      parseFloat(extraPayment) +
      parseFloat(latePaymentFee);
    const paymentResults = paymentListMomentRestructureLoans(
      firstPaymentDate,
      parseFloat(interestRate),
      totalRemaningBalance,
      duration
    );

    setState((prevState) => ({
      ...prevState,
      apr: paymentResults.apr.toFixed(2),
      monthlyPayment: paymentResults.monthlyPayment,
    }));
  };

  const toggleConfirmModal = () => {
    setState((prevState) => ({
      ...prevState,
      showConfirmModal: !prevState.showConfirmModal,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value, type } = event.target;
    let inputValue;
    if (value) {
      if (type !== 'date') {
        const errorsObj = errors;
        if (Object.keys(errorsObj).length > 0) {
          if ({}.hasOwnProperty.call(errorsObj, name)) {
            delete errorsObj[name];
          }
        }
        setErrors(errorsObj);

        let trimData;
        if (name === 'interestRate') {
          trimData = value.replace('%', '').trim();
        } else {
          trimData = value.replace(CURRENCY_FORMAT, '').trim();
        }
        inputValue = parseFloat(trimData.replace('.', '').replace(',', '.'));
      }
    } else {
      setErrors((prevState) => ({
        ...prevState,
        [name]: strings.inputEmpty,
      }));
    }
    setState((prevState) => ({
      ...prevState,
      [name]: value,
      values: {
        ...prevState.values,
        [name]: inputValue,
      },
    }));
  };

  const handleDateChange = (value) => {
    // if different then the first day of next month then show error
    if (moment(value).format('DD') !== '01' || moment(value).isBefore()) {
      setErrors((prevState) => ({
        ...prevState,
        firstPaymentDate: strings.restSelectFirstOfMonth,
      }));
    } else {
      const errorsObj = errors;
      if (Object.keys(errorsObj).length > 0) {
        if ({}.hasOwnProperty.call(errorsObj, 'firstPaymentDate')) {
          delete errorsObj?.firstPaymentDate;
        }
      }
      setErrors(errorsObj);
    }
    setState((prevState) => ({
      ...prevState,
      firstPaymentDate: value,
      values: {
        ...prevState.values,
        firstPaymentDate: value,
      },
    }));
  };

  const confirmRestructure = () => {
    const totalRemaningBalance =
      totalBalance - state.values.extraPayment + state.values.latePaymentFee;
    const data = {
      creditFacilityNumber: creditFacility?.number,
      loanAmount: totalRemaningBalance,
      latePaymentFee: state.values.latePaymentFee,
      setupFee: state.values.restructuringFee,
      startDate: state.values.firstPaymentDate
        ? moment(state.values.firstPaymentDate).format('YYYY-MM-DD')
        : '',
      interestRate: state.values.interestRate,
      duration: state.values.duration,
      extraPayment: parseInt(state.extraPayment.replace('kr', ''), 10),
      reason: state.reason?.value,
      durationLocked: !state.adjustDuration,
    };
    dispatch(updateRestructureLoan(data))
      .then(() => {
        toggleConfirmModal();
        setState((prevState) => ({
          ...prevState,
          loading: false,
        }));
        dispatch(
          success(
            addNotification(
              strings.formatString(
                strings.restNotificationBody,
                `${activeLead.leadClientInformation.firstName} ${activeLead.leadClientInformation.lastName}`
              ),
              strings.restNotificationTitle,
              notificationDismissDuration
            )
          )
        );
        dispatch(getUpdatedPatientCreditFacility(creditFacility.id));
        dispatch(getLogsCreditFacility(creditFacility.number));
        setActiveTab('0');
      })
      .catch(() => {
        setState((prevState) => ({
          ...prevState,
          loading: false,
        }));
        dispatch(
          notifError(
            addNotification(
              '',
              strings.restNotificationTitleError,
              notificationDismissDuration
            )
          )
        );
      });
  };

  if (leadLoans.length > 0 && Object.keys(state).length > 0) {
    return (
      <>
        <form className='restructuring-form' autoComplete='off'>
          <Row>
            <Col xs='12' md='7'>
              <Row>
                <Col xs='6' md='4'>
                  <Label htmlFor='restructuringFee'>
                    <span className='long-version'>{strings.restFee}</span>
                    <span className='short-version'>
                      {strings.restFeeShort}
                    </span>
                  </Label>
                  <div className='amount-wrapper'>
                    <CustomMaskInput
                      type='text'
                      name='restructuringFee'
                      className='form-control'
                      id='restructuringFee'
                      placeholder='0,00 kr.'
                      maskOptions={{
                        suffix: ` ${CURRENCY_FORMAT}`,
                        decimalSymbol: ',',
                      }}
                      value={state.restructuringFee}
                      onChange={handleInputChange}
                      onFocus={(e) => e.target.select()}
                    />
                    {errors.restructuringFee !== undefined && (
                      <div className='text-danger'>
                        {errors.restructuringFee}
                      </div>
                    )}
                  </div>
                </Col>
                <Col xs='6' md='4'>
                  <Label htmlFor='latePaymentFee'>
                    <span className='long-version'>
                      {strings.restLatePaymentFee}
                    </span>
                    <span className='short-version'>
                      {strings.restLatePaymentFeeShort}
                    </span>
                  </Label>
                  <div className='amount-wrapper'>
                    <CustomMaskInput
                      type='text'
                      name='latePaymentFee'
                      className='form-control'
                      id='latePaymentFee'
                      placeholder='0,00 kr.'
                      maskOptions={{
                        suffix: ` ${CURRENCY_FORMAT}`,
                        decimalSymbol: ',',
                      }}
                      value={state.latePaymentFee}
                      onChange={handleInputChange}
                      onFocus={(e) => e.target.select()}
                    />
                    {errors.latePaymentFee !== undefined && (
                      <div className='text-danger'>{errors.latePaymentFee}</div>
                    )}
                  </div>
                </Col>
                <Col xs='6' md='4'>
                  <Label htmlFor='extraPayment'>
                    {strings.restExtraPayment}
                  </Label>
                  <div className='amount-wrapper'>
                    <CustomMaskInput
                      type='text'
                      name='extraPayment'
                      className='form-control'
                      id='extraPayment'
                      placeholder='0,00 kr.'
                      maskOptions={{
                        suffix: ` ${CURRENCY_FORMAT}`,
                        decimalSymbol: ',',
                      }}
                      value={state.extraPayment}
                      onChange={handleInputChange}
                      onFocus={(e) => e.target.select()}
                    />
                    {errors.extraPayment !== undefined && (
                      <div className='text-danger'>{errors.extraPayment}</div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs='6' md='4'>
                  <Label htmlFor='firstPaymentDate'>
                    <span className='long-version'>
                      {strings.restFirstPaymentDate}
                    </span>
                    <span className='short-version'>
                      {strings.restFirstPaymentDateShort}
                    </span>
                  </Label>
                  <div className='credit-date-picker'>
                    <DatePicker
                      id='first-payment-datepicker'
                      value={state.firstPaymentDate}
                      selected={state.firstPaymentDate}
                      locale='da'
                      dateFormat='DD-MM-YYYY'
                      showClearButton={false}
                      onChange={(v, f) => handleDateChange(v, f)}
                    />
                    <i
                      className='lnil lnil-calender-alt'
                      htmlFor='first-payment-datepicker'
                    ></i>
                    {errors.firstPaymentDate !== undefined && (
                      <div className='text-danger'>
                        {errors.firstPaymentDate}
                      </div>
                    )}
                  </div>
                </Col>
                <Col xs='6' md='4'>
                  <Label htmlFor='interestRate'>
                    {strings.restInterestRate}
                  </Label>
                  <div>
                    <CustomMaskInput
                      type='text'
                      name='interestRate'
                      className='form-control'
                      id='interestRate'
                      placeholder='0,00 %'
                      maskOptions={{ suffix: ' %', decimalSymbol: ',' }}
                      value={state.interestRate}
                      onChange={handleInputChange}
                      onFocus={(e) => e.target.select()}
                    />
                    {errors.interestRate !== undefined && (
                      <div className='text-danger'>{errors.interestRate}</div>
                    )}
                  </div>
                </Col>
                <Col xs='6' md='4'>
                  <Label htmlFor='cause'>{strings.restCause}</Label>
                  <div>
                    <Select
                      options={reasonsForRestructure}
                      isMulti={false}
                      placeholder={`- ${strings.dropPlaceholder} -`}
                      value={state.reason}
                      onChange={(selectedReason) => {
                        setState((prevState) => ({
                          ...prevState,
                          reason: selectedReason,
                        }));
                        const errorsUpdated = errors;
                        delete errorsUpdated?.reason;
                        setErrors(errorsUpdated);
                      }}
                      className='custom_select'
                      classNamePrefix='custom_select'
                      menuPlacement='auto'
                    />
                    <div
                      className={`text-danger ${
                        errors.reason === undefined ? 'invisible' : ''
                      }`}
                    >
                      {errors.reason}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col className='aside-content' xs='12' md='5'>
              <Row>
                <Col className='text-uppercase'>
                  <Label>{strings.restDuration}</Label>
                </Col>
                <Col className='text-right'>
                  {strings.formatString(strings.restMonths, state?.duration)}
                </Col>
              </Row>
              <Row>
                <Col className='text-uppercase'>
                  <Label>{strings.restAPR}</Label>
                </Col>
                <Col
                  className={`text-right ${
                    state?.apr > maxAprRestructuring ? 'text-danger' : ''
                  }`}
                >
                  {formatInterestRate(state?.apr)}
                </Col>
              </Row>
              <Row>
                <Col className='text-uppercase'>
                  <Label> {strings.restMonthlyPayment}</Label>
                </Col>
                <Col
                  className={`text-right monthly ${
                    errors?.maxMonthlyPayment ? 'text-danger' : ''
                  }`}
                >
                  {currencyFormatDA(state?.monthlyPayment, true, true)}
                </Col>
              </Row>
              <div className='form-group duration-slider'>
                <input
                  type='range'
                  name='duration'
                  className='custom-range'
                  min='2'
                  max='100'
                  step='1'
                  value={state.duration}
                  onChange={handleInputChange}
                  disabled={Object.keys(errors).includes('reason')}
                />
              </div>
              <SimpleCheckbox
                customStyles='mt-0 pt-0 #444444'
                textColor={{ color: '#444444' }}
                content={strings.restAllowDuration}
                handleClick={() => {
                  setState((prevState) => ({
                    ...prevState,
                    adjustDuration: !prevState.adjustDuration,
                  }));
                }}
                checked={state?.adjustDuration}
              />
            </Col>
          </Row>
          <Row>
            <Col className='text-center'>
              <Button
                color='blue'
                disabled={
                  Object.keys(errors).length > 0 ||
                  (Object.keys(errors).length === 0 &&
                    parseFloat(state.apr) > maxAprRestructuring)
                }
                onClick={() => toggleConfirmModal()}
              >
                <i className='di di-calculator' />
                {strings.restLoansRestructure}
              </Button>
            </Col>
          </Row>
        </form>
        {state.showConfirmModal && (
          <DentiModal
            key='export-modal'
            close={() => toggleConfirmModal()}
            modalClass='confirm-modal export-modal restructure-modal'
            modalClassName='confirm-action'
          >
            <ExportConfirmationModal
              confirmAction={() => {
                confirmRestructure();
                setState((prevState) => ({
                  ...prevState,
                  loading: true,
                }));
              }}
              cancelAction={() => toggleConfirmModal()}
              confirmText={strings.restModalConfirm}
              cancelText={strings.restModalCancel}
              loading={state.loading}
            >
              <div>
                <p className='title'>{strings.restModalTitle}</p>
                <div className='confirm-message'>
                  <p>
                    {strings.formatString(
                      strings.restModalText,
                      <b>{`${state.duration} ${strings.monthsShort}`}</b>,
                      <b>{`${
                        state.interestRate.toString().includes('%')
                          ? state.interestRate
                          : state.interestRate + '%'
                      }`}</b>,
                      <b>
                        {currencyFormatDA(
                          state.values.restructuringFee,
                          true,
                          true
                        )}
                      </b>,
                      <b>
                        {currencyFormatDA(state.monthlyPayment, true, true)}
                      </b>
                    )}
                  </p>
                </div>
              </div>
            </ExportConfirmationModal>
          </DentiModal>
        )}
      </>
    );
  } else return null;
};

export default RestructureForm;
