import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ButtonLoader from './ButtonLoader/ButtonLoader';

export default function ExportConfirmationModal(props) {
  const [loading, setLoading] = useState(false);

  const handleConfirmAction = () => {
    if (!loading) {
      setLoading(true);
      props.confirmAction();
    }
  };

  return (
    <div className='status-modal'>
      {props.children}
      <div className='bottom-section action-btn cf'>
        <button
          className='submit-btn btn btn-blue center-block'
          type='button'
          onClick={handleConfirmAction}
          disabled={loading}
        >
          {!loading ? (
            <>
              <i className='di di-accept' />
              <span>{props.confirmText}</span>
            </>
          ) : (
            <ButtonLoader />
          )}
        </button>

        <button
          className='cancel-btn btn btn-light center-block'
          type='button'
          onClick={props.cancelAction}
        >
          <i className='di di-cancel' />
          <span>{props.cancelText}</span>
        </button>
      </div>
    </div>
  );
}

ExportConfirmationModal.propTypes = {
  confirmAction: PropTypes.func,
  cancelAction: PropTypes.func,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
};
