import { success, error as notifError } from 'react-notification-system-redux';
import { requestAxios as axios, addNotification } from '../constants/utils';
import { getLoggedInUserCredentials } from './userActions';
import { getLogsCreditFacility } from './logActions';
import { setServerModalError } from './uiElementsActions';
import { strings } from '../constants/localization';
import * as constants from '../constants';

export function setCurrentLead(lead = undefined) {
  localStorage.setItem('lastSelectedClient', JSON.stringify(lead));
  return {
    type: constants.SET_CURRENT_LEAD,
    payload: lead,
  };
}

export function setActivePage(activePage) {
  return {
    type: constants.SET_LEAD_ACTIVE_PAGE,
    payload: activePage,
  };
}

export function setOpenLead(leadId) {
  const request = axios({
    method: 'post',
    url: `${constants.SERVER_URL}/api/v2/lead/isleadOpened/${leadId}`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
    data: leadId,
    headers: { 'Content-Type': 'application/json' },
  });

  return {
    type: constants.SET_OPEN_LEAD,
    payload: request,
  };
}

export function leadUpdateInformation(id, leadData) {
  const editLead = true;

  return (dispatch) =>
    new Promise((resolve, reject) => {
      // dispatch({
      //   type: constants.EDIT_LEAD_LOADING,
      //   payload: editLead,
      // });

      axios({
        method: 'patch',
        url: `${constants.SERVER_URL}/api/v1/lead/patchClientInformation/id=${id}&leadClientChangeRequestBody={leadClientChangeRequestBody}`,
        withCredentials: true,
        auth: getLoggedInUserCredentials(),
        data: leadData,
      })
        .then((response) => {
          dispatch({
            type: constants.EDIT_LEAD_LOADING,
            payload: !editLead,
          });

          resolve(response);
        })
        .catch((error) => {
          dispatch({
            type: constants.EDIT_LEAD_LOADING,
            payload: !editLead,
          });
          dispatch({
            type: constants.UPDATE_LEAD_INFORMATION,
            payload: error,
          });

          reject(error);
        });
    });
}

export function updateLeadStatus(id, status) {
  const request = axios({
    method: 'put',
    url: `${constants.SERVER_URL}/api/v1/lead/changeLeadStatus/id=${id}&status=${status}`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
  });

  return {
    type: constants.UPDATE_LEAD_STATUS,
    payload: request,
  };
}

export function updateMembershipsStatus(clientStatus, cpr) {
  const request = axios({
    method: 'post',
    url: `${constants.SERVER_URL}/api/v1/client/updateClientStatus`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
    data: {
      clientStatus,
      cpr,
    },
  });
  return {
    type: constants.UPDATE_LEAD_MEMBERSHIP_STATUS,
    payload: request,
  };
}

export function deleteLead(cpr) {
  const request = axios({
    method: 'post',
    url: `${constants.SERVER_URL}/api/v1/client/anonymizeClientByCpr`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
    data: {
      cpr,
    },
  });
  return {
    type: constants.DELETE_LEAD,
    payload: request,
  };
}

export function deleteOnboardingMember(memberUuid) {
  const request = axios({
    method: 'delete',
    url: `${constants.SERVER_URL}/api/v1/member/anonymize/${memberUuid}`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
  });
  return {
    type: constants.DELETE_ONBOARDING_MEMBER,
    payload: request,
  };
}

export function updateLeadDunningStatus(creditNumber, status) {
  const request = axios({
    method: 'post',
    url: `${constants.SERVER_URL}/api/v1/creditFacility/changeDunningStatus`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
    data: {
      creditFacilityNumber: creditNumber,
      dunningStatus: status,
    },
  });

  return {
    type: constants.UPDATE_DUNNING_STATUS,
    payload: request,
  };
}

export function suspendLeadCreditFacility(
  creditFacilityNumber,
  creditFacilityStatus
) {
  const request = axios({
    method: 'put',
    url: `${constants.SERVER_URL}/api/v1/creditFacility/setCreditFacilityStatus`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
    data: {
      creditFacilityNumber,
      creditFacilityStatus,
    },
  });

  return {
    type: constants.UPDATE_CREDIT_STATUS,
    payload: request,
  };
}

export function grantClinicAccess(creditFacilityNumber, companyNumber) {
  const request = axios({
    method: 'put',
    url: `${constants.SERVER_URL}/api/v1/creditfacilities`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
    data: {
      creditFacilityNumber,
      companyNumber,
    },
  });

  return {
    type: constants.GRANT_CLINIC_ACCESS,
    payload: request,
  };
}

export function searchEmitted(search = false) {
  return {
    type: constants.PATIENT_SEARCH_EMITTED,
    payload: { search },
  };
}

export function filterEmitted(newFilter) {
  return {
    type: constants.PATIENT_UPDATE_FILTER,
    payload: newFilter,
  };
}

export function leadsRequestStatus(status = constants.LEADS_LOADING) {
  return {
    type: status,
    payload: status,
  };
}

export function getLeads(page = 0, callback = undefined, signal = null) {
  return async (dispatch, getState) => {
    const { activeLead, globalLoading, filters, searchActive } =
      getState().lead;
    const searchInput = searchActive?.searchInput;
    const paramsRequest = {};

    // pagination
    if (page > 0) {
      paramsRequest.page = page;
    } else {
      paramsRequest.page = 0;
    }

    // search
    if (searchInput !== null && searchInput !== '' && searchInput.length < 12) {
      paramsRequest.searchedInput = searchInput
        .trim()
        .replace(/\s/g, '')
        .split('-')
        .join('');
    } else if (searchInput === '') {
      // { "searchedInput": null } performs better than { "searchedInput": "" }
      paramsRequest.searchedInput = null;
    } else {
      paramsRequest.searchedInput = searchInput;
    }
    if (isNaN(paramsRequest.searchedInput)) {
      paramsRequest.searchedInput = searchInput;
    }

    if (filters && Object.keys(filters).length > 0) {
      Object.entries(filters).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          if (value?.length > 0) {
            // check brand to add '+' sign to SUNDHED
            if (key === 'brand') {
              paramsRequest.brand = value?.map((brandString) =>
                brandString === 'SUNDHED' ? 'SUNDHED+' : brandString
              );
            } else {
              paramsRequest[key] = value;
            }
          }
        } else if (value) {
          paramsRequest[key] = value;
        }
      });
    }

    // remove the company name from the payload if present
    if (Object.prototype.hasOwnProperty.call(paramsRequest, 'companyName')) {
      delete paramsRequest.companyName;
    }

    dispatch({
      type: constants.LEADS_LOADING,
      payload: constants.LEADS_LOADING,
    });

    try {
      const response = await axios({
        method: 'post',
        url: `${constants.SERVER_URL}/api/v1/leads/GET`,
        withCredentials: true,
        auth: getLoggedInUserCredentials(),
        data: paramsRequest,
        ...(signal && { signal }), // add the signal to the request if it exists; used to abort the request
      });
      const leadsList = response.data;

      dispatch({
        type: constants.GET_LEADS,
        payload: leadsList,
      });

      if (leadsList.content.length > 0) {
        if (!!searchActive.flag && leadsList.content.length === 1) {
          const currentLead = leadsList.content[0];
          dispatch(setCurrentLead(currentLead));
          dispatch(
            getLogsCreditFacility(currentLead.creditFacilityInformation?.number)
          );
          dispatch(
            getInstalmentLoanByCreditFacility(
              currentLead.creditFacilityInformation?.number
            )
          );
        }

        if (activeLead !== undefined && leadsList.content !== undefined) {
          const currentLead = leadsList.content.find(
            (lead) =>
              activeLead.id === lead.id || activeLead.leadUuid === lead.leadUuid
          );
          if (currentLead !== undefined && leadsList.content.length !== 1) {
            dispatch(setCurrentLead(currentLead));
            dispatch(
              getLogsCreditFacility(
                currentLead.creditFacilityInformation.number
              )
            );
            dispatch(
              getInstalmentLoanByCreditFacility(
                currentLead.creditFacilityInformation.number
              )
            );
          }
        }

        dispatch({
          type: constants.LEADS_SUCCESS,
          payload: constants.LEADS_SUCCESS,
        });
      } else {
        dispatch({
          type: constants.LEADS_EMPTY,
          payload: constants.LEADS_EMPTY,
        });
      }

      if (globalLoading) {
        // dispatch global loading
        dispatch({
          type: constants.EDIT_LEAD_LOADING,
          payload: !globalLoading,
        });
      }

      if (callback !== undefined) {
        callback();
      }
    } catch (error) {
      if (!error.response && error?.message === 'Network Error') {
        dispatch(setServerModalError(error.message));
      }
      if (error?.name !== 'CanceledError') {
        dispatch({
          type: constants.LEADS_FAILURE,
          payload: constants.LEADS_FAILURE,
        });
      }

      if (globalLoading) {
        // dispatch global loading
        dispatch({
          type: constants.EDIT_LEAD_LOADING,
          payload: !globalLoading,
        });
      }
    }
  };
}

export function getUpdatedPatientCreditFacility(creditFacilityId) {
  return async (dispatch) => {
    if (!creditFacilityId) {
      return;
    }
    try {
      const response = await axios.get(
        `${constants.SERVER_URL}/api/v2/leads/creditFacility/${creditFacilityId}`,
        {
          withCredentials: true,
          auth: getLoggedInUserCredentials(),
        }
      );

      if (response) {
        dispatch(setCurrentLead(response?.data));
        dispatch(updatePatientInLeadsList(response?.data));

        dispatch(
          getLogsCreditFacility(
            response?.data?.creditFacilityInformation?.number
          )
        );
        dispatch(
          getInstalmentLoanByCreditFacility(
            response?.data?.creditFacilityInformation?.number
          )
        );
      }
    } catch {
      dispatch({
        type: constants.LEADS_FAILURE,
        payload: constants.LEADS_FAILURE,
      });
    }
  };
}

export function updateLeadInternalNote(id, internalNote) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      const payloadData = { id, internalNote };
      axios({
        method: 'patch',
        url: `${constants.SERVER_URL}/api/v1/leads/internalNote`,
        withCredentials: true,
        auth: getLoggedInUserCredentials(),
        data: payloadData,
      })
        .then((response) => {
          dispatch({
            type: constants.SET_LEAD_INTERNAL_NOTE,
            payload: payloadData,
          });

          dispatch(
            success(
              addNotification(
                strings.patientInternalNoteSuccessMessage,
                strings.patientSuccessTitle,
                constants.notificationDismissDuration
              )
            )
          );
          resolve(response);
        })
        .catch((error) => {
          dispatch(
            notifError(
              addNotification(
                strings.patientInternalNoteErrorMessage,
                strings.patientErrorTitle,
                constants.notificationDismissDuration
              )
            )
          );
          // eslint-disable-next-line
          console.error(error);
          reject(error);
        });
    });
}

export function getInstalmentLoanByCreditFacility(creditFacilityNumber) {
  return (dispatch) => {
    axios({
      method: 'get',
      url: `${constants.SERVER_URL}/api/v2/getInstallmentloanByCreditFacilityNumber/${creditFacilityNumber}`,
      withCredentials: true,
      auth: getLoggedInUserCredentials(),
    }).then((response) => {
      dispatch({
        type: constants.GET_LOANS_BY_CREDIT_FACILITY,
        payload: response.data,
      });
    });
  };
}

export function getLeadClinics(creditFacilityNumber) {
  return (dispatch) => {
    axios({
      method: 'get',
      url: `${constants.SERVER_URL}/api/v1/creditFacility/getAssignedCompanies/${creditFacilityNumber}`,
      withCredentials: true,
      auth: getLoggedInUserCredentials(),
    }).then((response) => {
      dispatch({
        type: constants.GET_LEAD_CLINICS,
        payload: response.data,
      });
    });
  };
}

export function updateAssignedUser(creditNumber, admin) {
  const request = axios({
    method: 'post',
    url: `${constants.SERVER_URL}/api/v1/creditFacility/changeAssignedAdmin`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
    data: {
      creditFacilityNumber: creditNumber,
      username: admin,
    },
  });

  return {
    type: constants.UPDATE_ASSIGNED_USER,
    payload: request,
  };
}

export function updateCreditFacilityDunningType(creditNumber, dunningType) {
  const request = axios({
    method: 'post',
    url: `${constants.SERVER_URL}/api/v1/creditFacility/changeCreditFacilityDunningType`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
    data: {
      creditFacilityNumber: creditNumber,
      dunningType,
    },
  });

  return {
    type: constants.UPDATE_CREDIT_DUNNING_TYPE,
    payload: request,
  };
}

export function updateRestructureLoan(restructureData) {
  const request = axios({
    method: 'post',
    url: `${constants.SERVER_URL}/api/v1/installmentLoans/LoanRestructuring`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
    data: restructureData,
  });

  return {
    type: constants.UPDATE_RESTRUCTURE_LOAN,
    payload: request,
  };
}

export function updateCreditFacilityDetails(newCreditData) {
  const request = axios({
    method: 'post',
    url: `${constants.SERVER_URL}/api/v1/creditFacility/updateCreditFacilityInfo `,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
    data: newCreditData,
  });

  return {
    type: constants.UPDATE_CREDIT_DETAILS,
    payload: request,
  };
}

export function removeCompanyFromCreditFacility(
  companyReferenceNumber,
  clientCpr
) {
  const request = axios({
    method: 'post',
    url: `${constants.SERVER_URL}/api/v1/creditFacility/removeCompanyFromCreditFacility`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
    data: {
      companyReferenceNumber,
      clientCpr,
    },
  });

  return {
    type: constants.DELETE_COMPANY_FROM_CREDIT_FACILITY,
    payload: request,
  };
}

export function updateActivePatientInfo(newClientInfo) {
  return {
    type: constants.UPDATE_ACTIVE_PATIENT_INFO,
    payload: newClientInfo,
  };
}

export function updateActivePatientInternalNote(newClientNote) {
  return (dispatch, getState) => {
    const { activeLead } = getState().lead;
    let newObj = activeLead;
    newObj.internalNote = newClientNote;
    dispatch(updatePatientInLeadsList(newObj));
    return {
      type: constants.UPDATE_ACTIVE_PATIENT_NOTE,
      payload: newClientNote,
    };
  };
}

export function updatePatientInLeadsList(newClientInfo) {
  return {
    type: constants.UPDATE_ACTIVE_PATIENT_LEADS_LIST,
    payload: newClientInfo,
  };
}

export function setClientLoading(loadingStatus) {
  return {
    type: constants.SET_CLIENT_LOADING,
    payload: loadingStatus,
  };
}

export function openLoanDetails(loanNumber, showLoanDetailsModal = false) {
  return {
    type: constants.SET_LOAN_DETAILS,
    payload: {
      loanNumber,
      showLoanDetailsModal,
    },
  };
}

export function addWsPatientToUpdate(creditId) {
  return {
    type: constants.ADD_WS_PATIENT_UPDATE,
    payload: creditId,
  };
}

export function removeWsPatientToUpdate(creditId) {
  return {
    type: constants.REMOVE_WS_PATIENT_UPDATE,
    payload: creditId,
  };
}

export function sendRenewLinkTolead(creditFacilityNumber, sendEmail, sendSms) {
  const request = axios({
    method: 'post',
    url: `${constants.SERVER_URL}/api/v1/sendCreditFacilityRenewalLink`,
    withCredentials: true,
    auth: getLoggedInUserCredentials(),
    data: {
      creditFacilityNumber,
      sendEmail,
      sendSms,
    },
  });

  return {
    type: constants.SEND_RENEW_LINK_TO_PATIENT,
    payload: request,
  };
}

export function getClientDataForPartialRedemption(
  leadUuid,
  creditFacilityNumber
) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: `${constants.SERVER_URL}/public/v1/creditFacility/getClientForPartialRedemption`,
        params: {
          leadUuid,
          creditFacilityNumber,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: constants.GET_CLIENT_DATA_FOR_PARTIAL_REDEMPTION,
              payload: response,
            });
            resolve(response);
          } else {
            reject(response); // Reject if not a 200 status
          }
        })
        .catch((error) => {
          console.error('Error fetching client data', error);
          dispatch({
            type: constants.GET_CLIENT_DATA_FOR_PARTIAL_REDEMPTION,
            payload: error,
          });
          reject(error); // Reject on axios errors
        });
    });
}
