import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Pagination from 'react-js-pagination';
import { ListGroup, Form, FormGroup } from 'reactstrap';
import { strings } from '../../../constants/localization';
import {
  trackVerticalStyle,
  thumbVerticalStyle,
  thumbVerticalCustomBackgroundStyle,
} from '../../../constants/styleScrollbar';
import {
  APPLICATIONS_EMPTY,
  APPLICATIONS_FAILURE,
  APPLICATIONS_LOADING,
  APPLICATIONS_SUCCESS,
} from '../../../constants';
import { setSelectedListItemFromStorage } from '../../../constants/utils';
import {
  getAllApplications,
  setCurrentApplicationItem,
} from '../../../actions/onboardingApplicationsActions';
import ApplicationListItem from './ApplicationListItem';
import ApplicationFilter from './ApplicationFilter';
import LoadingMessage from '../../statusMessages/LoadingMessage';
import EmptyMessage from '../../statusMessages/EmptyMessage';
import ServerModalError from '../../common/ServerModalError';

const ApplicationList = ({ toggleMenu }) => {
  const location = useLocation();
  const search = location.search;
  const searchParams = new URLSearchParams(search);
  const scrollRef = useRef();
  const refName = useRef();
  const dispatch = useDispatch();
  const [renderSituation, setRenderSituation] = useState(null);
  // stored filters
  const filters = useSelector(
    (state) => state.onboardingApplication?.filterParams
  );
  const activePage = useSelector(
    (state) => state.onboardingApplication?.activePage
  );
  const [searchInput, setSearchInput] = useState(filters?.searchInput || null);
  const [activePageLocal, setActivePageLocal] = useState(activePage || 1);

  const [applicationsList, setApplicationsList] = useState([]);
  const activeApplicationListItem = useSelector(
    (state) => state.onboardingApplication?.activeApplicationListItem
  );
  const applicationsLoading = useSelector(
    (state) => state.onboardingApplication?.loading
  );
  const applicationsData = useSelector(
    (state) => state.onboardingApplication?.data?.content
  );
  const applicationsResult = useSelector(
    (state) => state.onboardingApplication?.data
  );

  // when the component mounts get the list of all credit applications
  useEffect(() => {
    const searchQuery = searchParams.get('q');
    const { cpr, creditApplicationStatus, dateLower, dateUpper, searchInput } =
      filters || {};
    if (searchQuery) {
      setSearchInput(searchQuery);
      dispatch(
        getAllApplications(
          cpr,
          creditApplicationStatus,
          dateLower,
          dateUpper,
          searchQuery,
          activePageLocal - 1
        )
      );
    } else {
      dispatch(
        getAllApplications(
          cpr,
          creditApplicationStatus,
          dateLower,
          dateUpper,
          searchInput,
          activePageLocal - 1
        )
      );
      setSelectedListItemFromStorage(
        'lastSelectedApplication',
        (lastStelected) => {
          dispatch(setCurrentApplicationItem(lastStelected));
        }
      );
    }
  }, []);

  useEffect(() => {
    if (applicationsData?.length > 0) {
      setApplicationsList(applicationsData);
    }
  }, [applicationsData]);

  // if page is updated to store, update also the local state
  useEffect(() => {
    if (activePageLocal !== activePage) {
      setActivePageLocal(activePage);
    }
  }, [activePage]);

  useEffect(() => {
    let situationComponent;

    switch (applicationsLoading) {
      case APPLICATIONS_LOADING:
        situationComponent = <LoadingMessage />;
        break;
      case APPLICATIONS_SUCCESS:
        situationComponent = renderList();
        break;
      case APPLICATIONS_EMPTY:
        situationComponent = (
          <div className='scrollbar-pseudo-rail'>
            {searchInput !== undefined && searchInput !== '' ? (
              <EmptyMessage message={strings.searchEmpty} />
            ) : (
              <>
                {activeApplicationListItem === undefined ? (
                  <EmptyMessage
                    message={strings.formatString(
                      strings.noItems,
                      strings.applicationsPlaceHolder
                    )}
                  />
                ) : (
                  <EmptyMessage
                    message={strings.formatString(
                      strings.noCategoryItems,
                      strings.applicationsPlaceHolder
                    )}
                  />
                )}
              </>
            )}
          </div>
        );
        break;
      case APPLICATIONS_FAILURE:
        situationComponent = (
          <div className='scrollbar-pseudo-rail'>
            <ServerModalError />
          </div>
        );
        break;
      default:
        situationComponent = (
          <div className='scrollbar-pseudo-rail'>
            <EmptyMessage
              message={strings.formatString(
                strings.noItems,
                strings.applicationsPlaceHolder
              )}
            />
          </div>
        );
    }

    setRenderSituation(situationComponent);

    // eslint-disable-next-line
  }, [
    applicationsLoading,
    searchInput,
    activeApplicationListItem,
    applicationsList,
  ]);

  const handleSearchSubmit = (values) => {
    const { cpr, creditApplicationStatus, dateLower, dateUpper, searchInput } =
      values || {};
    dispatch(
      getAllApplications(
        cpr,
        creditApplicationStatus,
        dateLower,
        dateUpper,
        searchInput,
        0
      )
    );
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const { cpr, creditApplicationStatus, dateLower, dateUpper } =
      filters || {};

    // if (searchInput !== '') {
    dispatch(
      getAllApplications(
        cpr,
        creditApplicationStatus,
        dateLower,
        dateUpper,
        searchInput,
        0
      )
    );
    // }
  };

  const handleClearSearch = () => {
    setSearchInput('');
    dispatch(getAllApplications(null, null, null, null, null, 0));
  };

  const handlePageChange = (pageNumber) => {
    const { cpr, creditApplicationStatus, dateLower, dateUpper } =
      filters || {};
    if (pageNumber !== activePage) {
      setActivePageLocal(pageNumber);

      dispatch(
        getAllApplications(
          cpr,
          creditApplicationStatus,
          dateLower,
          dateUpper,
          searchInput,
          pageNumber - 1
        )
      );
    }
  };

  const renderList = () => {
    const brandEnvVariable = process.env.REACT_APP_BRAND;
    return (
      <div className='entities-list-group'>
        <Scrollbars
          ref={scrollRef}
          renderTrackVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                ...trackVerticalStyle,
              }}
            />
          )}
          renderThumbVertical={({ style, ...props }) =>
            brandEnvVariable === 'Denti' ? (
              <div
                {...props}
                style={{
                  ...style,
                  ...thumbVerticalStyle,
                }}
              />
            ) : (
              <div
                {...props}
                style={{
                  ...style,
                  ...thumbVerticalCustomBackgroundStyle,
                }}
              />
            )
          }
        >
          <div className='scrollbar-wrapper'>
            <ListGroup className='applications-list-group'>
              {applicationsList &&
                applicationsList?.map((application, index) => {
                  return (
                    <ApplicationListItem
                      key={index}
                      application={application}
                    />
                  );
                })}
            </ListGroup>
            {applicationsResult && applicationsResult?.totalPages > 1 && (
              <Pagination
                activePage={applicationsResult.pageable.page + 1}
                itemClass='page-item'
                linkClass='page-link'
                itemsCountPerPage={applicationsResult.size}
                totalItemsCount={applicationsResult.totalElements}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
              />
            )}
          </div>
        </Scrollbars>
      </div>
    );
  };

  return (
    <>
      <div className='header'>
        <button
          type='button'
          className='btn btn-white menu-toggler'
          onClick={toggleMenu}
        >
          <div className='menu-toggler-icon'>
            <span />
            <span />
            <span />
          </div>
        </button>
        <h3 className='title'>{strings.applicationsSideBarMenuItem}</h3>
        <Form
          className='search-form'
          onSubmit={handleSearch}
          autoComplete='off'
        >
          {searchInput ? (
            <button
              type='button'
              className='btn-clear-search'
              onClick={handleClearSearch}
            >
              <span className='clear-search di di-close' />
            </button>
          ) : (
            <button type='submit' className='btn-search'>
              <span className='lnir lnir-search-alt' />
            </button>
          )}
          <FormGroup className='mb-0'>
            <input
              className='search-query form-control'
              id='searchInput'
              type='text'
              ref={refName}
              onChange={(e) => setSearchInput(e.target.value)}
              value={searchInput || ''}
              placeholder={strings.applicationsSeachPlaceholder}
            />
          </FormGroup>
        </Form>
      </div>
      <ApplicationFilter filterSubmit={handleSearchSubmit} />
      {renderSituation}
    </>
  );
};

export default ApplicationList;
