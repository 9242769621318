import React, { useEffect } from 'react';
import { useState } from 'react';
import { Fade } from 'reactstrap';
import LookupPatient from './LookupPatient';
import { shallowEqual, useSelector } from 'react-redux';

import PatientInfoAndCreditStatus from './PatientInfoAndCreditStatus';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import LoadingMessage from '../statusMessages/LoadingMessage';

import NewInvoice from '../invoices/NewInvoice/NewInvoice';
import PatientNotFound from './PatientNotfound';
import StepSendAppLink from '../add-patient/StepSendAppLink';
import StepSendAppSuccess from '../add-patient/StepSendAppSuccess';

import { useDispatch } from 'react-redux';
import {
  createCreditPageBySms,
  createCreditPageByEmail,
} from '../../actions/createCreditActions';

interface closeRegisterInvoiceModalProps {
  toggleCreateNewInvoice: () => void;
}

interface LeadClientInformation {
  cpr: number;
  firstName: string;
  lastName: string;
}

interface CreditFacilityInformation {
  status: string;
  expirationDate: string;
  amount: number;
  remainingTime: {
    totalDays: number;
  };
}

interface LeadsClientInformation {
  leadClientInformation: LeadClientInformation;
  creditFacilityInformation: CreditFacilityInformation;
}

interface LeadsInformationProps {
  patientInformation: LeadsClientInformation[];
}
interface UserProps {
  user: {
    activeCompany: string;
    companyName: string;
  };
}

const RegisterInvoiceFlow: React.FC<closeRegisterInvoiceModalProps> = ({
  toggleCreateNewInvoice,
}) => {
  const [currentCase, setCurrentCase] = useState<number>(1);
  const [clinic, setClinic] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [hasSentAppLink, setHasSentAppLink] = useState<boolean>(false);

  const [patientInfo, setPatientInfo] = useState<{
    cpr: string;
    letters: string;
  }>({
    cpr: '',
    letters: '',
  });
  const dispatch = useDispatch();
  const user = useSelector((state: { user?: UserProps }) => state.user || null);

  const activeCompany = user?.user?.activeCompany;
  const companyName = user?.user?.companyName;

  const patientLoading = useSelector(
    (state: { registerInvoiceFlow?: { loadingPatientInformation: boolean } }) =>
      state.registerInvoiceFlow?.loadingPatientInformation || false
  );

  const leadsInformation = useSelector(
    (state: { registerInvoiceFlow?: LeadsInformationProps }) =>
      state.registerInvoiceFlow?.patientInformation || [],
    shallowEqual
  );

  useEffect(() => {
    if (currentCase === 5 && email && phone && clinic && !hasSentAppLink) {
      setLoading(true);
      let sendBySmsFailed = false;

      const sendSms = phone
        ? dispatch(createCreditPageBySms(phone, clinic))
        : Promise.resolve();
      const sendEmail = email
        ? dispatch(createCreditPageByEmail(email, clinic))
        : Promise.resolve();

      Promise.all([sendSms, sendEmail])
        .then(() => {
          if (!sendBySmsFailed) {
            setLoading(false);
            setCurrentCase(6);
            setHasSentAppLink(true); // Set flag to true
          }
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
          toggleCreateNewInvoice();
        });
    }
  }, [
    currentCase,
    email,
    phone,
    clinic,
    hasSentAppLink,
    dispatch,
    toggleCreateNewInvoice,
  ]);

  const renderComponent = () => {
    switch (currentCase) {
      case 1:
        return (
          <LookupPatient
            setCurrentCase={setCurrentCase}
            setPatientData={setPatientInfo}
            companyUserAssociated={companyName}
            companyReferenceNumber={activeCompany}
          />
        );
      case 2:
        return (
          <PatientInfoAndCreditStatus
            toggleCreateNewInvoice={toggleCreateNewInvoice}
            setCurrentCase={setCurrentCase}
          />
        );

      case 3:
        return (
          <NewInvoice
            leadsInformation={leadsInformation}
            publicRegistrationInvoice
            toggleCreateNewInvoice={toggleCreateNewInvoice}
            invoiceFromFlow
          />
        );

      case 4:
        //send app link
        return (
          <StepSendAppLink
            user={user}
            activeCompany={activeCompany}
            isRegisterInvoiceFlow
            setRegisterInvoiceEmail={setEmail}
            setRegisterInvoicePhone={setPhone}
            setInvoiceClinic={setClinic}
            setCurrentCase={setCurrentCase}
          />
        );
      case 5:
        return null;
      case 6:
        return (
          <StepSendAppSuccess
            closeModal={toggleCreateNewInvoice}
            email={email || ''}
            phone={phone || ''}
          />
        );

      default:
        return (
          <PatientNotFound stateInfo={patientInfo} nextStep={setCurrentCase} />
        );
    }
  };
  const directionClassName = currentCase > 0 ? 'move-next' : 'move-prev';

  return (
    <div id='register-invoice-flow' className='registerInvoiceFlow'>
      {loading && <LoadingMessage />}
      <TransitionGroup
        appear
        timeout={600}
        className={`slide-group ${directionClassName} ${patientLoading ? 'loading-spinner' : ''
          }`}
      >
        <CSSTransition
          key={currentCase}
          timeout={300}
          classNames='fade'
          className='transition-container'
        >
          <Fade show={currentCase}>{renderComponent()}</Fade>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default RegisterInvoiceFlow;
