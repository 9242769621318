import React, { useEffect, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Row, Col } from 'reactstrap';
import {
  trackVerticalStyle,
  thumbVerticalStyle,
} from '../../constants/styleScrollbar';
import SundhedCreditInfo from './mitSundhedCredit/SundhedCreditInfo';
import EntityTableComponent from './common/EntityTableComponent';
import PaymentDetails from './mitSundhedCredit/PaymentDetails';
import PaymentAgreementsListItem from './mitSundhedCredit/PaymentAgreementsListItem';
import { strings } from '../../constants/localization';
import BillingHistoryListItem from './mitSundhedCredit/BillingHistoryListItem';
import PractitionerAccess from './mitSundhedCredit/PractitionerAccess';
import DocumentationCard from './mitSundhedCredit/DocumentatationCard';
import ServicesActions from './mitSundhedCredit/ServicesActions';
import { ServiceActionProvider } from './mitSundhedCredit/ServiceActionProvider';
import { useSelector } from 'react-redux';

interface PaymentAgreement {
  toggleMenu: () => void;
  setModalAcceptanceFlow: React.Dispatch<React.SetStateAction<boolean>>;
  setPaymentPlanModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setPartialRedemptionModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setWarningModal?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface installmentLoadDataProps {
  creditFacilityData: {
    remainingBalance: number;
    status: string;
    expirationDate: string;
    installmentLoanDataList: [
      {
        createdDate: string;
        monthlyPayment: number;
        interestRate: number;
        invoiceAmount: number;
        invoiceNumber: string;
        number: string;
        remainingBalance: number;
        amount: number;
        startDate: string;
        companyName: string;
        status: string;
      }
    ];
  };
}

const MitDashboard: React.FC<PaymentAgreement> = ({
  toggleMenu,
  setModalAcceptanceFlow,
  setPaymentPlanModal,
  setPartialRedemptionModal,
  setWarningModal
}) => {
  const [smallScreen, setSmallScreen] = useState<boolean>(false);
  const scrollRef = useRef<Scrollbars>(null);

  const clientCreditInformation = useSelector(
    (state: {
      memberPortal: { clientDataInformation: installmentLoadDataProps };
    }) => state.memberPortal?.clientDataInformation
  );

  const filteredOngoingPaymentList =
    clientCreditInformation?.creditFacilityData.installmentLoanDataList.filter(
      (agreement) => agreement.status === 'ACCEPTED'
    );

  const filteredNotDeletedOrDeprecated =
    clientCreditInformation?.creditFacilityData.installmentLoanDataList.filter(
      (item) => item.status !== 'DELETED' && item.status !== 'DEPRECATED'
    );
  const filteredBillingHistoryList = filteredNotDeletedOrDeprecated.filter(
    (item) => item.invoiceNumber !== 'Omlægning'
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 680) {
        setSmallScreen(true);
      } else {
        setSmallScreen(false);
      }
    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Function to set the vh unit exactly to the viewport height, accounting for the mobile browser bar
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    // Set vh unit on load
    setVh();

    // Set vh unit on resize
    window.addEventListener('resize', setVh);

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', setVh);
  }, []);

  const isDisabled =
    clientCreditInformation.creditFacilityData.status === 'DISABLED';
  const latePayment =
    clientCreditInformation.creditFacilityData.status === 'LATE_PAYMENT';

  const ongoingPaymentList = (list) => {
    const reversedList = list.reverse();
    return reversedList.map((agreement, index) => (
      <PaymentAgreementsListItem
        key={index}
        createdDate={agreement.createdDate}
        montlhyPayment={agreement.monthlyPayment}
        interestRate={agreement.interestRate}
        remainingBalance={agreement.remainingBalance}
        loanAmount={agreement.amount}
        loanNumber={agreement.number}
        setPaymentPlanModal={setPaymentPlanModal}
      />
    ));
  };

  const renderBillingHistoryList = (list) => {
    const reversedList = list.reverse();
    return reversedList.map((item, index) => (
      <BillingHistoryListItem
        key={index}
        {...item}
        setModalAcceptanceFlow={setModalAcceptanceFlow}
      />
    ));
  };

  const checkIfIsDisabledORLatePayment = isDisabled || latePayment;

  return (
    <Row className='app-row-height mitDashboard'>
      <Col className='p-0 app-col-height'>
        <Scrollbars
          ref={scrollRef}
          style={{
            height: '100vh',
          }}
          // eslint-disable-next-line
          renderTrackVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                ...trackVerticalStyle,
              }}
            />
          )}
          // eslint-disable-next-line
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                ...thumbVerticalStyle,
              }}
            />
          )}
        >
          <div className='mitSundhed-section'>
            <div className='sundhedCreditInfo'>
              <div className='entity-information-card'>
                <div className='header-information'>
                  <div className='header-text'>
                    {smallScreen ? (
                      <div className='card-title center'>
                        {strings.mitCreditTitleSmallScreen}
                      </div>
                    ) : (
                      <div className='card-title'>{strings.mitCreditTitle}</div>
                    )}
                  </div>
                  <button
                    type='button'
                    className='btn btn-white menu-toggler'
                    onClick={toggleMenu}
                  >
                    <div className='menu-toggler-icon'>
                      <span />
                      <span />
                      <span />
                    </div>
                  </button>
                </div>
                {/* Creidt staus and information card component  */}
                <SundhedCreditInfo />
              </div>
            </div>

            {/* wrap these components into a provider to have access to the same functions */}
            <ServiceActionProvider setPartialRedemptionModal={setPartialRedemptionModal} setWarningModal={setWarningModal}>
              {/* Show Active Payment component */}
              <div className='active-payment-agreement-section mt-3'>
                <div className='entityTableSection'>
                  <EntityTableComponent
                    tableList={
                      !checkIfIsDisabledORLatePayment
                        ? filteredOngoingPaymentList
                        : []
                    }
                    tableHeaders={[
                      strings.mitCreditAgreementTableCreated,
                      strings.mitCreditAgreementTableIMontly,
                      strings.mitCreditAgreementTableItemInterest,
                      strings.mitCreditAgreementTableItemOustandingDebt,
                      strings.mitCreditAgreementTableItemLoanAmount,
                    ]}
                    title={strings.mitCreditAgreementTitle}
                    totalDebtLabel={strings.mitCreditAgreementTotalDebt}
                    totalDebtAmount={
                      clientCreditInformation.creditFacilityData.remainingBalance
                    }
                    // activePaymentAgreement={!isDisabled ? true : false} add also the option that is the filteredOnGoingPaymentList is empty it´s false
                    activePaymentAgreement={
                      !checkIfIsDisabledORLatePayment &&
                        filteredOngoingPaymentList.length > 0
                        ? true
                        : false
                    }
                    tableEmptyList={strings.tablePaymentAgreementsEmptyTable}
                  >
                    {!checkIfIsDisabledORLatePayment &&
                      ongoingPaymentList(filteredOngoingPaymentList)}
                  </EntityTableComponent>
                </div>
              </div>

              {(!checkIfIsDisabledORLatePayment && filteredOngoingPaymentList?.length > 0) && (<div className='services-section mt-3'>
                <ServicesActions />
              </div>)}

            </ServiceActionProvider>


            {/* Payment information section with credit card */}
            {filteredOngoingPaymentList.length > 0 && !latePayment && (
              <div className='payment-details-section mt-3'>
                <PaymentDetails />
              </div>
            )}

            {/* Billing history section */}
            <div className='billing-history-section mt-3'>
              <div className='entityTableSection'>
                <EntityTableComponent
                  tableList={!isDisabled ? filteredBillingHistoryList : []}
                  tableHeaders={[
                    strings.mitBillingHistoryDate,
                    strings.mitBillingHistoryClinicAssociated,
                    strings.mitBillingHistoryStatus,
                    strings.mitBillingHistoryAmount,
                  ]}
                  title={strings.mitBillingHistoryTitle}
                  tableRowClass='billing-history-list'
                  tableEmptyList={strings.tableBillingHistoryEmptyTable}
                >
                  {!isDisabled &&
                    renderBillingHistoryList(filteredBillingHistoryList)}
                </EntityTableComponent>
              </div>
            </div>

            {/* Practitioner access section */}
            {!isDisabled && (
              <div className='practitioner-access-section mt-3'>
                <PractitionerAccess />
              </div>
            )}

            {/* Documents and contract section */}
            {!isDisabled && (
              <div className='documentation-section mt-3'>
                <DocumentationCard />
              </div>
            )}
          </div>
        </Scrollbars>
      </Col>
    </Row>
  );
};

export default MitDashboard;
