import React from 'react';
import { Button } from 'reactstrap';
import { strings } from '../../constants/localization';
// eslint-disable-next-line import/no-unresolved
import addPatientWarning from '../../resources/images/add-patient_warning.png';
import { useDispatch } from 'react-redux';
import { patientNotFounded } from '../../actions/registerInvoiceActions';

interface PatientNotFoundProps {
  stateInfo: {
    cpr: string;
    letters: string;
  };
  // eslint-disable-next-line no-unused-vars
  nextStep: (step: number) => void;
}

const PatientNotFound: React.FC<PatientNotFoundProps> = ({
  stateInfo,
  nextStep,
}) => {
  const dispatch = useDispatch();

  return (
    <div className='step-content step-final'>
      <div className='step-header'>
        <img
          src={addPatientWarning}
          alt='add patient warning'
          className='image-status'
        />
        <div className='title-text'>{strings.apNotFoundTitle}</div>
      </div>
      <div className='step-body'>
        <div className='text-information'>
          <p>
            {strings.formatString(
              strings.apNotFoundText1,
              <b>{stateInfo.cpr}</b>,
              <b>{stateInfo.letters}</b>
            )}
            <br />
            {strings.apNotFoundText2}
          </p>
          <p>
            {strings.apNotFoundText3}
            <br />
            <Button
              color='link'
              className='btn-app-link'
              onClick={() => {
                dispatch(patientNotFounded(false));
                nextStep(4);
              }}
            >
              {strings.apNotFoundLink}
            </Button>
          </p>
        </div>
        <div className='button-actions'>
          <Button
            color='blue'
            onClick={() => {
              dispatch(patientNotFounded(false));
              nextStep(1);
            }}
          >
            {strings.buttonTextTryAgain}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PatientNotFound;
