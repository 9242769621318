import React, { useState, useEffect } from 'react';
import { Button, CardBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { strings } from '../../constants/localization';
import { Sundhedplus } from '../../config';
import LoadingSpinner from './common/LoadingSpinner/LoadingSpinner';
import {
  getDanishTextForOnboardingStepTitle,
  getOnboardingErrorReasonString,
  getErrorEndpointName,
} from '../../constants/utils';
import { creditApplicationDenied } from '../../actions/onboardingActions';
import { ReactComponent as InformationIcon } from '../../resources/icons/info-circle.svg';
import { onboardingDenialReasons } from '../../constants/mappings';
import moment from 'moment';

const DeniedCreditOffer = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const data = useSelector((state) => state.onboarding?.data);
  const visitedSteps = useSelector((state) => state.onboarding?.visitedSteps);
  const serverErrorDetails = useSelector(
    (state) => state.ui?.serverErrorDetails
  );
  const creditApplicationAbortedApplication = useSelector(
    (state) => state.onboarding?.creditApplicationAbortedApplication
  );
  const creditApplicationDeniedApplication = useSelector(
    (state) => state.onboarding?.creditApplicationDeniedApplication
  );
  const [lastStepStored, setLastStepStored] = useState(null);
  const [denyReason, setDenyReason] = useState('');
  const { creditApplicationUuid, uuid } = data || {};
  const [denialMessages, setDenialMessages] = useState([]);
  const [denialLink, setDenialLink] = useState([]);

  useEffect(() => {
    document.title = `Sundhed+ | ${strings.onboardingDeniedCreditTitle}`;
    if (!creditApplicationDeniedApplication) {
      window.dataLayer = window?.dataLayer || [];
      window.dataLayer.push({
        customPageUrl: '/kreditvurdering-afvist',
        stepTitle: 'kreditvurdering - afvist',
        event: 'onboarding',
      });
    }
  }, [creditApplicationDeniedApplication]);

  useEffect(() => {
    let errorReason = '';
    const { message, cause } = serverErrorDetails?.data || {};
    const simpleStringError = getOnboardingErrorReasonString(cause ?? message);
    // Check if the message contains the specific text and extract the date
    const nextApplicationDateMatch = simpleStringError?.match(
      /Next allowed application date is in the future, (\d{4}-\d{2}-\d{2})/
    );
    const statusText = getStatusTextIfMatch(simpleStringError);
    const nextAllowedApplicationDate = nextApplicationDateMatch
      ? nextApplicationDateMatch[1]
      : '';
    const formattedNextAllowedApplicationDate = moment(
      nextAllowedApplicationDate,
      'YYYY-MM-DD',
      true
    ).isValid()
      ? moment(nextAllowedApplicationDate).format('DD-MM-YYYY')
      : 'Invalid date';

    const denialMessages =
      statusText && statusText.length > 0
        ? statusText.map((textKey) =>
            strings.formatString(
              strings[textKey],
              formattedNextAllowedApplicationDate
            )
          )
        : [strings.onboardingDeniedGenericMessage];

    if (nextApplicationDateMatch) {
      errorReason = 'Next allowed application date is in the future';
    } else {
      errorReason =
        simpleStringError && statusText ? simpleStringError : 'Generic';
    }

    setDenialMessages(denialMessages);
    setDenialLink(onboardingDenialReasons[errorReason]?.link);
    setDenyReason(errorReason);

    if (visitedSteps && visitedSteps?.length > 0) {
      const lastStep = visitedSteps[visitedSteps.length - 1];
      setLastStepStored(
        lastStep === 0 && visitedSteps.length > 1
          ? visitedSteps[visitedSteps.length - 2]
          : lastStep
      );
    }
  }, [serverErrorDetails, visitedSteps]);

  useEffect(() => {
    let finalReason = '';
    if (denyReason) {
      if (denyReason !== 'Generic') {
        finalReason = denyReason;
      } else {
        const stepTitle = getDanishTextForOnboardingStepTitle(lastStepStored);
        const endpointName = getErrorEndpointName(serverErrorDetails);
        finalReason = `Denied at step ${lastStepStored} - ${stepTitle}${
          endpointName ? ' - ' + endpointName : ''
        }`;
      }

      if (
        creditApplicationUuid &&
        uuid &&
        finalReason &&
        !creditApplicationDeniedApplication &&
        !creditApplicationAbortedApplication
      ) {
        setLoading(true);
        dispatch(
          creditApplicationDenied(creditApplicationUuid, uuid, finalReason)
        ).finally(() => {
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    }
  }, [denyReason]);

  const getStatusTextIfMatch = (deniedReasonString) => {
    for (let key in onboardingDenialReasons) {
      if (deniedReasonString?.includes(key)) {
        return onboardingDenialReasons[key].statusText;
      }
    }
    return null;
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner
          title={strings.onboardingSpinnerTitle}
          hasBackBtn={false}
        />
      ) : (
        <CardBody className='step-content'>
          <div className='step-title'>
            {strings.onboardingDeniedCreditTitle}
          </div>
          <div className='step-subtitle center bold pl-5 pr-5 pb-3'>
            {strings.onboardingDeniedCreditSubtitle}
          </div>
          <section className='warning-error-box'>
            <div className='head'>
              <div className='icon'>
                <InformationIcon className='infoIcon' />
              </div>

              {strings.onboardingDeniedCreditWarningTitle}
            </div>
            <div className='body'>
              {denialMessages.map((message, index) => (
                // if it is the last message, we remove the margin bottom from the message
                <div
                  className={`message ${
                    index === denialMessages.length - 1 ? 'mb-0' : ''
                  }`}
                  key={index}
                >
                  {message}
                  {denialLink && index === denialMessages.length - 1 && (
                    <a className='color' href={denialLink}>
                      {strings.onboardingDeniedForPaymentRemarksP3}
                    </a>
                  )}
                </div>
              ))}
            </div>
          </section>

          <div className='actions-buttons step-buttons credit-denied-actions'>
            <Button
              className='btn btn-blue btn-success full-width'
              tag='a'
              href={Sundhedplus.presentationSite}
            >
              {strings.onboardingActionButtonToSundhed}
            </Button>
          </div>
          <footer className='step-footer'>
            <p className='m-0 p-0'>{strings.onboardingDeniedCreditHelp}</p>

            {strings.formatString(
              strings.onboardingDeniedCreditHelpPhone,
              <a
                className='color'
                href={`tel:${Sundhedplus.contactPhone}`}
                style={{ whiteSpace: 'nowrap' }}
              >
                {Sundhedplus.contactPhoneHtmlFormated}
              </a>
            )}
            <p className='m-0 p-0'>{strings.onboardingDeniedCreditHelpTime}</p>
          </footer>
        </CardBody>
      )}
    </>
  );
};

export default DeniedCreditOffer;
