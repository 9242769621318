import React, { useEffect, useRef, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Row, Col } from 'reactstrap';
import {
  trackVerticalStyle,
  thumbVerticalStyle,
} from '../../constants/styleScrollbar';
import MemberProfileInformation from './mitMemberProfile/MemberProfileInformation';
import { strings } from '../../constants/localization';

interface mitMemberProfileProps {
  toggleMenu: () => void;
}

const MitMemberProfile: React.FC<mitMemberProfileProps> = ({ toggleMenu }) => {
  const [smallScreen, setSmallScreen] = useState<boolean>(false);
  const scrollRef = useRef<Scrollbars>(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 680) {
        setSmallScreen(true);
      } else {
        setSmallScreen(false);
      }
    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <Row className='app-row-height mitDashboard'>
      <Col className='p-0 app-col-height'>
        <Scrollbars
          ref={scrollRef}
          style={{
            height: window.innerHeight + 'px',
          }}
          //onScroll={handleScroll}
          // eslint-disable-next-line
          renderTrackVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                ...trackVerticalStyle,
              }}
            />
          )}
          // eslint-disable-next-line
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                ...thumbVerticalStyle,
              }}
            />
          )}
        >
          <div className='mitSundhed-section'>
            <div className='member-portal-profile'>
              <div className='entity-information-card'>
                <div className='header-information'>
                  <div className='header-text'>
                    {smallScreen ? (
                      <div className='card-title center'>
                        {strings.mitMemberProfileTitle}
                      </div>
                    ) : (
                      <div className='card-title'>
                        {strings.mitMemberProfileTitle}
                      </div>
                    )}
                  </div>

                  <button
                    type='button'
                    className='btn btn-white menu-toggler'
                    onClick={toggleMenu}
                  >
                    <div className='menu-toggler-icon'>
                      <span />
                      <span />
                      <span />
                    </div>
                  </button>
                </div>
                <MemberProfileInformation />
              </div>
            </div>
          </div>
        </Scrollbars>
      </Col>
    </Row>
  );
};

export default MitMemberProfile;
