import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { connect } from 'react-redux';
import { strings } from '../../../constants/localization';
import { moment, SERVER_URL } from '../../../constants';
import { cardStatusMap } from '../../../constants/mappings';
import KebabMenu from '../../common/KebabMenu/KebabMenu';
import { config } from '../../../config';
import {
  formatCreditCardWithMaskNumberAlreadyMasked,
  isAdmin,
} from '../../../constants/utils';
import { useSelector } from 'react-redux';
import DentiModal from '../../common/DentiModal';
import RetryWithdrawalModal from './RetryWithdrawalModal';

interface PaymentStatusProps {
  user: {
    admins: object[];
  };
  status: {
    leadStatusActive: boolean;
  };
  lead: {
    activeLead: object;
    searchActive: boolean;
    activePage: number;
    creditFacilityInformation: {
      installmentLoanInformations: {
        status: string;
        brand: string;
        number: string;
        monthlyPaymentAmount: number;
      }[];
      cardExpiryDate: string;
      maskedCard: string;
      subscriptionId: string | null;
      creditCardState: string | null;
      lastPaidInstallmentDate: string | null;
      number: string | null;
    };
    leadClientInformation: {
      clientDunningStatus: string;
    };
  };
}

const PaymentStatus: React.FC<PaymentStatusProps> = ({ lead }) => {
  const { creditFacilityInformation, leadClientInformation } = lead;
  const [lastLidLoan, setLastLidLoan] = useState<string>('NO');
  const [loanInvoiceNumber, setLoanInvoiceNumber] = useState<string | null>(
    null
  );
  const [retryWithdrawalModal, setRetryWithdrawalModal] =
    useState<boolean>(false);
  const { cardExpiryDate, maskedCard, lastPaidInstallmentDate } =
    creditFacilityInformation;
  const { clientDunningStatus } = leadClientInformation;
  const currentUser = useSelector(
    (state: { user: { user: unknown } }) => state.user.user
  );
  const isUserAdmin = isAdmin(currentUser);

  useEffect(() => {
    let found = false;
    creditFacilityInformation.installmentLoanInformations
      .slice()
      .reverse()
      .forEach((item) => {
        if (
          item.status !== 'WAITING_FOR_SIGNATURE' &&
          item.status !== 'DELETED' &&
          item.status !== 'NOT_ACCEPTED' &&
          !found
        ) {
          setLastLidLoan(item.brand);
          found = true;
          return;
        }
      });

    creditFacilityInformation.installmentLoanInformations.forEach((item) => {
      if (item.status === 'ACTIVE' || item.status === 'ACCEPTED') {
        setLoanInvoiceNumber(item.number);
      }
    });

    if (!found) {
      setLastLidLoan('NO');
    }
  }, [creditFacilityInformation.installmentLoanInformations]);

  const openLastLeadLoan = () => {
    window.open(
      `${config.billwerk.replace(
        '$0',
        creditFacilityInformation.subscriptionId || ''
      )}`,
      '_blank'
    );
  };

  const updateCreditCardButton = () => {
    if (loanInvoiceNumber) {
      window.open(
        `${SERVER_URL}/public/installmentloan/changeCreditInformation?installmentLoanNumber=${loanInvoiceNumber}`,
        '_blank'
      );
    }
  };

  const retryWithdrawal = () => {
    setRetryWithdrawalModal(true);
  };

  return (
    <>
      <Card id='PaymentStatusComponent' className='custom-card payment-status'>
        <CardBody>
          <div className='header-information'>

            <CardTitle className='header-text'>
              <div className='icon-area'>
                <i className='lnil lnil-credit-card icon ' />
                <span className='title'>{strings.creditCard}</span>
              </div>
            </CardTitle>

            {(lastLidLoan !== 'NO' &&
              creditFacilityInformation.subscriptionId) ||
              loanInvoiceNumber ||
              clientDunningStatus ? (
              <KebabMenu menuPlacement={undefined} scrollToBottom={undefined}>
                {lastLidLoan !== 'NO' &&
                  creditFacilityInformation.subscriptionId && (
                    <div
                      role='button'
                      tabIndex={0}
                      className='KebabMenu-item'
                      onClick={openLastLeadLoan}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          openLastLeadLoan();
                        }
                      }}
                    >
                      <span className='icon'>
                        <i className='lnir lnir-reply' />
                      </span>
                      <span className='text'>{strings.billwerkPlus}</span>
                    </div>
                  )}
                {loanInvoiceNumber && (
                  <div
                    role='button'
                    tabIndex={0}
                    className='KebabMenu-item'
                    onClick={updateCreditCardButton}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        updateCreditCardButton();
                      }
                    }}
                  >
                    <span className='icon'>
                      <i className='lnir lnir-circle-plus' />
                    </span>
                    <span className='text'>{strings.updateCreditCardOption}</span>
                  </div>
                )}
                {isUserAdmin && clientDunningStatus && (
                  <div
                    role='button'
                    tabIndex={0}
                    className='KebabMenu-item'
                    onClick={retryWithdrawal}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        retryWithdrawal();
                      }
                    }}
                  >
                    <span className='icon'>
                      <i className='lnir lnir-coin' />
                    </span>
                    <span className='text'>{strings.retryWithdrawal}</span>
                  </div>
                )}
              </KebabMenu>
            ) : null}

          </div>
          <div className='status-information'>
            <div className='line'>
              <div className='title-line'>{strings.latestPayment}</div>
              <div>
                {lastPaidInstallmentDate === null ||
                  lastPaidInstallmentDate === undefined ? (
                  <>{strings.never}</>
                ) : (
                  <>
                    {moment(
                      creditFacilityInformation.lastPaidInstallmentDate
                    ).format('DD. MMMM YYYY')}
                  </>
                )}
              </div>
            </div>
            <div className='line'>
              <div className='title-line'>{strings.cardNumber}</div>
              {maskedCard
                ? formatCreditCardWithMaskNumberAlreadyMasked(maskedCard)
                : '-'}
            </div>
            <div className='line'>
              <div className='title-line'>
                {strings.registerInvoiceFlowCreditExpired}
              </div>
              <div>
                {cardExpiryDate
                  ? moment(
                    `20${cardExpiryDate.split('/')[1]}-${cardExpiryDate.split('/')[0]
                    }-01`
                  ).format('MMMM YYYY')
                  : ' - '}
              </div>
            </div>
            <div className='line'>
              <div className='title-line'>{strings.cardStatus}</div>
              {creditFacilityInformation.creditCardState === null ? (
                <div className='status-yellow'>{strings.noCard}</div>
              ) : (
                <div
                  className={
                    cardStatusMap[creditFacilityInformation.creditCardState]
                      .className
                  }
                >
                  {
                    strings[
                    cardStatusMap[creditFacilityInformation.creditCardState]
                      .statusText
                    ]
                  }
                </div>
              )}
            </div>
          </div>
        </CardBody>
      </Card>

      {retryWithdrawalModal && (
        <DentiModal
          close={() => setRetryWithdrawalModal(false)}
          modalClass='modal-dialog-centered retry-withdrawal-modal'
        >
          <RetryWithdrawalModal
            onClose={() => setRetryWithdrawalModal(false)}
            creditFacilityInformation={creditFacilityInformation}
          />
        </DentiModal>
      )}
    </>
  );
};

function mapStateToProps(state: PaymentStatusProps) {
  return {
    activePage: state.lead.activePage,
    searchActive: state.lead.searchActive,
    statusActive: state.status.leadStatusActive,
    allAdmins: state.user.admins,
    activeLead: state.lead.activeLead,
  };
}

export default connect(mapStateToProps, {})(PaymentStatus);
