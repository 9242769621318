import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  toggleServerModalError,
  setServerModalError,
} from '../../actions/uiElementsActions';
import DentiModal from './DentiModal';
import { strings } from '../../constants/localization';
import { brandConfig } from '../../config';
import brokenRobot from '../../resources/images/broken-robot.png';
import { moment } from '../../constants';

const ServerModalError = () => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(true);
  const [activePanel, setActivePanel] = useState(false);
  const [showServerRebootingModal, setShowServerRebootingModal] =
    useState(false);
  const serverErrorDetails = useSelector(
    (state) => state.ui.serverErrorDetails
  );
  const { message, cause } = serverErrorDetails?.data || {};
  //f we receive 500 with "EntityFactoryManager is closed" or 502, then show this rebooting modal
  const serverIsDown =
    typeof serverErrorDetails !== 'object' &&
    (serverErrorDetails === 'Network Error' ||
      serverErrorDetails !== 'canceled');

  const phoneNumber =
    strings.getLanguage() === 'en'
      ? `+45 ${brandConfig.contactPhoneHtmlFormated}`
      : brandConfig.contactPhoneHtmlFormated;

  useEffect(() => {
    if (
      serverIsDown ||
      (serverErrorDetails?.status === 500 &&
        (message?.includes('EntityManagerFactory is closed') ||
          cause?.includes('EntityManagerFactory is closed')))
    ) {
      setShowServerRebootingModal(true);
    }
  }, [serverErrorDetails]);

  const toggleErrorModal = () => {
    setModal(!modal);
    dispatch(toggleServerModalError(false));
  };

  const toggleServerRebootingModal = () => {
    setShowServerRebootingModal(false);
    setModal(false);

    dispatch(toggleServerModalError(false));
    dispatch(setServerModalError(null));
  };

  const togglePanel = () => {
    setActivePanel(!activePanel);
  };

  if (showServerRebootingModal) {
    return (
      <DentiModal
        close={() => toggleServerRebootingModal()}
        modalClass='notification-modal'
        modalClassName='confirm-action'
        closeBtn={false}
      >
        <div>
          <div className='icon'>
            <i className='lnil lnil-reload' />
          </div>
          <div>
            <h3 className='headline'>{strings.serverRebootingHeadline}</h3>
            <p className='notification-message'>
              {strings.serverRebootingText}
            </p>
          </div>
          <div className='bottom-section action-btn cf'>
            <button
              className='submit-btn btn btn-blue center-block'
              type='button'
              onClick={() => toggleServerRebootingModal()}
            >
              <span>{strings.serverRebootingButton}</span>
            </button>
          </div>
        </div>
      </DentiModal>
    );
  } else if (modal) {
    return (
      <DentiModal
        close={toggleErrorModal}
        modalClassName='server-error'
        modalClass='server-error-modal modal-dialog-centered'
      >
        <Card>
          <CardBody>
            <div className='something-went-wrong'>
              <h1 className='title'>
                {serverIsDown
                  ? strings.errorServerDownTitle
                  : strings.errorServerTitle}
              </h1>
              <div className='error-info'>
                <p>
                  {serverIsDown
                    ? strings.errorServerDownText1
                    : strings.errorServerText1}
                </p>
                <p>
                  {serverIsDown
                    ? strings.errorServerDownText2
                    : strings.errorServerText2}
                </p>
              </div>
              <div className='error-image-container'>
                <img
                  src={brokenRobot}
                  alt='broken-robot'
                  className='image-broken-robot'
                />
              </div>
              <div className='visit-action-buttons'>
                <Button color='blue' tag='a' href={`tel:${phoneNumber}`}>
                  {strings.formatString(
                    strings.declineCallText,
                    '',
                    phoneNumber
                  )}
                </Button>
                <Button
                  color='blue'
                  tag='a'
                  href={`mailto:${brandConfig.contactEmail}`}
                >
                  {strings.declineEmailText}
                </Button>

                {/* More tehnical information section */}
                {!serverIsDown && (
                  <>
                    <div className='accordion-header' onClick={togglePanel}>
                      {strings.showMoreTehnicalInformation}
                      <i className='lnil lnil-chevron-down'></i>
                    </div>
                    <div className={`panel ${activePanel ? 'active' : ''} `}>
                      <div>
                        <b>{strings.httpText} </b>
                        {serverErrorDetails && serverErrorDetails?.status
                          ? serverErrorDetails.status
                          : ''}
                      </div>

                      <div>
                        <b>{strings.httpMessage} </b>
                        {serverErrorDetails?.data.cause
                          ? serverErrorDetails?.data.cause
                          : ''}
                        {serverErrorDetails?.data.message &&
                        !serverErrorDetails?.data.cause
                          ? serverErrorDetails?.data.message
                          : ''}
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className='timestamp'>
                {moment().format('DD-MM-YYYY  HH:mm:ss')}
              </div>
            </div>
          </CardBody>
        </Card>
      </DentiModal>
    );
  } else {
    return null;
  }
};

ServerModalError.propTypes = {
  toggleServerModalError: PropTypes.func,
};

export default ServerModalError;
