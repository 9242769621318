import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { success } from 'react-notification-system-redux';
import {
  isManager,
  isDentist,
  addNotification,
} from '../../../constants/utils';
import { strings } from '../../../constants/localization';
import ManagerCompaniesList from './ManagerCompaniesList';
import CompanyDetails from './CompanyDetails';
import {
  getDentistCompany,
  getCompaniesListForManagers,
} from '../../../actions/companyActions';
import { clearClinicWebsocketMessage } from '../../../actions/websocketActions';
import LoadingMessage from '../../statusMessages/LoadingMessage';
import WelcomePage from '../../common/WelcomePage';

const CompaniesSettings = ({
  displayComponent,
  toggleModal,
  modalShow,
  toggleCreateNewInvoice,
  createNewInvoice,
}) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user?.user);
  const [activeCompany, setActiveCompany] = useState(null);
  const [loadingDentistCompanyData, setLoadingDentistCompanyData] =
    useState(false);
  const companies = useSelector(
    (state) => state.company?.managersCompaniesListAll
  );
  const activeManagerCompany = useSelector(
    (state) => state.company?.activeManagerCompany
  );

  //get the ws messages for company updates
  const wsMessages = useSelector(
    (state) => state.websocketMessages.clinic,
    shallowEqual
  );

  // GET THE COMPANY DATA DEPENDING ON THE USER TYPE
  useEffect(() => {
    if (isManager(currentUser)) {
      setActiveCompany(activeManagerCompany);
    } else if (isDentist(currentUser)) {
      setLoadingDentistCompanyData(true);
      dispatch(getDentistCompany(currentUser?.activeCompany))
        .then((result) => {
          if (result?.payload?.data) {
            setActiveCompany(result?.payload?.data);
            setLoadingDentistCompanyData(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [currentUser, activeManagerCompany, dispatch]);

  const updateCompanyDataAndShowNotification = useCallback(
    (companyReferenceNumber, companyName) => {
      dispatch(getDentistCompany(companyReferenceNumber))
        .then((result) => {
          if (result?.payload?.status === 200) {
            setActiveCompany(result?.payload?.data);
            dispatch(
              success(
                addNotification(
                  strings.formatString(strings.editCompanySuccess, companyName),
                  strings.editCompanySuccessTitle,
                  0
                )
              )
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [dispatch]
  );

  // process updates on the companies list from websocket
  const processWsMessages = useCallback(() => {
    if (wsMessages.length > 0) {
      wsMessages.forEach((message) => {
        const {
          websocketMessageType,
          companyChainId,
          companyName,
          companyReferenceNumber,
          triggeringUser,
        } = message || {};
        // CHECK WS NOTIFICATION FOR MANAGER
        if (isManager(currentUser)) {
          if (
            (websocketMessageType === 'COMPANY_UPDATED' ||
              websocketMessageType === 'COMPANY_CREATE' ||
              websocketMessageType === 'IMPORT_COMPANY_CREATE') &&
            companyChainId === currentUser?.companyChainId &&
            triggeringUser !== currentUser?.username
          ) {
            // if is the view list, then update the list
            if (!activeManagerCompany) {
              dispatch(getCompaniesListForManagers())
                .then(() => {
                  //setWsMessage(message);
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            // if is the company view, then update and the ws message is for the active manager company
            else if (
              activeManagerCompany &&
              activeManagerCompany?.referenceNumber === companyReferenceNumber
            ) {
              updateCompanyDataAndShowNotification(
                activeManagerCompany?.referenceNumber,
                companyName
              );
              //dispatch(setCurrentManagerCompany(activeCompanyUpdated));
            }
          }
        }
        // CHECK WS NOTIFICATION FOR DENTIST
        else if (isDentist(currentUser)) {
          if (
            websocketMessageType === 'COMPANY_UPDATED' &&
            companyReferenceNumber === currentUser?.activeCompany &&
            triggeringUser !== currentUser?.username
          ) {
            setTimeout(() => {
              updateCompanyDataAndShowNotification(
                currentUser?.activeCompany,
                companyName
              );
            }, 1000);
          }
        }
        // clear the ws message
        dispatch(clearClinicWebsocketMessage());
      });
    }
  }, [
    wsMessages,
    activeManagerCompany,
    currentUser,
    updateCompanyDataAndShowNotification,
    dispatch,
  ]);

  useEffect(() => {
    // react to ws messages new entries
    processWsMessages();
  }, [processWsMessages]);

  return (
    <div>
      {isManager(currentUser) && (
        <>
          {displayComponent === 'companies' && companies?.length > 0 ? (
            <div className='manager-companies-list'>
              <ManagerCompaniesList companies={companies} />
            </div>
          ) : (
            <WelcomePage
              toggleModal={toggleModal}
              modalShow={modalShow}
              toggleCreateNewInvoice={toggleCreateNewInvoice}
              createNewInvoice={createNewInvoice}
            />
          )}
          <div
            className={`manager-companies-list-item ${activeManagerCompany !== undefined ? 'active' : ''
              }`}
          >
            <CompanyDetails company={activeCompany} />
          </div>
        </>
      )}

      {loadingDentistCompanyData && <LoadingMessage />}

      {isDentist(currentUser) && !loadingDentistCompanyData && (
        <div className='manager-companies-list-item active'>
          <CompanyDetails company={activeCompany} />
        </div>
      )}
    </div>
  );
};

export default CompaniesSettings;
