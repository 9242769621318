import {
  formatAmount,
  formatCurrency,
  formatCurrencyDaStandard,
  removeCurrencyFormattingForOnFocus,
} from '../../../constants/utils';
import { strings } from '../../../constants/localization';
import React, { useEffect, useState } from 'react';
import { Button, Label } from 'reactstrap';

type AdditionalPaymentProps = {
  onNextStep: () => void;
  setAdditionalPayment: (value: string) => void;
  remainingBalance: number;
  hasCancelButton?: boolean;
  hasRedirect?: boolean;
  closeModal?: () => void;
  additionalPayment?: string;
};

const AdditionalPayment: React.FC<AdditionalPaymentProps> = ({
  onNextStep,
  setAdditionalPayment,
  remainingBalance,
  hasCancelButton,
  hasRedirect,
  closeModal,
  additionalPayment,
}) => {
  const [amountToPay, setAmountToPay] = useState<string>('');
  const [rawAmountToPay, setRawAmountToPay] = useState<string>('');
  const [hasError, setHasError] = useState<string>('');

  const handleNextStep = () => {
    let errorMessage = '';

    const valueWithoutPoint = rawAmountToPay;
    const amountParsed = parseFloat(valueWithoutPoint);

    if (!valueWithoutPoint) {
      errorMessage = strings.partialRedemptionPaymentErrorMsg1;
    } else if (amountParsed > remainingBalance) {
      errorMessage = strings.partialRedemptionPaymentErrorMsg2;
    } else if (amountParsed < 100) {
      errorMessage = strings.partialRedemptionPaymentErrorMsg3;
    } else if (remainingDebtAfterAmountPaid < 100) {
      errorMessage = strings.partialRedemptionPaymentErrorMsg4;
    }

    if (errorMessage) {
      setHasError(errorMessage);
      return;
    }
    setAdditionalPayment(valueWithoutPoint);
    onNextStep();
  };

  const remainingDebtAfterAmountPaid =
    remainingBalance - (parseFloat(rawAmountToPay) || 0);

  const changeValue = (value: string) => {
    setHasError('');
    const valueWithoutSufix = value.replace('kr.', '').replaceAll('.', '');
    setAmountToPay(valueWithoutSufix);
    const replaceCommaWithDot = valueWithoutSufix.replace(',', '.');
    setRawAmountToPay(replaceCommaWithDot);
  };

  useEffect(() => {
    if (additionalPayment) {
      setAmountToPay(formatCurrency(additionalPayment));
      setRawAmountToPay(additionalPayment);
    }
  }, [additionalPayment]);

  // 'Enter' key event listener
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleNextStep();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [rawAmountToPay]);

  const handleCancelButton = () => {
    if (hasRedirect) {
      window.close();
    } else {
      closeModal && closeModal();
      window.location.reload();
    }
  };

  return (
    <>
      <div className='step-content'>
        <h1 className='step-title'>
          {strings.partialRedemptionAdditionalPaymentTitle}
        </h1>
        <div className='subtitle'>
          <p>{strings.partialRedemptionAdditionalPaymentSubtitle}</p>
        </div>

        <div className='mb-5'>
          <Label className='input-label'>
            {strings.partialRedemptionAdditionalPaymentInputLabel}
          </Label>
          <input
            type='text'
            placeholder='0,00 kr.'
            className={`input-field ${hasError ? 'input_danger mb-1' : ''}`}
            onChange={(e) => changeValue(e.target.value)}
            value={formatAmount(amountToPay)}
            onFocus={(e) => removeCurrencyFormattingForOnFocus(e, setAmountToPay)}
            onBlur={(e) => {
              if (e.target.value !== '') {
                const formattedValue = formatCurrency(e.target.value);
                setAmountToPay(formattedValue);
              }
            }}
          />
          {hasError && <div className='text-danger'>{hasError}</div>}
        </div>

        <div className='payment-info'>
          <Label>
            {strings.partialRedemptionAdditionalPaymenRemainingDebt}
          </Label>
          <span>{formatCurrencyDaStandard(remainingBalance)}</span>
        </div>
        <div className='payment-info'>
          <Label>
            {strings.partialRedemptionAdditionalPaymenRemainingDebtAfterPayment}
          </Label>
          <span>
            {remainingDebtAfterAmountPaid > 0
              ? formatCurrencyDaStandard(remainingDebtAfterAmountPaid)
              : formatCurrencyDaStandard(remainingBalance)}
          </span>
        </div>

        <div className='step-actions'>
          {hasCancelButton && (
            <Button
              color='light'
              className='reject'
              onClick={handleCancelButton}
            >
              {strings.partialRedemptionButtonCancel}
            </Button>
          )}
          <Button
            color='blue'
            className='continue'
            onClick={handleNextStep}
            disabled={amountToPay === '' || amountToPay === '0,00 kr.'}
          >
            {strings.partialRedemptionButtonNext}
          </Button>
        </div>
      </div>

    </>
  );
};

export default AdditionalPayment;
