import { requestAxios as axios } from '../constants/utils';
import { getLoggedInUserCredentials } from './userActions';

import {
  SET_CURRENT_DOCUMENTATION_ITEM,
  ACCEPT_DOCUMENTATION_ITEM,
  DENY_DOCUMENTATION_ITEM,
  UPDATE_DOCUMENTATION_INTERNAL_NOTE,
  GET_ALL_DOCUMENTS,
  GET_COUNTER_PENDING_DOCUMENTS,
  GET_DOCUMENT_DETAILS,
  SERVER_URL,
  UPDATE_DOCUMENTATION_FILTER_PARAMS,
  DOCUMENTATION_SUCCESS,
  DOCUMENTATION_EMPTY,
  DOCUMENTATION_FAILURE,
  DOCUMENTATION_LOADING,
  SAVE_FINAL_UPDATED_BUDGET,
  UPDATE_DOCUMENTATION_INFORMATION,
  SET_DOCUMENTATION_ITEM_LOADING,
  SET_DOCUMENTATION_ACTIVE_PAGE,
} from '../constants';

export function setCurrentDocumentationItem(documentItem = undefined) {
  localStorage.setItem(
    'lastSelectedDocumentation',
    JSON.stringify(documentItem)
  );
  return {
    type: SET_CURRENT_DOCUMENTATION_ITEM,
    payload: documentItem,
  };
}

export function setDocumentationItemLoading(loadingStatus) {
  return {
    type: SET_DOCUMENTATION_ITEM_LOADING,
    payload: loadingStatus,
  };
}

export function setActivePage(activePage) {
  return {
    type: SET_DOCUMENTATION_ACTIVE_PAGE,
    payload: activePage,
  };
}

export function updateDocumentationList(newDocumentInformation) {
  return {
    type: UPDATE_DOCUMENTATION_INFORMATION,
    payload: newDocumentInformation,
  };
}

export function getAllDocuments(status, type, searchInput, page) {
  let finalSearchInput = searchInput?.replace(/\s/g, '');
  // check if searchInput is a not empty string
  if (typeof searchInput === 'string' && finalSearchInput !== '') {
    // check if searchInput is a cpr, to clear the '-' if it needs
    if (/^\d{6}(-)?\d{4}$/.test(finalSearchInput)) {
      finalSearchInput = finalSearchInput?.split('-')?.join('');
    }
  }

  const params = {
    status,
    type,
    searchInput: finalSearchInput || null,
    page: page > 0 ? page : 0,
  };

  return async (dispatch) => {
    dispatch({
      type: DOCUMENTATION_LOADING,
      payload: DOCUMENTATION_LOADING,
    });

    dispatch(setActivePage(params.page + 1 || 1));

    try {
      const response = await axios({
        method: 'get',
        url: `${SERVER_URL}/api/v1/creditApplication/getAllDocuments`,
        auth: getLoggedInUserCredentials(),
        withCredentials: true,
        params: arguments.length > 0 ? params : {},
      });

      const documentationList = response?.data;
      dispatch({
        type: GET_ALL_DOCUMENTS,
        payload: documentationList,
      });

      if (documentationList?.content.length > 0) {
        dispatch({
          type: DOCUMENTATION_SUCCESS,
          payload: DOCUMENTATION_SUCCESS,
        });
      } else {
        dispatch({
          type: DOCUMENTATION_EMPTY,
          payload: DOCUMENTATION_EMPTY,
        });
      }

      if (documentationList && documentationList?.content.length === 1) {
        dispatch(setCurrentDocumentationItem(documentationList?.content[0]));
      }

      dispatch({
        type: UPDATE_DOCUMENTATION_FILTER_PARAMS,
        payload: {
          status: params?.status || null,
          type: params?.type || null,
          searchInput: params?.searchInput || null,
        },
      });
    } catch (error) {
      console.log('error', error);
      dispatch({
        type: DOCUMENTATION_FAILURE,
        payload: error,
      });
    }
  };
}

export function getCounterPendingDocuments() {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: 'get',
        url: `${SERVER_URL}/api/v1/creditApplication/getAllDocuments?status=PENDING`,
        auth: getLoggedInUserCredentials(),
        withCredentials: true,
      });

      const documentationList = response?.data;
      dispatch({
        type: GET_COUNTER_PENDING_DOCUMENTS,
        payload: documentationList?.totalElements,
      });
    } catch (error) {
      console.log('error', error);
    }
  };
}

export function getDocumentDetails(cpr, documentId) {
  return (dispatch) => {
    const response = axios({
      method: 'get',
      url: `${SERVER_URL}/api/v1/creditApplication/${cpr}/documents/${documentId}`,
      auth: getLoggedInUserCredentials(),
      withCredentials: true,
    });

    dispatch({
      type: GET_DOCUMENT_DETAILS,
      payload: response,
    });

    return response;
  };
}

export function updateDocumentationStatus(
  documentId,
  cpr,
  status,
  type,
  supportNote,
  isSupportNote
) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      // Return a Promise explicitly
      axios({
        method: 'put',
        url: `${SERVER_URL}/api/v1/creditApplication/updateDocumentInfo`,
        withCredentials: true,
        auth: getLoggedInUserCredentials(),
        data: {
          cpr,
          creditApplicationDocumentId: documentId,
          status,
          supportNote: isSupportNote ? supportNote : undefined,
          type,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type:
                status === 'APPROVED'
                  ? ACCEPT_DOCUMENTATION_ITEM
                  : DENY_DOCUMENTATION_ITEM,
              payload: response,
            });

            const { activeDocListItem } = getState().onboardingDocumentation;
            let newActiveDocListItem = { ...activeDocListItem };
            newActiveDocListItem.status = status;

            // Update support note if isSupportNote is true
            if (isSupportNote) {
              newActiveDocListItem.supportNote = supportNote;
            }

            dispatch(updateDocumentationList(newActiveDocListItem));
            dispatch(setCurrentDocumentationItem(newActiveDocListItem));
            resolve(response);
          } else {
            throw new Error('Unexpected response status');
          }
        })
        .catch((error) => {
          dispatch({
            type:
              status === 'APPROVED'
                ? ACCEPT_DOCUMENTATION_ITEM
                : DENY_DOCUMENTATION_ITEM,
            payload: error,
          });
          reject(error);
        });
    });
  };
}

export function acceptDocumentation(documentId, cpr, type) {
  return (dispatch) =>
    dispatch(updateDocumentationStatus(documentId, cpr, 'APPROVED', type));
}

export function denyDocumentation(
  documentId,
  cpr,
  type,
  supportNote,
  isSupportNote
) {
  return (dispatch) =>
    dispatch(
      updateDocumentationStatus(
        documentId,
        cpr,
        'REJECTED',
        type,
        supportNote,
        isSupportNote
      )
    );
}

export function updateDocumentationInternalNote(documentId, cpr, newNote) {
  return (dispatch, getState) =>
    axios({
      method: 'put',
      url: `${SERVER_URL}/api/v1/creditApplication/updateDocumentInfo`,
      withCredentials: true,
      auth: getLoggedInUserCredentials(),
      data: {
        cpr,
        creditApplicationDocumentId: documentId,
        internalNote: newNote,
      },
    })
      .then(() => {
        dispatch({
          type: UPDATE_DOCUMENTATION_INTERNAL_NOTE,
          payload: newNote,
        });
        dispatch(
          updateDocumentationList({
            ...getState().onboardingDocumentation.activeDocListItem,
            internalNote: newNote,
          })
        );
      })
      .catch((error) => ({
        type: UPDATE_DOCUMENTATION_INTERNAL_NOTE,
        payload: error,
      }));
}

export function updateDocumentationFilterParams(filterParams) {
  return {
    type: UPDATE_DOCUMENTATION_FILTER_PARAMS,
    payload: filterParams,
  };
}

export function updateBudgetItem(data) {
  return (dispatch, getState) => {
    return axios({
      method: 'post',
      url: `${SERVER_URL}/public/v1/budget/final`,
      data,
    })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: SAVE_FINAL_UPDATED_BUDGET,
            payload: response,
          });
          const { activeDocListItem } = getState().onboardingDocumentation;
          let newActiveDocListItem = { ...activeDocListItem };

          newActiveDocListItem.budgetConclusionDTO.final =
            data.budgetMap[newActiveDocListItem.type]._final;

          dispatch(setCurrentDocumentationItem(newActiveDocListItem));
          return response;
        } else {
          throw new Error('Unexpected response status');
        }
      })
      .catch((error) => {
        dispatch({
          type: SAVE_FINAL_UPDATED_BUDGET,
          payload: error,
        });
        return Promise.reject(error);
      });
  };
}
