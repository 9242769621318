import React, { Component } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { success, error as notifError } from 'react-notification-system-redux';

import KebabMenu from '../../../common/KebabMenu/KebabMenu';
import DentiModal from '../../../common/DentiModal';
import ConfirmationModal from '../../../common/ConfirmationModal';
import DeleteChainFail from './DeleteChainFail/DeleteChainFail';
import SimpleCheckbox from '../../../common/SimpleCheckbox/SimpleCheckbox';
import DeleteWarningModal from '../../../common/DeleteWarningModal';
import { strings } from '../../../../constants/localization';
import {
  moment,
  momentFormat,
  notificationDismissDuration,
} from '../../../../constants';
import { phoneNoFormat, addNotification } from '../../../../constants/utils';
import { addToBlacklist } from '../../../../actions/companyActions';
import { getCompanyChainLogs } from '../../../../actions/logActions';
import {
  updateCompanyChainInfo,
  deleteCompanyChainAndAssociatedCompanies,
  getMarketingFeeByBrand,
  setChainLoading,
  getCompanyChains,
  setCurrentChain,
  searchEmitted,
  filterEmitted,
  updateChainItemInList,
} from '../../../../actions/companyChainActions';
import { toggleServerModalError } from '../../../../actions/uiElementsActions';

class ChainInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      editMode: false,
      deleteConfirmation: false,
      editCvr: false,
      editCvrBlacklist: false,
      companyChainName: props.activeChain.companyChainName,
      cvr: props.activeChain.cvrNumber
        ? phoneNoFormat(props.activeChain.cvrNumber)
        : '',
      brand: props.activeChain.brand,
      status: props.activeChain.status,
      marketingFeeSelectedObj: {},
      marketingFee:
        this.props.activeChain.companyChainFee !== null
          ? `${String(this.props.activeChain.companyChainFee).replaceAll(
              '.',
              ','
            )} %`
          : '%',
      isMarketingFeeEmpty: this.props.activeChain.companyChainFee === null,
      trialEndDate: new Date(props.activeChain.trialEndDate),
      cvrError: '',
      blacklistCVR: false,
      companyChainNameError: '',
      marketingFeeError: '',
      deleteError: '',
    };
    this.onDelete = this.onDelete.bind(this);
    this.getCell = this.getCell.bind(this);
    this.save = this.save.bind(this);
    this.clickDelete = this.clickDelete.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.fieldHasError = this.fieldHasError.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.cancel = this.cancel.bind(this);
    this.blackListOldCvr = this.blackListOldCvr.bind(this);
    this.isBlackListChecked = this.isBlackListChecked.bind(this);
  }

  // GET THE MARKETING FEES FOR EACH BRAND
  componentDidMount() {
    this.props.getMarketingFeeByBrand('Denti');
    this.props.getMarketingFeeByBrand('Sundhed+');
    // filter the current chain for active companies
    let listOfActiveCompanies = [];
    if (this.props.activeChain?.companies.length > 0) {
      listOfActiveCompanies = this.props.activeChain.companies.filter(
        (company) => company.status === 'ACTIVE'
      );
    }
    this.setState({
      status: this.props.activeChain.status,
      listOfActiveCompanies: listOfActiveCompanies,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.activeChain) !==
      JSON.stringify(this.props.activeChain)
    ) {
      // filter the current chain for active companies
      let listOfActiveCompanies = [];
      if (this.props.activeChain?.companies.length > 0) {
        listOfActiveCompanies = this.props.activeChain.companies.filter(
          (company) => company.status === 'ACTIVE'
        );
      }
      // eslint-disable-next-line
      this.setState({
        loading: false,
        editMode: false,
        companyChainName: this.props.activeChain.companyChainName,
        cvr: this.props.activeChain.cvrNumber
          ? phoneNoFormat(this.props.activeChain.cvrNumber)
          : '',
        brand: this.props.activeChain.brand,
        status: this.props.activeChain.status,
        marketingFeeSelectedObj: {},
        marketingFee:
          this.props.activeChain.companyChainFee !== null
            ? `${String(this.props.activeChain.companyChainFee).replaceAll(
                '.',
                ','
              )} %`
            : '%',
        isMarketingFeeEmpty: this.props.activeChain.companyChainFee === null,
        trialEndDate: new Date(this.props.activeChain.trialEndDate),
        cvrError: '',
        blacklistCVR: false,
        editCvrBlacklist: false,
        editCvr: false,
        companyChainNameError: '',
        marketingFeeError: '',
        deleteError: '',
        deleteConfirmation: false,
        listOfActiveCompanies: listOfActiveCompanies,
      });
    }

    if (this.state.status !== this.props.activeChain.status) {
      this.setState({
        status: this.props.activeChain.status,
      });
    }
  }

  // used when not in edit mode
  generateMarketingFeeSituation() {
    const chain = this.props.activeChain;
    let feeResult = '';
    if (chain.companyChainFee === null) {
      if (chain.smartlink) {
        feeResult =
          chain.brandSmartLinkFee !== null
            ? `${chain.brandSmartLinkFee} %`
            : '';
      } else {
        feeResult =
          chain.brandNonSmartLinkFee !== null
            ? `${chain.brandNonSmartLinkFee} %`
            : '';
      }
    } else {
      feeResult = `${String(chain.companyChainFee).replaceAll('.', ',')} %`;
    }
    return feeResult;
  }

  // used in edit mode
  generateDefaultMarketingFeeSituation() {
    let currentMarketingObj;
    if (
      this.state.brand === 'Denti' &&
      Object.keys(this.state.marketingFeeSelectedObj).length === 0 &&
      this.props.marketingFeeDenti
    ) {
      currentMarketingObj = this.props.marketingFeeDenti;
    }
    if (
      this.state.brand === 'Sundhed+' &&
      Object.keys(this.state.marketingFeeSelectedObj).length === 0 &&
      this.props.marketingFeeDenti
    ) {
      currentMarketingObj = this.props.marketingFeeSundhed;
    }
    if (Object.keys(this.state.marketingFeeSelectedObj).length > 0) {
      currentMarketingObj = this.state.marketingFeeSelectedObj;
    }

    return strings.formatString(
      strings.marketingFeeEditCustomMsg,
      this.props.activeChain.smartlink
        ? `${currentMarketingObj.smartlinkFee} %`
        : `${currentMarketingObj.nonsmartlinkFee} %`
    );
  }

  clickDelete() {
    this.setState({
      deleteConfirmation: true,
    });
  }

  onDelete() {
    const { activeChain } = this.props;
    this.setState({
      loading: true,
    });
    this.props
      .deleteCompanyChainAndAssociatedCompanies({
        cvrNumber: activeChain.cvrNumber,
        chainCompanyId: activeChain.id,
      })
      .then((response) => {
        if (response?.payload.status === 200) {
          // if the blacklist is checked, the blacklist companyChain after delete
          if (this.state.blacklistCVR === true) {
            this.props.addToBlacklist(activeChain.cvrNumber).catch((e) => {
              this.props.toggleServerModalError(false);
              this.setState({
                deleteError: e.response.data.cause,
                deleteConfirmation: false,
                loading: false,
              });
            });
          }
          this.setState({
            deleteConfirmation: false,
            loading: false,
          });
          this.props.getCompanyChains(
            this.state.activePage - 1,
            this.props.searchValue.searchInput,
            null,
            undefined,
            this.props.filters
          );
          this.props.setCurrentChain();
        }
      })
      .catch((e) => {
        this.props.toggleServerModalError(false);
        this.setState({
          deleteError: e.response.data.cause,
          deleteConfirmation: false,
          loading: false,
        });
      });
  }

  validateForm() {
    let cvrError = '';
    let companyChainNameError = '';
    let marketingFeeError = '';

    if (this.state.cvr?.replaceAll(' ', '').length === 0) {
      cvrError = strings.required;
      this.setState({
        editCvrBlacklist: false,
      });
    } else if (this.state.cvr?.replaceAll(' ', '').length !== 8) {
      cvrError = strings.cvrError;
      this.setState({
        editCvrBlacklist: false,
      });
    } else {
      cvrError = '';
    }
    const oldCvr = phoneNoFormat(this.props.activeChain.cvrNumber);
    if (cvrError === '' && this.state.editCvr) {
      if (this.state.cvr === oldCvr || oldCvr === null || oldCvr === '') {
        this.setState({
          editCvrBlacklist: false,
        });
      } else {
        this.setState({
          editCvrBlacklist: true,
        });
      }
    }

    if (this.state.companyChainName.replaceAll(' ', '').length === 0) {
      companyChainNameError = strings.companyChainNameError;
    } else {
      companyChainNameError = '';
    }

    // if marketingFee value contains a ".",  set error message
    if (this.state.marketingFee.search(/[.]/g) !== -1) {
      marketingFeeError = strings.marketingFeeError;
    } else {
      marketingFeeError = '';
    }

    this.setState(
      {
        cvrError,
        companyChainNameError,
        marketingFeeError,
      },
      this.isBlackListChecked
    );
  }

  isBlackListChecked() {
    if (this.state.editCvrBlacklist === false) {
      this.saveForm();
    }
  }

  isFormValid() {
    return (
      this.state.cvrError.length === 0 &&
      this.state.companyChainNameError.length === 0 &&
      this.state.marketingFeeError.length === 0
    );
  }

  fieldHasError(field) {
    return !!this.state[`${field}Error`];
  }

  saveForm() {
    const marketingFeeWithoutPercentage = this.state.marketingFee
      .split('%')[0]
      .replaceAll(' ', '')
      .replaceAll(',', '.');
    const data = {
      id: this.props.activeChain.id,
      companyChainName: this.state.companyChainName,
      cvrNumber: this.state.cvr.replaceAll(' ', ''),
      brand: this.state.brand,
      companyChainFee:
        marketingFeeWithoutPercentage !== ''
          ? Number(marketingFeeWithoutPercentage)
          : null,
      trialEndDate: moment(this.state.trialEndDate).format('YYYY-MM-DD'),
    };
    if (this.isFormValid()) {
      this.props.setChainLoading(true);
      this.props
        .updateCompanyChainInfo(data)
        .then((response) => {
          if (response?.status === 200) {
            this.setState(
              {
                editMode: false,
                companyChainNameError: '',
                cvrError: '',
                marketingFeeError: '',
              },
              () => {
                this.props.setChainLoading(false);
                this.props.success(
                  addNotification(
                    strings.formatString(
                      strings.editCompanyChainSuccess,
                      `${this.state.companyChainName}`
                    ),
                    strings.editCompanyChainSuccessTitle,
                    notificationDismissDuration
                  )
                );
                this.props.getCompanyChainLogs(this.props.activeChain.id);
              }
            );
          }
        })
        .catch((e) => {
          this.props.setChainLoading(false);
          const errorResponse = e.response?.data?.cause;
          if (
            errorResponse.includes(
              'Bad Request: The entered cvr number does already exist.'
            )
          ) {
            this.setState({
              cvrError: strings.cvrExistsShort,
            });
            this.props.toggleServerModalError(false);
          } else {
            this.props.notifError(
              addNotification(
                strings.errorEditCompanyChain,
                strings.errorEditCompanyChainTitle,
                notificationDismissDuration
              )
            );
          }
        });
    }
  }

  save(ev) {
    ev.preventDefault();
    this.validateForm();
  }

  blackListOldCvr() {
    if (this.state.blacklistCVR === true) {
      this.props
        .addToBlacklist(this.props.activeChain.cvrNumber)
        .then((response) => {
          if (response?.payload.status === 200) {
            this.saveForm();
            this.setState({
              editCvrBlacklist: false,
              editCvr: false,
              loading: false,
            });
          } else {
            this.setState({
              editCvrBlacklist: false,
              editCvr: false,
              loading: false,
            });
          }
        })
        .catch(() => {
          this.props.toggleServerModalError(false);
          this.setState({
            editCvrBlacklist: false,
            editCvr: false,
            loading: false,
          });
        });
    } else {
      this.saveForm();
    }
  }

  cancel(ev) {
    ev.preventDefault();
    this.setState({
      editMode: false,
      editCvrBlacklist: false,
      editCvr: false,
      companyChainName: this.props.activeChain.companyChainName,
      cvr: this.props.activeChain.cvrNumber
        ? phoneNoFormat(this.props.activeChain.cvrNumber)
        : '',
      brand: this.props.activeChain.brand,
      marketingFeeSelectedObj: {},
      marketingFee:
        this.props.activeChain.companyChainFee !== null
          ? String(this.props.activeChain.companyChainFee).replaceAll('.', ',')
          : '',
      isMarketingFeeEmpty: this.props.activeChain.companyChainFee === null,
      trialEndDate: new Date(this.props.activeChain.trialEndDate),
      cvrError: '',
      companyChainNameError: '',
      marketingFeeError: '',
    });
  }

  changeValue(value, fieldName) {
    let saveValue = value;

    if (fieldName === 'registrationNumber') {
      saveValue = this.state.registrationNumber;
      // eslint-disable-next-line
      if (value.length <= 4 && !isNaN(value)) {
        saveValue = value;
      }
    }

    if (fieldName === 'cardNumber') {
      saveValue = this.state.cardNumber;
      // eslint-disable-next-line
      if (value.length <= 10 && !isNaN(value)) {
        saveValue = value;
      }
    }

    if (fieldName === 'cvr') {
      this.setState({
        editCvr: true,
      });
      saveValue = phoneNoFormat(saveValue.replaceAll(' ', ''));
      if (value.replaceAll(' ', '').length > 8) {
        saveValue = this.state.cvr;
      }
    }
    this.setState({ [fieldName]: saveValue });
  }

  getCell(logoClassName, title, value, fieldName, type) {
    const inputElement = (
      <input
        className='form-control custom-form-control'
        type='text'
        value={this.state[fieldName]}
        onChange={(e) => this.changeValue(e.target.value, fieldName)}
      />
    );

    const inputPercentageElement = (
      <>
        <div className='input-wrapper'>
          <input
            className='form-control custom-form-control'
            type='text'
            value={this.state.marketingFee.replaceAll('%', '').trim()}
            onKeyUp={(e) => {
              this.setState({
                isMarketingFeeEmpty: e.target.value.trim() === '',
              });
            }}
            onChange={(e) => this.changeValue(e.target.value, 'marketingFee')}
          />
          <span>%</span>
        </div>
        {(this.state.marketingFee.replaceAll('%', '').trim() === '' ||
          this.state.isMarketingFeeEmpty) &&
          this.state.editMode && (
            <span className='marketingFeeEditCustomMsg'>
              {this.generateDefaultMarketingFeeSituation()}
            </span>
          )}
      </>
    );

    const inputDate = (
      <input
        type='date'
        className='dateField form-control custom-form-control'
        onChange={(e) => {
          this.changeValue(e.target.value, 'trialEndDate');
        }}
        value={moment(this.state.trialEndDate).format('YYYY-MM-DD')}
      />
    );

    const inputBrand = (
      <>
        <Button
          className={`${
            this.state.brand !== 'Denti' ? 'btn-blue' : 'btn-light'
          } brandButtons`}
          onClick={() => {
            const marketingFeeFromProps = this.props.marketingFeeSundhed;
            this.setState({
              brand: 'Sundhed+',
              marketingFeeSelectedObj: marketingFeeFromProps,
            });
          }}
        >
          Sundhed+
        </Button>
        <Button
          className={`${
            this.state.brand === 'Denti' ? 'btn-blue' : 'btn-light'
          } brandButtons`}
          onClick={() => {
            const marketingFeeFromProps = this.props.marketingFeeDenti;
            this.setState({
              brand: 'Denti',
              marketingFeeSelectedObj: marketingFeeFromProps,
            });
          }}
        >
          Denti
        </Button>
      </>
    );

    return (
      <div className='col-12'>
        <div className='media'>
          <div className='logo media-left'>
            <div className='d-logo'>
              <i className={`lnir ${logoClassName}`} />
            </div>
          </div>
          <div className='media-body'>
            <div className='media-body-title'>
              <div>{title}</div>

              {this.fieldHasError(type || fieldName) && (
                <div className='error'>
                  {this.state[`${type || fieldName}Error`]}
                </div>
              )}
            </div>
            <div className={`media-body-content ${type} `}>
              {!type && (
                <>{this.state.editMode ? inputElement : value || '-'}</>
              )}
              {type === 'marketingFee' && (
                <>
                  {this.state.editMode
                    ? inputPercentageElement
                    : this.generateMarketingFeeSituation() || '-'}
                  {!this.state.editMode &&
                  this.props.activeChain.companyChainFee === null ? (
                    <span>({strings.marketingFeeCustomMsg})</span>
                  ) : (
                    ''
                  )}
                </>
              )}
              {type === 'brand' && (
                <>{this.state.editMode ? inputBrand : value}</>
              )}
              {type === 'date' && (
                <>
                  {this.state.editMode
                    ? inputDate
                    : moment(this.state.trialEndDate).format(momentFormat)}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { activeChain } = this.props;

    const {
      editMode,
      companyChainName,
      cvr,
      brand,
      marketingFee,
      trialEndDate,
    } = this.state;
    const trimedCVR = cvr ? cvr.replaceAll(' ', '') : '';
    return (
      <div
        id='ChainInformationComponent'
        className={`entity-information-card custom-card card ${
          this.state.editMode ? 'edit-mode' : ''
        }`}
      >
        <div className='card-body'>
          <div className='header-information'>
            <div className='header-text'>
              <div className='card-title'>{activeChain.companyChainName}</div>
              <span className='card-loked-data'>
                <span className='iconzone'>
                  {activeChain.brand === 'Denti' && (
                    <i className='di di-denti' />
                  )}
                  {activeChain.brand === 'Sundhed+' && (
                    <i className='di di-sundhedplus' />
                  )}
                  {activeChain.id}
                </span>
              </span>
            </div>
            <div className='header-actions'>
              {editMode ? (
                <div>
                  <Button
                    className='btn-light create-button'
                    onClick={this.cancel}
                  >
                    {strings.undoActionButton}
                  </Button>
                  <Button
                    className='btn-blue create-button'
                    onClick={(ev) => {
                      this.save(ev);
                    }}
                  >
                    <i className='lnil lnil-save icon' />
                    <span className='center_Text'>
                      {strings.saveActionButton}
                    </span>
                  </Button>
                </div>
              ) : (
                <KebabMenu>
                  <div
                    className='KebabMenu-item'
                    onClick={() => {
                      this.setState({
                        editMode: true,
                        cvrError: '',
                        companyChainNameError: '',
                      });
                    }}
                  >
                    <span className='icon'>
                      <i className='lnir lnir-pencil ' />
                    </span>
                    <span className='text'>{strings.edit}</span>
                  </div>

                  <div className='KebabMenu-item' onClick={this.clickDelete}>
                    <span className='icon'>
                      <i className='lnir lnir-trash-can' />
                    </span>
                    <span className='text'>{strings.delete}</span>
                  </div>
                </KebabMenu>
              )}
            </div>
          </div>

          <div className='mt-4 lead-personal-data row'>
            <div className='col-12 col-md-5 zone1'>
              <div className='row'>
                {this.getCell(
                  'lnir-house-alt',
                  strings.name,
                  companyChainName,
                  'companyChainName'
                )}
                {this.getCell('lnir-shield', strings.cvrLabel, cvr, 'cvr')}
                {this.getCell('lnir-flag', 'brand', brand, 'brand', 'brand')}
              </div>
            </div>
            <div className='col-12 col-md-7 zone2'>
              {this.getCell(
                'lnir-offer',
                strings.marketingFee,
                marketingFee,
                'marketingFee',
                'marketingFee'
              )}
              {this.getCell(
                'lnir-calender-alt-2',
                strings.trialEnd,
                trialEndDate,
                'trialEndDate',
                'date'
              )}
            </div>
          </div>
          {this.state.deleteConfirmation &&
            this.state.deleteError === '' &&
            this.state.status !== 'ACTIVE' &&
            this.state.listOfActiveCompanies.length === 0 && (
              <DentiModal
                key={1}
                close={() => this.setState({ deleteConfirmation: false })}
                modalClass='confirm-modal'
                modalClassName='confirm-action'
              >
                <ConfirmationModal
                  confirmAction={() => {
                    this.onDelete();
                  }}
                  loading={this.state.loading}
                  cancelAction={() => {
                    this.setState({
                      deleteConfirmation: false,
                    });
                  }}
                >
                  <div>
                    <p className='title'>{strings.deleteInvoiceTitle}</p>
                    <p className='confirm-message'>
                      {strings.formatString(
                        strings.chainDeleteConfirmMesage,
                        activeChain.companyChainName
                      )}
                    </p>
                    {trimedCVR && trimedCVR !== '-' && (
                      <SimpleCheckbox
                        customStyles='d-flex mb-20'
                        content={strings.formatString(
                          strings.blacklistCVR,
                          phoneNoFormat(this.props.activeChain.cvrNumber)
                        )}
                        checked={this.state.blacklistCVR}
                        handleClick={() => {
                          this.setState({
                            blacklistCVR: !this.state.blacklistCVR,
                          });
                        }}
                      />
                    )}
                  </div>
                </ConfirmationModal>
              </DentiModal>
            )}

          {this.state.deleteConfirmation && this.state.status === 'ACTIVE' && (
            <DentiModal
              key={1}
              close={() => this.setState({ deleteConfirmation: false })}
              modalClass='warning-modal'
              modalClassName='confirm-action'
              closeBtn={false}
            >
              <DeleteWarningModal
                headlineText={strings.deleteCompanyWarningHeadline}
                bodyText={strings.formatString(
                  strings.deleteCompanyChainWarningBody1,
                  <br />
                )}
                close={() => this.setState({ deleteConfirmation: false })}
              />
            </DentiModal>
          )}

          {this.state.deleteConfirmation &&
            this.state.status !== 'ACTIVE' &&
            this.state.listOfActiveCompanies.length > 0 && (
              <DentiModal
                key={1}
                close={() => this.setState({ deleteConfirmation: false })}
                modalClass='warning-modal'
                modalClassName='confirm-action'
                closeBtn={false}
              >
                <DeleteWarningModal
                  headlineText={strings.deleteCompanyWarningHeadline}
                  bodyText={strings.formatString(
                    strings.deleteCompanyChainWarningBody2,
                    <br />
                  )}
                  close={() => this.setState({ deleteConfirmation: false })}
                />
              </DentiModal>
            )}

          {this.state.deleteError !== '' && (
            <DentiModal
              key={2}
              close={() => this.setState({ deleteError: '' })}
              modalClass='confirm-modal'
              modalClassName='confirm-action'
            >
              <DeleteChainFail
                chainName={companyChainName}
                close={() => {
                  this.setState({ deleteError: '', deleteConfirmation: false });
                }}
                error={this.state.deleteError}
              />
            </DentiModal>
          )}
          {this.state.editCvrBlacklist === true && (
            <DentiModal
              key={3}
              modalClass='confirm-modal'
              modalClassName='confirm-action'
            >
              <ConfirmationModal
                confirmAction={() => {
                  this.blackListOldCvr();
                  this.setState({
                    loading: true,
                  });
                }}
                loading={this.state.loading}
                cancelAction={() => {
                  this.setState({
                    editCvrBlacklist: false,
                    editCvr: true,
                    loading: false,
                  });
                }}
              >
                <div>
                  <p className='title'>
                    {strings.chainEditCVRHeadlineBlackList}
                  </p>
                  <p className='confirm-message'>
                    {strings.chainEditCVRBodyBlackList}
                  </p>
                  <p>{strings.chainEditCVRBodyBlackListSecond}</p>

                  <SimpleCheckbox
                    customStyles='d-flex mb-20'
                    content={strings.formatString(
                      strings.blacklistCVR,
                      phoneNoFormat(this.props.activeChain.cvrNumber)
                    )}
                    checked={this.state.blacklistCVR}
                    handleClick={() => {
                      this.setState({
                        blacklistCVR: !this.state.blacklistCVR,
                      });
                    }}
                  />
                </div>
              </ConfirmationModal>
            </DentiModal>
          )}
        </div>
      </div>
    );
  }
}

ChainInformation.propTypes = {
  activeChain: PropTypes.object,
  update: PropTypes.func,
  chainList: PropTypes.array,
  filterEmitted: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    activeChain: state.companyChain.activeChain,
    marketingFeeDenti: state.companyChain.marketingFeeDenti,
    marketingFeeSundhed: state.companyChain.marketingFeeSundhed,
    chainList:
      state.companyChain && state.companyChain.companyChainList
        ? state.companyChain.companyChainList.content
        : [],
    filters: state.companyChain.filters,
  };
}

export default connect(mapStateToProps, {
  getMarketingFeeByBrand,
  getCompanyChainLogs,
  toggleServerModalError,
  updateCompanyChainInfo,
  addToBlacklist,
  deleteCompanyChainAndAssociatedCompanies,
  success,
  notifError,
  setChainLoading,
  getCompanyChains,
  searchEmitted,
  filterEmitted,
  setCurrentChain,
  updateChainItemInList,
})(ChainInformation);
