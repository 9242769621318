import DentiModal from '../../../common/DentiModal';
import { Button } from 'reactstrap';
import { strings } from '../../../../constants/localization';

const ApplicationEndpointRequestSuccessModal = ({ reloadApplication, headline, subtitle }) => {
    return (
        <div>
            <DentiModal
                modalClass='modal-dialog-centered application-request--modal'
                key={1}
                close={reloadApplication}
            >
                <div className='success'>
                    <div className='icon'>
                        <i className='di di-circle-check' />
                    </div>
                    <div>
                        <h3 className='headline'>{headline}</h3>
                        <p className='subtitle'>
                            {subtitle}
                        </p>
                    </div>
                    <div className='bottom-section action-btn cf'>
                        <Button color='blue' className='btn btn-cancel' onClick={reloadApplication}>
                            {strings.applicationEndpointRequestModalBtnText}
                        </Button>
                    </div>
                </div>
            </DentiModal>
        </div>
    )
}

export default ApplicationEndpointRequestSuccessModal;
