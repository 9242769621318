import webstomp from 'webstomp-client';
import SockJS from 'sockjs-client';
import { store } from '../store';
import {
  SOCKET_URL,
  ADD_CLIENT_WEBSOCKET_MESSAGE,
  ADD_CLINIC_WEBSOCKET_MESSAGE,
  ADD_DOCUMENTATION_WEBSOCKET_MESSAGE,
  REMOVE_CLIENT_WEBSOCKET_MESSAGE,
  REMOVE_CLINIC_WEBSOCKET_MESSAGE,
  REMOVE_DOCUMENTATION_WEBSOCKET_MESSAGE,
  ADD_CLIENT_PORTAL_WEBSOCKET_MESSAGE,
  REMOVE_CLIENT_PORTAL_WEBSOCKET_MESSAGE
} from '../constants';
import {
  isAdmin,
  isManager,
  isDentist,
  getLoggedInUserDetails,
} from '../constants/utils';

const subcribeToChannel = (webstompClient) => {
  const user = getLoggedInUserDetails();
  let subscriptionPath;
  // SUBSCRIBE TO CORESPONDING CHANNEL, BASED ON ROLE
  if (isAdmin(user)) {
    subscriptionPath = '/user/queue/admin';
  }
  if (isManager(user)) {
    subscriptionPath = '/user/queue/manager';
  }
  if (isDentist(user)) {
    subscriptionPath = '/user/queue/dentist';
  }
  webstompClient.subscribe(
    subscriptionPath,
    (message) => {
      const websocketMessage = JSON.parse(message.body);
      switch (websocketMessage?.websocketMessageType) {
        case 'COMPANY_UPDATED':
        case 'COMPANY_CREATE':
        case 'IMPORT_COMPANY_CREATE':
          store.dispatch(setClinicWebsocketMessage(websocketMessage));
          break;
        case 'CLIENT_INFO_UPDATE':
        case 'CREDIT_UPDATE':
        case 'CREDIT_CARD_UPDATED':
        case 'ACCEPTED_LOAN_NOTIFICATION':
        case 'DECLINED_LOAN_NOTIFICATION':
        case 'NEW_LOAN_NOTIFICATION':
        case 'DUNNING_NOTIFICATION':
        case 'INVOICE_REFUND':
        case 'LOAN_DELETED':
          store.dispatch(setClientWebsocketMessage(websocketMessage));
          break;
        case 'NEW_DOCUMENTATION_UPLOADED':
        case 'DOCUMENTATION_UPDATED':
          store.dispatch(
            setPendingDocumentationWebsocketMessage(websocketMessage)
          );
          break;
        default:
      }
      // acknowledge it
      message.ack();
    },
    { ack: 'client' }
  );
};

// this is the subscription for the onboarding page where there's no authenticated user
const subscribeToOnboardingChannel = (webstompClient, leadUuid) => {
  // Subscribe to channel using the leadUuid
  const subscriptionPath = `/topic/documentation/${leadUuid}`;
  webstompClient.subscribe(
    subscriptionPath,
    (message) => {
      const websocketMessage = JSON.parse(message.body);

      switch (websocketMessage?.websocketMessageType) {
        case 'NEW_DOCUMENTATION_UPLOADED':
        case 'DOCUMENTATION_UPDATED':
          if (websocketMessage?.leaduuid === leadUuid) {
            console.log(websocketMessage);
            store.dispatch(
              setPendingDocumentationWebsocketMessage(websocketMessage)
            );
          }

          break;
        default:
      }
      // acknowledge it
      message.ack();
    },
    { ack: 'client' }
  );
};

// this is the subscription for the onboarding page where there's no authenticated user
const subscribeToClientPortalChannel = (webstompClient, leadUuid) => {
  // Subscribe to channel using the leadUuid
  const subscriptionPath = `/topic/clientPortal/${leadUuid}`;
  webstompClient.subscribe(
    subscriptionPath,
    (message) => {
      const websocketMessage = JSON.parse(message.body);

      switch (websocketMessage?.websocketMessageType) {
        case 'CLIENT_INFO_UPDATE':
        case 'CREDIT_UPDATE':
        case 'CREDIT_CARD_UPDATED':
        case 'ACCEPTED_LOAN_NOTIFICATION':
        case 'DECLINED_LOAN_NOTIFICATION':
        case 'NEW_LOAN_NOTIFICATION':
        case 'DUNNING_NOTIFICATION':
        case 'INVOICE_REFUND':
        case 'LOAN_DELETED':
          store.dispatch(
            setClientPortalWebsocketMessage(
              websocketMessage.websocketMessageType
            )
          );
          break;

        default:
      }
      // acknowledge it
      message.ack();
    },
    { ack: 'client' }
  );
};

export function connectWebsocket(leadUuid = null, isClientPortal = false) {
  let webstompClient;
  const reconnect = true;
  const socket = new SockJS(SOCKET_URL);
  socket.protocol = 'v12.stomp';
  socket.onclose = () => {
    if (reconnect) {
      connectWebsocket();
    }
  };
  webstompClient = webstomp.over(socket);
  webstompClient.reconnect_delay = 10000;
  webstompClient.hasDebug = false;

  webstompClient.connect(
    {},
    () => {
      // If leadUuid is provided, handle onboarding or client portal subscription
      if (arguments.length === 0) {
        // subscribe to the main channel for authenticated users
        subcribeToChannel(webstompClient);
      } else if (isClientPortal) {
        subscribeToClientPortalChannel(webstompClient, leadUuid);
      } else {
        subscribeToOnboardingChannel(webstompClient, leadUuid);
      }
    },
    (error) => {
      console.log('stomp error', error);
      if (reconnect) {
        connectWebsocket();
      }
    }
  );

  return {
    type:
      arguments.length === 0
        ? 'CONNECT_WEBSOCKET'
        : isClientPortal
          ? 'CONNECT_WEBSOCKET_CLIENT_PORTAL'
          : 'CONNECT_WEBSOCKET_ONBOARDING',

    payload: webstompClient,
  };
}

export function setClientWebsocketMessage(wsObject) {
  return {
    type: ADD_CLIENT_WEBSOCKET_MESSAGE,
    payload: wsObject,
  };
}

export function setClientPortalWebsocketMessage(wsObject) {
  return {
    type: ADD_CLIENT_PORTAL_WEBSOCKET_MESSAGE,
    payload: wsObject,
  };
}

export function clearClientWebsocketMessage(index) {
  return {
    type: REMOVE_CLIENT_WEBSOCKET_MESSAGE,
    payload: index,
  };
}

export function setClinicWebsocketMessage(wsObject) {
  return {
    type: ADD_CLINIC_WEBSOCKET_MESSAGE,
    payload: wsObject,
  };
}

export function clearClinicWebsocketMessage() {
  return {
    type: REMOVE_CLINIC_WEBSOCKET_MESSAGE,
    payload: [],
  };
}

export function clearClientPortalWebsocketMessage() {
  return {
    type: REMOVE_CLIENT_PORTAL_WEBSOCKET_MESSAGE,
    payload: [],
  };
}

export function setPendingDocumentationWebsocketMessage(wsObject) {
  return {
    type: ADD_DOCUMENTATION_WEBSOCKET_MESSAGE,
    payload: wsObject,
  };
}

export function clearPendingDocumentationWebsocketMessage() {
  return {
    type: REMOVE_DOCUMENTATION_WEBSOCKET_MESSAGE,
    payload: [],
  };
}
