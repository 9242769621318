import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Pagination from 'react-js-pagination';
import { ListGroup, Form, FormGroup } from 'reactstrap';
import {
  trackVerticalStyle,
  thumbVerticalStyle,
  thumbVerticalCustomBackgroundStyle,
} from '../../../constants/styleScrollbar';
import DocumentationListItem from './DocumentationListItem';
import DocumentationFilter from './DocumentationFilter';
import {
  getAllDocuments,
  setCurrentDocumentationItem,
} from '../../../actions/onboardingDocumentationActions';
import { clearPendingDocumentationWebsocketMessage } from '../../../actions/websocketActions';
import {
  DOCUMENTATION_EMPTY,
  DOCUMENTATION_FAILURE,
  DOCUMENTATION_LOADING,
  DOCUMENTATION_SUCCESS,
} from '../../../constants';
import { setSelectedListItemFromStorage } from '../../../constants/utils';
import LoadingMessage from '../../statusMessages/LoadingMessage';
import EmptyMessage from '../../statusMessages/EmptyMessage';
import ServerModalError from '../../common/ServerModalError';
import { strings } from '../../../constants/localization';

const DocumentationList = ({ toggleMenu }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const search = location.search;
  const searchParams = new URLSearchParams(search);
  const scrollRef = useRef();
  const refName = useRef();
  const [renderSituation, setRenderSituation] = useState(null);
  // stored filters
  const filters = useSelector(
    (state) => state.onboardingDocumentation?.filterParams
  );
  const [searchInput, setSearchInput] = useState(filters?.searchInput || null);
  const activeDocListItem = useSelector(
    (state) => state.onboardingDocumentation?.activeDocListItem
  );
  const activePage = useSelector(
    (state) => state.onboardingDocumentation?.activePage
  );
  const [activePageLocal, setActivePageLocal] = useState(activePage || 1);
  const documentationLoading = useSelector(
    (state) => state.onboardingDocumentation?.loading
  );
  const documentationData = useSelector(
    (state) => state.onboardingDocumentation?.data
  );

  const wsDocumentationMessages = useSelector(
    (state) => state.websocketMessages?.documentation
  );

  // when the component mounts get the list of all documents
  useEffect(() => {
    const searchQuery = searchParams.get('q');
    const { status, type, searchInput } = filters || {};
    if (searchQuery) {
      setSearchInput(searchQuery);
      dispatch(getAllDocuments(status, type, searchQuery, activePageLocal - 1));
    } else {
      dispatch(getAllDocuments(status, type, searchInput, activePageLocal - 1));
      setSelectedListItemFromStorage(
        'lastSelectedDocumentation',
        (lastStelected) => {
          dispatch(setCurrentDocumentationItem(lastStelected));
        }
      );
    }
  }, []);

  useEffect(() => {
    if (activePageLocal !== activePage) {
      setActivePageLocal(activePage);
    }
  }, [activePage]);

  // check for messages from websocket that require documents list update
  useEffect(() => {
    // react to ws messages new entries
    if (wsDocumentationMessages?.length > 0) {
      //  status, page, searchInput, filter, type
      const { status, type } = filters || {};
      const { creditApplicationUuid, type: docType } = activeDocListItem || {};
      // get all updated documents
      dispatch(getAllDocuments(status, type, searchInput, activePage - 1));
      const newActiveDocListItem = documentationData?.content?.find(
        (doc) =>
          doc?.creditApplicationUuid === creditApplicationUuid &&
          doc?.type === docType
      );
      if (newActiveDocListItem) {
        dispatch(setCurrentDocumentationItem(newActiveDocListItem));
      }

      // clear the ws message afetr the list update
      dispatch(clearPendingDocumentationWebsocketMessage());
    }
  }, [wsDocumentationMessages]);

  const handleSearchSubmit = (values) => {
    const { status, type } = values || {};

    dispatch(getAllDocuments(status, type, searchInput, 0));
  };

  const handleSearch = (e) => {
    const { status, type } = filters || {};
    e.preventDefault();
    dispatch(getAllDocuments(status, type, searchInput, 0));
  };

  const handleClearSearch = () => {
    setSearchInput('');
    dispatch(getAllDocuments(null, null, '', 0));
  };

  const handlePageChange = (pageNumber) => {
    const { status, type } = filters || {};
    if (activePage !== pageNumber) {
      setActivePageLocal(pageNumber);
      dispatch(getAllDocuments(status, type, searchInput, pageNumber - 1));
    }
  };

  const renderList = () => {
    const brandEnvVariable = process.env.REACT_APP_BRAND;
    return (
      <div className='entities-list-group'>
        <Scrollbars
          ref={scrollRef}
          renderTrackVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                ...trackVerticalStyle,
              }}
            />
          )}
          renderThumbVertical={({ style, ...props }) =>
            brandEnvVariable === 'Denti' ? (
              <div
                {...props}
                style={{
                  ...style,
                  ...thumbVerticalStyle,
                }}
              />
            ) : (
              <div
                {...props}
                style={{
                  ...style,
                  ...thumbVerticalCustomBackgroundStyle,
                }}
              />
            )
          }
        >
          <div className='scrollbar-wrapper'>
            <ListGroup className='company-list-group'>
              {documentationData &&
                documentationData.content.map((doc, index) => (
                  <DocumentationListItem
                    key={index}
                    document={doc}
                    active={
                      doc?.creditApplicationUuid ===
                        activeDocListItem?.creditApplicationUuid &&
                      doc?.type === activeDocListItem?.type
                    }
                  />
                ))}
            </ListGroup>
            {documentationData && documentationData?.totalPages > 1 && (
              <Pagination
                activePage={documentationData.page + 1}
                itemClass='page-item'
                linkClass='page-link'
                itemsCountPerPage={documentationData.pageSize}
                totalItemsCount={documentationData.totalElements}
                pageRangeDisplayed={5}
                onChange={handlePageChange}
              />
            )}
          </div>
        </Scrollbars>
      </div>
    );
  };

  useEffect(() => {
    let situationComponent;

    switch (documentationLoading) {
      case DOCUMENTATION_LOADING:
        situationComponent = <LoadingMessage />;
        break;
      case DOCUMENTATION_SUCCESS:
        situationComponent = renderList();
        break;
      case DOCUMENTATION_EMPTY:
        situationComponent = (
          <div className='scrollbar-pseudo-rail'>
            {searchInput !== undefined && searchInput !== '' ? (
              <EmptyMessage message={strings.searchEmpty} />
            ) : (
              <>
                {activeDocListItem === undefined ? (
                  <EmptyMessage
                    message={strings.formatString(
                      strings.noItems,
                      strings.documentationPlaceHolder
                    )}
                  />
                ) : (
                  <EmptyMessage
                    message={strings.formatString(
                      strings.noCategoryItems,
                      strings.documentationPlaceHolder
                    )}
                  />
                )}
              </>
            )}
          </div>
        );
        break;
      case DOCUMENTATION_FAILURE:
        situationComponent = (
          <div className='scrollbar-pseudo-rail'>
            <ServerModalError />
          </div>
        );
        break;
      default:
        situationComponent = (
          <div className='scrollbar-pseudo-rail'>
            <EmptyMessage
              message={strings.formatString(
                strings.noItems,
                strings.documentationPlaceHolder
              )}
            />
          </div>
        );
    }

    setRenderSituation(situationComponent);

    // eslint-disable-next-line
  }, [documentationLoading, searchInput, activeDocListItem, documentationData]);

  return (
    <>
      <div className='header'>
        <button
          type='button'
          className='btn btn-white menu-toggler'
          onClick={toggleMenu}
        >
          <div className='menu-toggler-icon'>
            <span />
            <span />
            <span />
          </div>
        </button>
        <h3 className='title'>{strings.documentationSideBarMenuItem}</h3>
        <Form
          className='search-form'
          onSubmit={handleSearch}
          autoComplete='off'
        >
          {searchInput !== null && searchInput !== '' ? (
            <button
              type='button'
              className='btn-clear-search'
              onClick={handleClearSearch}
            >
              <span className='clear-search di di-close' />
            </button>
          ) : (
            <button type='submit' className='btn-search'>
              <span className='lnir lnir-search-alt' />
            </button>
          )}
          <FormGroup className='mb-0'>
            <input
              className='search-query form-control'
              id='searchInput'
              type='text'
              ref={refName}
              onChange={(e) => setSearchInput(e.target.value)}
              value={searchInput || ''}
              placeholder={strings.formatString(
                strings.documentationSeachPlaceholder,
                strings.companiesPlaceholder
              )}
            />
          </FormGroup>
        </Form>
      </div>
      <DocumentationFilter filterSubmit={handleSearchSubmit} />
      {renderSituation}
    </>
  );
};

export default DocumentationList;
