import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { strings } from '../../../constants/localization';

import './style.scss';

const CustomSelect = (props) => {
  const [clean, setClean] = useState(props.cleanStep);
  const [cleanStep, setCleanStep] = useState(props.cleanStep);
  const [valid, setValid] = useState(
    props.validation && props.value
      ? props.validation(props.value)
      : !props.validation
  );

  useEffect(() => {
    if (props.cleanStep !== cleanStep) {
      setCleanStep(props.cleanStep);
      setClean(props.cleanStep);
    }
    if (props.valid !== undefined && props.valid !== valid) {
      setValid(props.valid);
    }
  }, [props.cleanStep, cleanStep, props.valid, valid]);

  const onChangeHandler = (selectionArray) => {
    setClean(false);

    if (props.validation) {
      setValid(props.validation(selectionArray));
    }

    const eventLike = {
      target: { name: 'selectedCategories', value: selectionArray },
    };
    props.onChange(eventLike);
  };

  const isValid = () => {
    return clean || valid === true;
  };

  return (
    <div className='CustomSelectComponent'>
      <label htmlFor={props.name}>
        <span>{props.label}</span>
        {!props.stepIsValid &&
          !isValid() &&
          props.errorMsg &&
          props.value !== '' && (
            <span className='inlineInputError'>{props.errorMsg}</span>
          )}
      </label>

      <Select
        options={props.options}
        isMulti={props.isMulti}
        value={props.value}
        onChange={onChangeHandler}
        //onBlur={onChangeHandler}
        placeholder={strings.locationFlow_step2_placeholder_categories}
        className={`custom_select ${
          !props.stepIsValid && !isValid() ? 'invalidInput' : ''
        }`}
        classNamePrefix='custom_select'
        //menuIsOpen={true}
      />
    </div>
  );
};

export default CustomSelect;
