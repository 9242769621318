import React from 'react';
import DentiModal from '../common/DentiModal';
import { useDispatch } from 'react-redux';
import {
  patientNotFounded,
  patientFounded,
} from '../../actions/registerInvoiceActions';
import RegisterInvoiceFlow from './RegisterInvoiceFlow';

interface RegisterInvoiceModalProps {
  toggleCreateNewInvoice: () => void; // Function to toggle state in parent component
  createNewInvoice: boolean;
}

const RegisterInvoiceModal: React.FC<RegisterInvoiceModalProps> = ({
  toggleCreateNewInvoice,
}) => {
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    dispatch(patientNotFounded(false));
    dispatch(patientFounded(false));
    toggleCreateNewInvoice();
  };

  return (
    <div>
      <DentiModal
        key='register-invoice-modal'
        close={handleCloseModal}
        modalClass='register-invoice-modal modal-dialog-centered modal-dialog-scrollable'
      >
        <RegisterInvoiceFlow toggleCreateNewInvoice={handleCloseModal} />
      </DentiModal>
    </div>
  );
};

export default RegisterInvoiceModal;
