const prod = {
  //customerIO: 'https://fly.customer.io/env/95802/people/$0',
  customerIO: `https://fly.customer.io/env/95802/people/$0`,
  customerIOByEmail:
    'https://fly.customer.io/workspaces/95802/journeys/people?email=$0',
  billwerk: 'https://admin.billwerk.plus/#/rp/subscriptions/subscription/$0',
  zendesk: 'https://denti.zendesk.com/agent/search/1?q=$0',
  gistSiteID: 'cc71b6ea3a63d9a7a020',
  monthioUrl: 'https://flow.monthio.com/?sessionId=$0',
  monthioBudgetDataUrl:
    'https://portal.monthio.com/budget-business/smart-check-session/$0/dashboard',
  clientPortalUrl: 'https://mit.sundhedplus.dk',
  loginPageUrl: 'https://app.sundhedplus.dk/login',
  damAppMainPageUrl: 'https://app.sundhedplus.dk',
};

const preprod = {
  //customerIO: 'https://fly.customer.io/env/100351/people/$0',
  customerIO: `https://fly.customer.io/env/100351/people/$0`,
  customerIOByEmail:
    'https://fly.customer.io/workspaces/100351/journeys/people?email=$0',
  billwerk: 'https://admin.billwerk.plus/#/rp/subscriptions/subscription/$0',
  monthioUrl: 'https://flow.monthio.com/?sessionId=$0',
  monthioBudgetDataUrl:
    'https://portal.monthio.com/budget-business/smart-check-session/$0/dashboard',
  clientPortalUrl: 'https://mit-preprod.sundhedplus.dk',
  loginPageUrl: 'https://app-preprod.sundhedplus.dk/login',
  damAppMainPageUrl: 'https://app-preprod.sundhedplus.dk',
};

const staging = {
  //customerIO: 'https://fly.customer.io/env/100351/people/$0',
  customerIO: `https://fly.customer.io/env/100351/people/$0`,
  customerIOByEmail:
    'https://fly.customer.io/workspaces/100351/journeys/people?email=$0',
  billwerk: 'https://admin.billwerk.plus/#/rp/subscriptions/subscription/$0',
  zendesk: 'https://denti.zendesk.com/agent/search/1?q=$0',
  gistSiteID: '8c4bd8232bddfaa95d20',
  monthioUrl: 'https://test-flow.monthio.com/?sessionId=$0',
  monthioBudgetDataUrl:
    'https://test-portal.monthio.com/budget-business/smart-check-session/$0/dashboard',
  clientPortalUrl: 'https://mit-test.sundhedplus.dk',
  loginPageUrl: 'https://app-test.sundhedplus.dk/login',
  damAppMainPageUrl: 'https://app-test.sundhedplus.dk',
};

const Denti = {
  appName: 'Denti',
  site: 'denti.dk',
  presentationSite: 'https://www.denti.dk',
  mainPageUrl: 'https://www.denti.dk',
  mainPageUrlLoginWrongBrand: 'https://app.sundhedplus.dk',
  damMainPageUrl: 'https://dam.denti.dk',
  loginPageUrl: 'https://dam.denti.dk/login',
  contactEmail: 'kontakt@denti.dk',
  generalEmail: 'kontakt@denti.dk',
  contactPhone: '+45 2222 1121',
  contactPhoneHtmlFormated: '22 22 11 21',
  logoAltText: 'Denti logo',
  termsAndConditionsWithoutBnpl:
    'https://denti.dk/assets/aftalebestemmelser.html',
  termsAndConditionsWithBnpl:
    'https://denti.dk/assets/aftalebestemmelser_bnpl.html',
  termsAndConditionsUserRegistration:
    'https://www.denti.dk/brugerbetingelser.html',
  trustPilotUrl:
    'https://dk.trustpilot.com/review/denti.dk?utm_medium=Trustbox&amp;utm_source=EmailSignature4',
  trustPilotAltRatingImg: 'TrustPilot rating',
  trustPilotLogoAlt: 'TrustPilot',
  creditorName: 'CMC Finans ApS',
  creditorAddress: 'Rådhusvej 13',
  creditorLocation: 'DK-2920 Charlottenlund',
  companyRegistrationNumber: '37701149',
  paymentAgreementParticipant: 'Denti ApS (37701149)',
  userRegistrationWelcomePage: '',
  emailTemplateTextCopyrightDate: '2018',
  SundhedplusForBehandlere: 'https://www.sundhedplus.dk/for-behandlere/',
};

const Sundhedplus = {
  appName: 'Sundhed+',
  site: 'sundhedplus.dk',
  presentationSite: 'https://www.sundhedplus.dk',
  mainPageUrl: 'https://app.sundhedplus.dk',
  mainPageUrlLoginWrongBrand: 'https://dam.denti.dk/',
  damMainPageUrl: 'https://dam.denti.dk/',
  loginPageUrl: 'https://app.sundhedplus.dk/login',
  contactEmail: 'kontakt@sundhedplus.dk',
  generalEmail: 'partner@sundhedplus.dk',
  contactPhone: '+45 8282 8383',
  contactPhoneHtmlFormated: '82 82 83 83',
  logoAltText: 'Sundhed+ logo',
  termsAndConditionsWithoutBnpl:
    'https://sundhedplus.dk/assets/aftalebestemmelser.html',
  termsAndConditionsWithBnpl:
    'https://sundhedplus.dk/assets/aftalebestemmelser.html',
  termsAndConditionsUserRegistration:
    'https://www.sundhedplus.dk/brugerbetingelser.html',
  termsAndConditionsMigration:
    'https://www.sundhedplus.dk/assets/migration-medlemsbetingelser.html ',
  termsAndConditionsOnboarding:
    'https://www.sundhedplus.dk/assets/medlemsbetingelser.html',
  trustPilotUrl:
    'https://dk.trustpilot.com/review/denti.dk?utm_medium=Trustbox&amp;utm_source=EmailSignature4',
  trustPilotAltRatingImg: 'TrustPilot rating',
  trustPilotLogoAlt: 'TrustPilot',
  creditorName: 'CMC Finans ApS',
  creditorAddress: 'Rådhusvej 13',
  creditorLocation: 'DK-2920 Charlottenlund',
  companyRegistrationNumber: '37701149',
  paymentAgreementParticipant: 'Denti ApS (37701149)',
  userRegistrationWelcomePage:
    'https://www.sundhedplus.dk/velkommen-som-ny-behandler/',
  emailTemplateTextCopyrightDate: '2020',
  SundhedplusForBehandlere: 'https://www.sundhedplus.dk/for-behandlere/',
  SundhedplusFindClinicUrl: 'https://www.sundhedplus.dk/find-behandler/',
  privacyPolicy: 'https://www.sundhedplus.dk/privatlivspolitik/',
  seeOurGuide: 'https://www.sundhedplus.dk/hemmelig-adresse/',
  borderDk: 'https://www.borger.dk/',
  advantagesPublicPage: 'https://www.sundhedplus.dk/fordele/',
  noitsoLink: `https://budget.sundhedplus.dk/budget/$0`,
};

// credit info limits
const creditMinAmount = 1000;
const creditMaxAmount = 75000;
const creditMaxAmountApproveCredit = 50000;
const creditMaxAmountApproveCreditForWarningCards = '50.000';
const creditMinInterestRate = 7.95;
const creditMaxInterestRate = 17.95;
const creditMaxMonths = 12; // from today, for credit expiration date
const creditMinDurationMin = 24;
const creditMinDurationMax = 48;
const creditMaxDuration = 72; // for loan acceptance
const maxMonthlyPaymentRate = 0.8;
const maxMotnhlyPaymentAllowed = 20000;

// restucturing
const maxAprRestructuring = 24.75;

// loan calculator default interestRate
const loanCalcDefaultInterestRate = 12.95;

const companyProfileDescriptionCharsLimit = 500;
const timeoutMinutesSessionExpiration = 720; //12h
const timeoutMinutesSessionWarningShow = 5; // how many minutes before expiration to show warning
const timeoutMinutesBeforeLogout = 1; // how many minutes before expiration to logout user

// partial redemption limits
const partialRedemptionCreditMinDuration = 21;
const partialRedemptionCreditMaxDuration = 47;

// onboarding
const uploadFileSizeLimit = 10000; // KB -> 10MB

// Onboarding staging test data
const onboardingTestData = {
  firstName: 'Elda',
  lastName: 'Ebert',
  email: 'kb@denti.dk',
  phone: '26898522',
  leadUuid: 'f27b1f09-d351-4f08-b2d3-9c00086b8719',
  memberUuid: 'f9c52270-dcd1-4347-96cc-fa22fbaa66e4',
  cpr: '0101324532',
  budgetId: '2b2f3b79-64c1-4d9d-9b33-6f5aabd36908',
  budgetTransactionId: '8a617fec-9603-451f-aa56-4e60830c0ac5',
  bisnodeCprLookupId: 14,
  debtSinceLastTaxReport: {
    amount: 0,
    monthlyPayment: 0,
  },
  statedDisposableAmount: 6500,
  missingDocumentation: [
    { amount: 0.0, type: 'HEATING_EXPENSES' },
    { amount: 1270, type: 'WATER' },
    { amount: 555, type: 'ELECTRICITY' },
    { amount: 260, type: 'PHONE_SUBSCRIPTION' },
  ],
};

const config =
  process.env.REACT_APP_STAGE === 'production'
    ? prod
    : process.env.REACT_APP_STAGE === 'preprod'
    ? preprod
    : staging;

const brandConfig =
  process.env.REACT_APP_BRAND === 'Denti' ? Denti : Sundhedplus;

// Remove console logs from production
if (process.env.REACT_APP_STAGE === 'production') {
  console.log = function () {};
}

export {
  // Add common config values here
  config,
  brandConfig,
  companyProfileDescriptionCharsLimit,
  Sundhedplus,
  timeoutMinutesSessionExpiration,
  timeoutMinutesSessionWarningShow,
  timeoutMinutesBeforeLogout,
  creditMinAmount,
  creditMaxAmount,
  creditMaxAmountApproveCredit,
  creditMinInterestRate,
  creditMaxInterestRate,
  creditMaxMonths,
  creditMinDurationMin,
  creditMinDurationMax,
  creditMaxDuration,
  maxMonthlyPaymentRate,
  maxMotnhlyPaymentAllowed,
  maxAprRestructuring,
  onboardingTestData,
  uploadFileSizeLimit,
  loanCalcDefaultInterestRate,
  creditMaxAmountApproveCreditForWarningCards,
  partialRedemptionCreditMinDuration,
  partialRedemptionCreditMaxDuration,
};
