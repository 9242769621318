import React, { useState } from 'react';
import Error from '../../common/ErrorPage';
import { Button } from 'reactstrap';
import { strings } from '../../../constants/localization';
import { useCriiptoVerify } from '@criipto/verify-react';

import {
  createMitIdState,
  isClientLogout,
  isNotClient as setIsNotClient
  // eslint-disable-next-line
} from '../../../actions/memberPortalActions';
import { AnyAction } from 'redux';
import { useDispatch } from 'react-redux';
import ButtonLoader from '../../../components/common/ButtonLoader/ButtonLoader';

interface ErrorPageForClientPortalProps {
  setErrorEndpoint: React.Dispatch<React.SetStateAction<boolean>>;
  isNotClient: boolean;
}

const ErrorPageForClientPortal: React.FC<ErrorPageForClientPortalProps> = ({
  setErrorEndpoint,
  isNotClient,
}) => {
  const { loginWithRedirect } = useCriiptoVerify();
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const dispatch = useDispatch();

  return (
    <Error isClientPortalError>
      {isNotClient ? (
        <>
          <h1>{strings.clientPortalErrorNotClientTitle}</h1>
          <p>{strings.clientPortalErrorNotClientP1}</p>
          <p>
            {strings.formatString(
              strings.clientPortalErrorNotClientP2,
              <a
                href={`https://${process.env.REACT_APP_ONBOARDING_DOMAIN}`}
                rel='noopener noreferrer'
                className='link'
              >
                {strings.clientPortalErrorNotClientStartApplication}
              </a>
            )}
          </p>

          <p>
            {strings.formatString(
              strings.clientPortalErrorNotClientP3,
              <a
                href={`https://${process.env.REACT_APP_ONBOARDING_DOMAIN}`}
                rel='noopener noreferrer'
                className='link'
              >
                {strings.clientPortalErrorNotClientCreateAccount}
              </a>
            )}
          </p>

          <div className='button-container'>
            <Button
              className='btn btn-blue mt-4 error-button-client-portal'
              onClick={() => {
                window.location.href = 'https://www.sundhedplus.dk/';
              }}
            >
              {loadingButton ? (
                <ButtonLoader />
              ) : (
                strings.clientPortalErrorButtonGoBackToSundhed
              )}{' '}
            </Button>
          </div>
        </>
      ) : (
        <>
          <h1>{strings.clientPortalErrorTitle}</h1>
          <p>{strings.clientPortalErrorP1}</p>
          <div className='button-container'>
            <Button
              className='btn btn-blue button-client-portal mt-4'
              onClick={() => {
                dispatch(isClientLogout(false));
                setLoadingButton(true);
                setErrorEndpoint(false);
                dispatch(createMitIdState() as AnyAction).then(() => {
                  dispatch(setIsNotClient(false));
                  loginWithRedirect().then(() => {
                    setLoadingButton(false);
                  });
                });
              }}
            >
              {loadingButton ? (
                <ButtonLoader />
              ) : (
                strings.clientPortalErrorButton
              )}{' '}
            </Button>
          </div>
        </>
      )}
    </Error>
  );
};

export default ErrorPageForClientPortal;
