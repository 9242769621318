import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, FormGroup, Label } from 'reactstrap';
import { connect, useSelector } from 'react-redux';
import Select from 'react-select';
import './style.scss';
import { strings } from '../../../../constants/localization';
import { moment } from '../../../../constants';
import {
  numberWithDotAsThousandsSeparator,
  paymentListMoment,
  formatDanishWithoutFractionDigits,
  removeCurrencyFormating,
} from '../../../../constants/utils';
import {
  creditFaciltyStatusesMapEN,
  creditFaciltyStatusesMapDA,
} from '../../../../constants/mappings';
import ButtonLoader from '../../../common/ButtonLoader/ButtonLoader';
import {
  updateCreditFacilityDetails,
  getUpdatedPatientCreditFacility,
  updatePatientInLeadsList,
} from '../../../../actions/leadActions';
import { getLogsCreditFacility } from '../../../../actions/logActions';
import { toggleServerModalError } from '../../../../actions/uiElementsActions';
import SimpleCheckbox from '../../../common/SimpleCheckbox/SimpleCheckbox';
import { DatePicker } from 'reactstrap-date-picker';
import {
  creditMaxInterestRate,
  creditMaxAmount,
  creditMaxMonths,
  creditMinDurationMin,
  creditMinDurationMax,
} from '../../../../config';

let timoutId;

const EditCreditFacility = (props) => {
  const activeLead = useSelector((state) => state.lead.activeLead);
  const credit = activeLead?.creditFacilityInformation;
  const [amount, setAmount] = useState(credit.amount || '');
  const [interestRate, setInterestRate] = useState(credit.interestRate || '');
  const [expirationDate, setExpirationDate] = useState(
    credit.expirationDate || ''
  );
  const [creditExpired, setCreditExpired] = useState(false);
  const [estimatedDisposableAmount, setEstimatedDisposableAmount] =
    useState('');
  const [cursorPositionEstimatedAmpunt, setCursorPositionEstimatedAmpunt] =
    useState(0);
  const [minDuration, setMinDuration] = useState('');
  const [maxMonthlyPayment, setMaxMonthlyPayment] = useState(
    credit?.maxMonthlyPayment || ''
  );
  const [missingCreditAmount, setMissingCreditAmount] = useState(false);
  const [missingEstimatedAmount, setMissingEstimatedAmount] = useState(false);
  const [minAmountRequired, setMinAmountRequired] = useState('');
  const [sendUpdatedCreditEmail, setSendUpdatedCreditEmail] = useState(false);
  const [status, setStatus] = useState({});
  const [comment, setComment] = useState('');
  const [amountError, setAmountError] = useState(false);
  const [interestRateError, setInterestRateError] = useState(false);
  const [expirationDateError, setExpirationDateError] = useState(false);
  const [commentError, setCommentError] = useState(false);
  const [monthlyPaymentError, setMonthlyPaymentError] = useState(false);
  const [showComment, setShowComment] = useState(false);
  const [fadeOutComment, setFadeOutComment] = useState(false);

  const [loading, setLoading] = useState(false);
  const [savedWithSuccess, setSavedWithSuccess] = useState(false);
  const [savedWithFailure, setSavedWithFailure] = useState(false);
  const firstRender = useRef(true);
  let checkBoxTextColor = { color: '#444444' };

  let creditFacilityStatuses = [];
  if (props.user.language === 'en') {
    creditFacilityStatuses = creditFaciltyStatusesMapEN;
  } else if (props.user.language === 'da') {
    creditFacilityStatuses = creditFaciltyStatusesMapDA;
  }

  useEffect(() => {
    //mount
    return () => {
      //unmount
      if (timoutId !== undefined) {
        clearTimeout(timoutId);
      }
    };
  }, []);

  useEffect(() => {
    if (minDuration) {
      recalculateValues(minDuration);
    }
  }, [minDuration, maxMonthlyPayment]);

  //for fixing update warning
  useEffect(() => {
    const formatMaxMonthlyPayment = credit?.maxMonthlyPayment
      ? formatDanishWithoutFractionDigits(credit?.maxMonthlyPayment)
      : '';
    const calculatedEstimatedAmount = credit?.maxMonthlyPayment
      ? Math.floor(credit?.maxMonthlyPayment).toString().replace('.', ',')
      : '';
    setAmount(numberWithDotAsThousandsSeparator(credit.amount) + ' kr.');
    setInterestRate(credit.interestRate.toString().replace('.', ',') + ' %');
    setExpirationDate(credit.expirationDate);
    // Set the status object with data from credit facility
    creditFacilityStatuses.forEach((status) => {
      if (status.value.trim() === credit.status.trim()) {
        setStatus({
          label: status.label,
          value: status.value,
        });
      }
    });
    if (calculatedEstimatedAmount) {
      setEstimatedDisposableAmount(
        numberWithDotAsThousandsSeparator(calculatedEstimatedAmount) + ' kr.'
      );
    }
    setMinDuration(credit?.minimumDuration || '');
    setMaxMonthlyPayment(formatMaxMonthlyPayment);
    setComment(credit.comment);
    setFadeOutComment(false);
    setMonthlyPaymentError(false);
  }, [credit, creditFacilityStatuses, credit.status]);

  useEffect(() => {
    const today = moment();
    const chosenDate = moment(expirationDate);

    const isExpired = chosenDate.isBefore(today);
    setCreditExpired(isExpired);

    let hasUpdated = false;
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      const amountNo = Number(amount.replace('.', '').replace(' kr', ''));
      const interestRateNo = Number(
        interestRate.replace(',', '.').replace(' %', '')
      );
      const formattedDate = moment(expirationDate).format('YYYY-MM-DD');
      const maxMonthlyPaymentNo = removeCurrencyFormating(
        maxMonthlyPayment?.replace('.', '')
      );
      hasUpdated =
        amountNo !== credit.amount ||
        interestRateNo !== credit.interestRate ||
        formattedDate !== credit.expirationDate ||
        status.value !== credit.status ||
        (maxMonthlyPaymentNo &&
          maxMonthlyPaymentNo !== credit.maxMonthlyPayment) ||
        (minDuration && minDuration !== credit?.minimumDuration);
    }
    setShowComment(hasUpdated);
  }, [
    credit,
    amount,
    interestRate,
    expirationDate,
    status,
    maxMonthlyPayment,
    minDuration,
  ]);

  useEffect(() => {
    if (timoutId !== undefined) {
      clearTimeout(timoutId);
      timoutId = undefined;
    }
    if (savedWithSuccess) {
      timoutId = setTimeout(() => setSavedWithSuccess(false), 2000);
    } else if (savedWithFailure) {
      timoutId = setTimeout(() => setSavedWithFailure(false), 2000);
    }
  }, [savedWithSuccess, savedWithFailure]);

  useEffect(() => {
    const amountNo = Number(
      amount.toString().replace('.', '').replace(' kr', '')
    );
    if (status?.value === 'ACTIVE' && !amountNo) {
      setMissingCreditAmount(true);
    }
    if (status?.value !== 'ACTIVE' && sendUpdatedCreditEmail) {
      setSendUpdatedCreditEmail(false);
    }
  }, [status]);

  let handleDateChange = (value) => {
    setExpirationDate(value);
  };

  const handleBlurEstimatedDisposableAmount = (e) => {
    const value = e.target.value;
    const clearStringNumber = value
      ?.replace(' kr', '')
      .replace('.', '')
      .replace(',', '.');
    const numberValue = Number(clearStringNumber);
    setEstimatedDisposableAmount(e.target.value ? `${e.target.value} kr.` : '');

    if (numberValue) {
      setMissingEstimatedAmount(false);
      setMinDuration(creditMinDurationMin);
      setMaxMonthlyPayment(formatDanishWithoutFractionDigits(numberValue));
    } else {
      if (status.value === 'ACTIVE' && !creditExpired) {
        setMissingEstimatedAmount(true);
      }
      setMinDuration('');
      setMaxMonthlyPayment('');
    }
  };

  const recalculateValues = (currentMinDuration) => {
    // if those values are set, then do the calculation for the maxMonthlyPayment
    const amountNo = Number(
      amount.toString().replace('.', '').replace(' kr', '')
    );
    // Remove '%' symbol and replace comma with dot
    const interestRateValue = parseFloat(
      interestRate.toString().replace('%', '').replace(',', '.')
    );
    if (!amountNo && status?.value === 'ACTIVE' && !creditExpired) {
      setMissingCreditAmount(true);
    }
    if (amountNo && interestRateValue !== undefined) {
      const minDurationNo =
        Number(currentMinDuration) ||
        credit?.minimumDuration ||
        creditMinDurationMin;
      const startOfNextMonth = moment()
        .add(1, 'M')
        .startOf('month')
        .format('YYYY-MM-DD');
      // establismentFee is always 5 % of the loan amount
      const establishmentFee = 0.05 * amountNo;

      const paymentResults = paymentListMoment(
        startOfNextMonth,
        interestRateValue,
        amountNo,
        minDurationNo,
        parseFloat(establishmentFee)
      );
      const monthlyAmount = parseInt(paymentResults?.monthlyPayment, 10);
      const numberEstimatedDisposableAmount = removeCurrencyFormating(
        estimatedDisposableAmount?.toString().replace('.', '')
      );

      const numberMaxMonthlyPayment = removeCurrencyFormating(
        maxMonthlyPayment?.toString().replace('.', '')
      );

      if (numberEstimatedDisposableAmount) {
        if (monthlyAmount && monthlyAmount > numberMaxMonthlyPayment) {
          //stop whenever Monthly Payment from the loan calculation logic is lower than maxMonthlyPayment and minDuration < 48
          if (minDurationNo < creditMinDurationMax) {
            setMinDuration(minDurationNo + 2);
          }
          if (minDuration >= creditMinDurationMax) {
            setMinAmountRequired(monthlyAmount);
            setMonthlyPaymentError(true);
          }
        } else {
          setMonthlyPaymentError(false);
        }
      }
    }
  };

  let handleSubmit = () => {
    const amountNo = Number(amount.replace('.', '').replace(' kr', ''));
    const interestRateNo = Number(
      interestRate.replace(',', '.').replace(' %', '')
    );
    const today = moment();
    const chosenDate = moment(expirationDate);
    const formattedDate = chosenDate.format('YYYY-MM-DD');
    // Add 12 months to the current date
    const futureDate = today.add(creditMaxMonths, 'months');

    const estimatedAmountNo = removeCurrencyFormating(
      estimatedDisposableAmount?.replace('.', '')
    );
    const maxMonthlyPaymentNo = removeCurrencyFormating(
      maxMonthlyPayment?.replace('.', '')
    );
    // validate inputs
    setAmountError(amount === ' kr.' || amountNo > creditMaxAmount);
    setInterestRateError(
      interestRate === ' %' || interestRateNo > creditMaxInterestRate
    );

    setExpirationDateError(chosenDate.isAfter(futureDate));
    setCommentError(!comment || comment?.trim() === '');
    let formHasError =
      amount === ' kr.' ||
      amountNo > creditMaxAmount ||
      interestRate === ' %' ||
      interestRateNo > creditMaxInterestRate ||
      chosenDate.isAfter(futureDate) ||
      !comment ||
      comment?.trim() === '';
    const hasUpdatedValues =
      amountNo !== credit.amount ||
      interestRateNo !== credit.interestRate ||
      formattedDate !== credit.expirationDate ||
      status.value !== credit.status ||
      maxMonthlyPaymentNo !== credit.maxMonthlyPayment ||
      minDuration !== credit?.minimumDuration;

    //If the status of a credit facility set to Active(updated or not) and not expired
    // Estimated disposable amount should be set to have a value for minimum duration and max monthly payment.
    if (
      status.value === 'ACTIVE' &&
      !creditExpired &&
      estimatedDisposableAmount.trim() === ''
    ) {
      setMissingEstimatedAmount(true);
      formHasError = true;
    }
    // in case the status is already active and there's no changes in the credut value that is still 0
    if (missingCreditAmount) {
      formHasError = true;
    }

    const updatedCredit = {
      amount: amountNo !== credit.amount ? amountNo : null,
      comment: comment !== '' && comment !== credit?.comment ? comment : null,
      creditFacilityNumber: credit.number,
      creditFacilityStatus:
        status.value !== credit.status ? status.value : null,
      expirationDate:
        formattedDate !== credit.expirationDate ? formattedDate : null,
      interestRate:
        interestRateNo !== credit.interestRate ? interestRateNo : null,
      sendCreditUpdatedEmail: sendUpdatedCreditEmail,
      estimatedDisposableAmount: estimatedAmountNo || null,
      maxMonthlyPayment:
        maxMonthlyPayment !== '-' ? maxMonthlyPaymentNo || null : null,
      minimumDuration: minDuration !== '-' ? minDuration || null : null,
    };

    // if all fields are valid and there's no error
    if (!formHasError && hasUpdatedValues) {
      setLoading(true);
      props
        .updateCreditFacilityDetails(updatedCredit)
        .then((response) => {
          setLoading(false);
          if (response.payload.status === 200) {
            setFadeOutComment(true);
            setSavedWithSuccess(true);
            setComment('');
            props.getUpdatedPatientCreditFacility(credit.id);
            props.getLogsCreditFacility(credit.number);
            setSendUpdatedCreditEmail(false);
          }
        })
        .catch(() => {
          props.toggleServerModalError(false);
          setLoading(false);
          setSavedWithFailure(true);
        });
    }
  };

  const shouldDisableSaveButton = () => {
    // If comment is not set, disable the save button
    if (!comment) {
      return true;
    }

    // If credit is active and not expired
    if (status.value === 'ACTIVE' && !creditExpired) {
      // If estimatedDisposableAmount is missing, disable the save button
      if (estimatedDisposableAmount.trim() === '' || monthlyPaymentError) {
        return true;
      }
    }

    // Allow submit in all other cases
    return false;
  };

  return (
    <form className='edit-credit-form' autoComplete='off'>
      <Row xs='2'>
        <Col>
          <Label>
            <span>{strings.creditDetailsAmount}</span>
            {amountError && (
              <span className='inlineInputError'>
                {strings.formatString(
                  strings.creditDetailsAmountError,
                  numberWithDotAsThousandsSeparator(creditMaxAmount)
                )}
              </span>
            )}
            {missingCreditAmount && (
              <span className='inlineInputError'>{strings.required}</span>
            )}
          </Label>
          <div className='amount-wrapper'>
            <input
              type='text'
              name='amount'
              className='form-control'
              value={amount !== 0 ? amount : ''}
              maxLength={creditMaxAmount.toString().length + 1}
              onChange={(e) => {
                //first remove the existing .
                let amountWithoutDot = e.target.value.replace('.', '');
                setAmount(numberWithDotAsThousandsSeparator(amountWithoutDot));
              }}
              onFocus={(e) => {
                setMissingCreditAmount(false);
                setMonthlyPaymentError(false);
                const valueWithoutCurrency = e.target.value.replace(' kr.', '');
                if (Number(valueWithoutCurrency) === 0) {
                  setAmount('');
                } else {
                  setAmount(valueWithoutCurrency);
                }
              }}
              onBlur={(e) => {
                setAmount(`${e.target.value} kr.`);
                if (!e.target.value && status?.value === 'ACTIVE') {
                  setMissingCreditAmount(true);
                }
                recalculateValues(creditMinDurationMin);
              }}
            />
          </div>
        </Col>
        <Col>
          <Label>{strings.creditDetailsStatus}</Label>
          <Select
            options={creditFacilityStatuses}
            isMulti={false}
            value={status}
            onChange={(selectedStatus) => {
              // recalculate values if the status is changed to ACTIVE
              setStatus(selectedStatus);
              if (selectedStatus.value === 'ACTIVE' && !creditExpired) {
                if (minDuration > creditMinDurationMax) {
                  recalculateValues(creditMinDurationMax);
                } else {
                  recalculateValues(creditMinDurationMin);
                }
              }
            }}
            className='custom_select'
            classNamePrefix='custom_select'
            menuPlacement='auto'
          />
        </Col>
      </Row>

      <Row xs='2'>
        <Col>
          <Label>
            <span>{strings.creditDetailsInterestRate}</span>
            {interestRateError && (
              <span className='inlineInputError'>
                {strings.formatString(
                  strings.creditDetailsInterestRateError,
                  creditMaxInterestRate
                )}
              </span>
            )}
          </Label>
          <div className='interest-wrapper'>
            <input
              type='text'
              className='form-control'
              value={interestRate}
              onChange={(e) => {
                //only digits and comma
                let checkedInput = e.target.value
                  .replace(/[^0-9,]/g, '')
                  .replace(/(,.*?),(.*,)?/, '$1');
                setInterestRate(checkedInput);
              }}
              onFocus={(e) => setInterestRate(e.target.value.replace(' %', ''))}
              onBlur={(e) => {
                setInterestRate(`${e.target.value} %`);
                recalculateValues(creditMinDurationMin);
              }}
            />
          </div>
        </Col>
        <Col>
          <FormGroup>
            <Label>
              <span>{strings.creditDetailsExpirationDate}</span>
              {expirationDateError && (
                <span className='inlineInputError'>
                  {strings.formatString(
                    strings.creditDetailsDateError,
                    creditMaxMonths
                  )}
                </span>
              )}
            </Label>
            <div className='credit-date-picker'>
              <DatePicker
                id='expiration-date-datepicker'
                value={expirationDate}
                selected={expirationDate}
                locale='da'
                dateFormat='DD-MM-YYYY'
                showClearButton={false}
                onChange={(v, f) => handleDateChange(v, f)}
                //onBlur={() => saveBtnClicked ? validateExpirationDate(): ''}
              />
              <i
                className='lnil lnil-calender-alt'
                htmlFor='expiration-date-datepicker'
              ></i>
            </div>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col xs='12' md='5'>
          <Label>
            <span>{strings.creditDetailsEstimatedDisposableAmount}</span>
            {missingEstimatedAmount && (
              <span className='inlineInputError'>{strings.required}</span>
            )}
          </Label>
          <div className='amount-wrapper '>
            <input
              ref={(input) => {
                if (
                  input &&
                  input?.selectionStart !== cursorPositionEstimatedAmpunt
                ) {
                  const cursorPosition =
                    cursorPositionEstimatedAmpunt > 0
                      ? input.value?.includes('.')
                        ? cursorPositionEstimatedAmpunt + 1
                        : cursorPositionEstimatedAmpunt - 1
                      : 0;
                  input.setSelectionRange(cursorPosition, cursorPosition);
                }
              }}
              type='text'
              name='estimatedDisposableAmount'
              className='form-control estimated'
              value={
                estimatedDisposableAmount !== 0 ? estimatedDisposableAmount : ''
              }
              onChange={(e) => {
                // save the cursor position
                setCursorPositionEstimatedAmpunt(e.target.selectionStart);
                //first remove the existing .
                let amountWithoutDot = e.target.value.replace('.', '');
                setEstimatedDisposableAmount(
                  numberWithDotAsThousandsSeparator(amountWithoutDot)
                );
              }}
              onFocus={(e) => {
                const valueWithoutCurrency = e.target.value.replace(' kr.', '');
                if (Number(valueWithoutCurrency) === 0) {
                  setEstimatedDisposableAmount('');
                } else {
                  setEstimatedDisposableAmount(valueWithoutCurrency);
                }
              }}
              onBlur={handleBlurEstimatedDisposableAmount}
            />
          </div>
        </Col>

        <Col xs='12' md='7' className='display-amounts'>
          <div className='calculated-results'>
            <span>{strings.creditDetailsMinDuration}</span>
            <span>
              {minDuration
                ? `${minDuration} ${strings.creditDetailsMinDurationMonths}`
                : '-'}
            </span>
          </div>
          <div className='calculated-results'>
            <span>{strings.creditDetailsMaxMotnhlyPayment}</span>
            <span>{maxMonthlyPayment || '-'}</span>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          {estimatedDisposableAmount && monthlyPaymentError && (
            <div className='calculation-error'>
              {strings.formatString(
                strings.creditDetailsValidateValuesError,
                minAmountRequired.toString().includes('kr')
                  ? minAmountRequired
                  : formatDanishWithoutFractionDigits(minAmountRequired) || '',
                amount || ''
              )}
            </div>
          )}
        </Col>
      </Row>

      {/* Show checkbox only if the credit status is already active and there has been changes OR if the new status is active */}
      {((credit?.status === 'ACTIVE' &&
        showComment === true &&
        status?.value === 'ACTIVE') ||
        (credit?.status !== status?.value && status?.value === 'ACTIVE')) && (
        <Row>
          <Col>
            <SimpleCheckbox
              customStyles='mt-0 pt-0 #444444'
              textColor={checkBoxTextColor}
              content={strings.creditSendUpdate}
              handleClick={() => {
                setSendUpdatedCreditEmail(!sendUpdatedCreditEmail);
              }}
              checked={sendUpdatedCreditEmail}
            />
          </Col>
        </Row>
      )}
      <Row
        xs='1'
        className={
          !showComment
            ? 'hide-textarea'
            : fadeOutComment
            ? 'fadeOutAndHide'
            : ''
        }
      >
        <Col>
          <Row xs='2' className='mb-0'>
            <Col>
              <Label>
                <span>{strings.creditDetailsChangeReason}</span>
              </Label>
              {commentError && showComment && (
                <span className='inlineInputError'>
                  {strings.creditDetailsCommentError}
                </span>
              )}
            </Col>
          </Row>
          <textarea
            type='text'
            className='form-control'
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            //onBlur={() => saveBtnClicked ? validateComment(): ''}
          ></textarea>
        </Col>
      </Row>

      <div className='text-center'>
        {/* Save button  */}
        {!loading && !savedWithSuccess && !savedWithFailure && (
          <Button
            color='blue'
            disabled={shouldDisableSaveButton()}
            onClick={handleSubmit}
          >
            <i className='lnil lnil-save' />
            {strings.creditDetailsSaveButton}

            {loading && <ButtonLoader />}
          </Button>
        )}

        {/* loading button  */}
        {loading && (
          <Button color='blue' disabled={true}>
            <ButtonLoader />
          </Button>
        )}

        {/* saved with success button  */}
        {savedWithSuccess && (
          <Button color='blue' disabled={true}>
            <i className='lnil lnil-checkmark' />
            {strings.creditDetailsSaveButtonSuccess}
          </Button>
        )}

        {/* saved with failure button  */}
        {savedWithFailure && (
          <Button color='red' disabled={true}>
            <i className='lnil lnil-close' />
            {strings.creditDetailsSaveButtonFailure}
          </Button>
        )}
      </div>
    </form>
  );
};

function mapStateToProps(state) {
  return {
    user: state.user.user,
  };
}

export default connect(mapStateToProps, {
  updateCreditFacilityDetails,
  getUpdatedPatientCreditFacility,
  updatePatientInLeadsList,
  getLogsCreditFacility,
  toggleServerModalError,
})(EditCreditFacility);
