import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import Switch from 'react-switch';
import ReactQuill from 'react-quill';
import { config } from './../../config';
import { currencyFormatDA, getCustomerIOLink } from '../../constants/utils';
import LoadingMessage from '../statusMessages/LoadingMessage';
import * as constants from '../../constants';
import {
  updateLeadInternalNoteSupport,
  getPatientDataSupport,
  addEmailSegmentUserAssociation,
  removeEmailSegmentUserAssociation,
} from '../../actions/liveAgentActions';
import './style.scss';
import { strings } from '../../constants/localization';
import Matches from './Matches/Matches';
import { SERVER_URL } from '../../constants';

class LiveAgent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: null,
      emailFlowValue: false,
      textarea: '',
      isMultiple: false,
      noteSavefail: false,
      noteSaving: false,
      noteSaved: false,
      actionTaken: false,
    };
    this.modules = {
      toolbar: [
        ['bold', 'italic', 'underline'],
        ['image'],
        [{ list: 'ordered' }],
        [{ color: [] }],
        ['clean'],
      ],
    };

    this.emailFlowClick = this.emailFlowClick.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.saveInternalNote = this.saveInternalNote.bind(this);
  }

  componentDidMount() {
    const iOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (iOS) {
      document.addEventListener(
        'gesturestart',
        function (e) {
          e.preventDefault();
        },
        false
      );
    }

    document.body.classList.add('public-content');
    document.body.classList.add('public-flow');

    this.props
      .getPatientDataSupport(this.props.auth, {
        emailList:
          this.props.emails?.length > 0 && this.props.emails[0] !== ''
            ? this.props.emails
            : null,
        phoneNumber: this.props.phone || null,
        firstName: this.props.firstname || null,
      })
      .then((result) => {
        let emailFlowValue = false;
        result.payload.data.emailSegments.forEach((value) => {
          if (value.segmentName === 'Vælg en behandler') {
            emailFlowValue = true;
          }
        });

        this.setState({
          data: result.payload.data,
          loading: false,
          textarea: decodeURIComponent(result.payload.data.internalNote || ''),
          emailFlowValue,
        });
      })
      .catch((e) => {
        this.setState({
          error: 'haserror', /// display last screen
          loading: false,
          isMultiple: e.response.data.cause === 'more than one match',
        });
      });
  }

  componentWillUnmount() {
    document.body.classList.remove('public-flow');
    document.body.classList.remove('public-content');
  }

  handleTextChange(htmlText) {
    this.setState({
      textarea: htmlText,
    });
  }

  saveInternalNote() {
    this.setState(
      { actionTaken: true, noteSaving: true, noteSavefail: false },
      () => {
        this.props
          .updateLeadInternalNoteSupport(
            this.props.auth,
            this.state.data.leadId,
            encodeURIComponent(this.state.textarea)
          )
          .then(() => {
            this.setState(
              {
                noteSaved: true,
                noteSaving: false,
              },
              () => {
                setTimeout(() => {
                  this.setState({ noteSaved: false, actionTaken: false });
                }, 3000);
              }
            );
          })
          .catch(() => {
            this.setState({
              noteSavefail: true,
              noteSaving: false,
            });
          });
      }
    );
  }

  emailFlowClick() {
    const { emailFlowValue } = this.state;
    if (emailFlowValue) {
      this.props
        .removeEmailSegmentUserAssociation(this.props.auth, {
          userId: this.state.data.uuid,
          segmentId: 2,
          userType: 'MEMBER',
        })
        .then(() => {
          this.setState({
            emailFlowValue: !emailFlowValue,
          });
        });
    } else {
      this.props
        .addEmailSegmentUserAssociation(this.props.auth, {
          userId: this.state.data.uuid,
          segmentId: 2,
          userType: 'MEMBER',
        })
        .then(() => {
          this.setState({
            emailFlowValue: !emailFlowValue,
          });
        });
    }
  }

  renderPatientData() {
    const {
      data,
      error,
      loading,
      emailFlowValue,
      textarea,
      noteSavefail,
      noteSaving,
      noteSaved,
      actionTaken,
    } = this.state || {};
    const {
      creditStatus,
      creditExpirationDate,
      latestAcceptedInstallmentLoanNumber,
    } = data || {};
    let creditStatusLabel = '';
    let creditStatusColor = '';
    let notDisabledOrRejectedAndWithAcceptedLoans = false;
    const daysSinceExpired =
      constants
        .moment()
        .diff(constants.moment(creditExpirationDate, 'DD-MM-YYYY'), 'days') ||
      0;

    // set the corresponding credit status label
    creditStatusLabel =
      creditStatus === 'ACTIVE' && daysSinceExpired > 0
        ? strings.liveAgentCreditStatus_EXPIRED
        : strings[`liveAgentCreditStatus_${creditStatus}`] || '';
    // set the corresponding credit status label color
    creditStatusColor =
      creditStatus === 'ACTIVE' && daysSinceExpired < 0 ? 'green' : '';
    if (creditStatus === 'ACTIVE' && daysSinceExpired < 0) {
      creditStatusColor = 'green';
    } else if (creditStatus === 'ACTIVE' && daysSinceExpired > 0) {
      creditStatusColor = 'yelow';
    } else if (
      creditStatus === 'SUSPENDED' ||
      creditStatus === 'LATE_PAYMENT'
    ) {
      creditStatusColor = 'red';
    } else {
      creditStatusColor = '';
    }

    if (
      creditStatus !== 'DISABLED' &&
      (creditStatus !== 'REJECTED' || data.numberOfAcceptedLoans !== 0)
    ) {
      notDisabledOrRejectedAndWithAcceptedLoans = true;
    }
    return (
      <>
        {loading && <LoadingMessage />}
        {!loading && data && !error && (
          <>
            <div
              className={`topLine_area ${
                !data.clientDunningStatus ||
                (!data.clientDunningStatus &&
                  data.numberOfAcceptedLoans === 0 &&
                  data.creditCardStatus === null)
                  ? 'green'
                  : ''
              } ${
                !data.clientDunningStatus &&
                data.creditCardStatus === null &&
                data.numberOfAcceptedLoans !== 0
                  ? 'orange'
                  : ''
              } ${data.clientDunningStatus ? 'red' : ''}`}
            />
            <div className='LiveAgent_content'>
              <div className='titel_area'>
                {data.patientFullName}
                {data.patientBrand === 'Denti' && (
                  <span className='name_icon blue'>
                    <i className='di di-denti' />
                  </span>
                )}
                {data.patientBrand === 'Sundhed+' && (
                  <span className='name_icon red'>
                    <i className='di di-sundhedplus' />
                  </span>
                )}
              </div>
              <div className='input_area'>
                <div>
                  <ReactQuill
                    placeholder={strings.notePlaceholder}
                    theme='bubble'
                    onChange={this.handleTextChange}
                    value={this.state.textarea}
                    modules={this.modules}
                    bounds='.quill-editor'
                  />
                </div>
                {textarea && textarea !== data.internalNote && (
                  <div className='textButton'>
                    {actionTaken && (
                      <div className='noteText'>
                        {noteSavefail && <>{strings.notSaved}</>}
                        {noteSaving && <>{strings.saving}</>}
                        {noteSaved && <>{strings.saved}</>}
                      </div>
                    )}
                    <Button color='orange' onClick={this.saveInternalNote}>
                      {strings.saveActionButton}
                    </Button>
                  </div>
                )}
              </div>
              <div className='buttons_area patient-tab'>
                <Button
                  color='orange'
                  tag='a'
                  href={`${window.location.origin}/leads?q=${data.patientEmailAddress}`}
                  target='_blank'
                >
                  {strings.openPatientDAM}
                </Button>
                <Button
                  color={
                    data.patientReepaySubscription === null ? 'gray' : 'orange'
                  }
                  tag='a'
                  href={`${config.billwerk?.replace(
                    '$0',
                    data.patientReepaySubscription
                  )}`}
                  target='_blank'
                  disabled={data.patientReepaySubscription === null}
                >
                  {strings.openPatientReepay}
                </Button>
                <Button
                  color={creditStatus === 'DISABLED' ? 'gray' : 'orange'}
                  tag='a'
                  onClick={() => {
                    const linkFilters = getCustomerIOLink(new Date());
                    const customerIoURL = config.customerIO.replace(
                      '$0',
                      data?.userId
                    );
                    window.open(`${customerIoURL}/${linkFilters}`, '_blank');
                  }}
                  target='_blank'
                  disabled={creditStatus === 'DISABLED'}
                >
                  {strings.openPatientCustomerIo}
                </Button>
              </div>
              <div className='emailFlow_area'>
                <div>{strings.chooseClinic}</div>
                <Switch
                  onChange={this.emailFlowClick}
                  checked={emailFlowValue}
                  className='react-switch'
                />
              </div>

              <div className='patientdata_area'>
                <div className='dataRow'>
                  <div className='leftItem'>{strings.membershipCreated}</div>
                  <div className='rightItem'>
                    {constants
                      .moment(data.creditCreatedDate, 'DD-MM-YYYY')
                      .format(constants.momentFormatLong)
                      .toLowerCase()}
                  </div>
                </div>
                <div className='dataRow '>
                  <div className='leftItem'>{strings.creditLastRenewed}</div>
                  <div className='rightItem'>
                    {data.creditCardStatus !== null && (
                      <>
                        {constants
                          .moment(data.creditLastRenewalDate, 'DD-MM-YYYY')
                          .format(constants.momentFormatLong)
                          .toLowerCase()}
                      </>
                    )}
                    {data.creditCardStatus === null && <>{strings.never}</>}
                  </div>
                </div>
                <div className='dataRow'>
                  <div className='leftItem'>{strings.creditStatus}</div>
                  <div className='rightItem'>
                    <span className={creditStatusColor || ''}>
                      {creditStatusLabel || ''}
                    </span>
                    {creditStatus !== 'LATE_PAYMENT' &&
                      creditStatus !== 'DISABLED' &&
                      creditStatus !== 'REJECTED' && (
                        <>{` (${constants
                          .moment(creditExpirationDate, 'DD-MM-YYYY')
                          .format(constants.momentFormatLong)
                          .toLowerCase()})`}</>
                      )}
                  </div>
                </div>
                <div className='dataRow '>
                  <div className='leftItem'>
                    {strings.memberAvailableCredit}
                  </div>
                  <div className='rightItem'>
                    {notDisabledOrRejectedAndWithAcceptedLoans
                      ? `${currencyFormatDA(
                          data.currentlyAvailableCredit,
                          true,
                          true,
                          false
                        )} / ${currencyFormatDA(
                          data.creditAmount,
                          true,
                          true,
                          false
                        )}`
                      : '-'}
                  </div>
                </div>
                <div className='dataRow'>
                  <div className='leftItem'>{strings.rmnPrincipal}</div>
                  <div className='rightItem'>
                    {notDisabledOrRejectedAndWithAcceptedLoans
                      ? currencyFormatDA(
                          data.remainingBalance,
                          true,
                          true,
                          false
                        )
                      : '-'}
                  </div>
                </div>
                <div className='dataRow ' />
                <div className='dataRow'>
                  <div className='leftItem'>{strings.activeLoans}</div>
                  <div className='rightItem'>
                    {notDisabledOrRejectedAndWithAcceptedLoans
                      ? `${data.numberOfAcceptedLoans} ${strings.loans}`
                      : '-'}
                  </div>
                </div>
                <div className='dataRow '>
                  <div className='leftItem'>{strings.latestPayment}</div>
                  <div className='rightItem'>
                    {notDisabledOrRejectedAndWithAcceptedLoans
                      ? `${
                          data.dateOfLastPayment !== null
                            ? `${constants
                                .moment(data.dateOfLastPayment, 'YYYY-MM-DD')
                                .format(constants.momentFormatLong)
                                .toLowerCase()} (${currencyFormatDA(
                                data.amountOfLastPaidReepayInvoice,
                                true,
                                true,
                                false
                              )})`
                            : ''
                        } `
                      : '-'}
                    {data.dateOfLastPayment === null &&
                    creditStatus !== 'DISABLED' &&
                    creditStatus !== 'REJECTED'
                      ? strings.never
                      : ''}
                  </div>
                </div>
                <div className='dataRow'>
                  <div className='leftItem'>{strings.creditCard}</div>
                  <div
                    className={`rightItem ${
                      data.creditCardStatus === 'ACTIVE' ? 'green' : ''
                    } ${
                      data.creditCardStatus === null &&
                      notDisabledOrRejectedAndWithAcceptedLoans
                        ? 'yelow'
                        : ''
                    } ${
                      data.creditCardStatus !== 'ACTIVE' &&
                      data.creditCardStatus !== null &&
                      notDisabledOrRejectedAndWithAcceptedLoans
                        ? 'red'
                        : ''
                    }`}
                  >
                    {data.creditCardStatus === 'ACTIVE'
                      ? strings.cardActive
                      : ''}
                    {data.creditCardStatus === null &&
                    notDisabledOrRejectedAndWithAcceptedLoans
                      ? strings.noCard
                      : ''}
                    {data.creditCardStatus !== 'ACTIVE' &&
                    data.creditCardStatus !== null &&
                    notDisabledOrRejectedAndWithAcceptedLoans
                      ? strings.cardDeclined
                      : ''}
                    {!notDisabledOrRejectedAndWithAcceptedLoans ? '-' : ''}
                  </div>
                </div>

                <div className='links-to-copy'>
                  {latestAcceptedInstallmentLoanNumber !== null && (
                    <div className='link-input-group'>
                      <label>{strings.liveAgentUpdateCreditLabel}</label>
                      <input
                        type='text'
                        value={`${SERVER_URL}/public/installmentloan/changeCreditInformation?installmentLoanNumber=${latestAcceptedInstallmentLoanNumber}`}
                        readOnly={true}
                        onFocus={(event) => {
                          event.target.select();
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className='clinics_area'>
                {data.clinicList && data.clinicList.length > 0 && (
                  <div className='clinic_area_name'>
                    {strings.clinicsWithAccess}
                  </div>
                )}
                {data.clinicList &&
                  data.clinicList.map((clinic) => (
                    <div key={clinic.clinicNumber} className='dataRow'>
                      <div className='leftItem'>
                        {`${clinic.clinicName} (${clinic.clinicNumber})`}
                      </div>
                      <div
                        className={`rightItem brand ${
                          clinic.brand === 'Denti' ? 'brand-d' : 'brand-s'
                        }`}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </>
        )}
        {error && <Matches isMultiple={this.state.isMultiple} />}
      </>
    );
  }

  render() {
    return <div className='LiveAgent_page'>{this.renderPatientData()}</div>;
  }
}
export default connect(null, {
  updateLeadInternalNoteSupport,
  getPatientDataSupport,
  addEmailSegmentUserAssociation,
  removeEmailSegmentUserAssociation,
})(LiveAgent);
