import React, { useEffect, useState } from 'react';
import DentiModal from '../../../common/DentiModal';
import { moment } from '../../../../constants';
import { strings } from '../../../../constants/localization';
import { formatDanishWithoutFractionDigits } from '../../../../constants/utils';
import { applicationPayslipsMap } from '../../../../constants/mappings';

const PayslipsModal = ({ payslips, sixMonthsAverageSalary, closeModal }) => {
  const [applicationPayslips, setApplicationPayslips] = useState([]);
  const [moreThanOnePayslip, setMoreThanOnePayslip] = useState(false);
  const [averageNetSalary, setAverageNetSalary] = useState(0);

  useEffect(() => {
    if (payslips?.length > 0) {
      const payslipsOrdered = payslips.reverse();

      // calculate the total salary
      const totalSalary = payslipsOrdered.reduce(
        (acc, payslip) => acc + payslip.netSalary,
        0
      );
      // calculate the average net salary
      const averageNetSalaryResult = totalSalary / payslipsOrdered.length;
      setApplicationPayslips(payslipsOrdered);
      setAverageNetSalary(averageNetSalaryResult);
      setMoreThanOnePayslip(checkMultipleObjects());
    }
  }, [payslips]);

  const groupEntriesByMonth = () => {
    const grouped = {};
    applicationPayslips.forEach((entry) => {
      const month = moment(entry.payslipDate).format('MMMM YYYY');
      if (!grouped[month]) {
        grouped[month] = [];
      }
      grouped[month].push(entry);
    });
    return grouped;
  };

  const groupedPayslipsByMonth = groupEntriesByMonth();
  const checkMultipleObjects = () => {
    const keys = Object.keys(groupedPayslipsByMonth);
    return keys.length > 1;
  };

  return (
    <DentiModal
      key={2}
      close={() => closeModal()}
      modalClass='onboarding payslips-modal'
      modalClassName='payslips-modal'
      closeBtn={true}
    >
      <div className='payslips-entries'>
        {Object.keys(groupedPayslipsByMonth).map((month, monthIndex) => {
          const monthPayslisps = groupedPayslipsByMonth[month];

          return (
            <div className='payslips-group' key={monthIndex}>
              <div className='month-date'>{month ? month : '-'}</div>
              <div className='month-group'>
                {monthPayslisps.map((payslip, index) => {
                  const { netSalary, grossPay, salaryType } = payslip || {};
                  return (
                    <div
                      className={`payslip  ${
                        moreThanOnePayslip ? 'multiplePaySlip' : ''
                      }`}
                      key={index}
                    >
                      <div className='month-content'>
                        <div className='line'>
                          <span className='label'>
                            {strings.applicationIncomeNetSalary}
                          </span>
                          <span className='value net'>
                            {netSalary
                              ? formatDanishWithoutFractionDigits(
                                  Math.floor(netSalary)
                                )
                              : '-'}
                          </span>
                        </div>
                        <div className='line'>
                          <span className='label'>
                            {strings.applicationIncomeGrossSalary}
                          </span>
                          <span className='value gross'>
                            {grossPay
                              ? formatDanishWithoutFractionDigits(
                                  Math.floor(grossPay)
                                )
                              : '-'}
                          </span>
                        </div>
                        <div className='line'>
                          <span className='label'>
                            {strings.applicationIncomeSalaryType}
                          </span>
                          <span className='value'>
                            {salaryType
                              ? `(${salaryType}) ${
                                  strings[
                                    applicationPayslipsMap[salaryType]
                                      ?.payslipsSalaryTypeText
                                  ]
                                }`
                              : '-'}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>

      <div className='average'>
        <div className='line'>
          <span className='label'>
            {strings.applicationIncomeSalaryAverage}
          </span>
          <span className='value gross'>
            {sixMonthsAverageSalary
              ? formatDanishWithoutFractionDigits(
                  Math.floor(sixMonthsAverageSalary)
                )
              : averageNetSalary
              ? formatDanishWithoutFractionDigits(Math.floor(averageNetSalary))
              : '-'}
          </span>
        </div>
      </div>
    </DentiModal>
  );
};

export default PayslipsModal;
