import { strings } from '../../../constants/localization';
import React from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import EntityTableComponent from '../common/EntityTableComponent';

import moment from 'moment';
import { momentFormatLong } from '../../../constants';
import { useSelector } from 'react-redux';

interface companyDataListProps {
  companyDataList: [
    {
      createdDate: string;
      companyName: string;
    }
  ];
}

const PractitionerAccess: React.FC = () => {
  const clientInformation = useSelector(
    (state: {
      memberPortal: { clientDataInformation: companyDataListProps };
    }) => state.memberPortal?.clientDataInformation
  );

  let buttonDisabled = true; // hidden button for the first version

  const billingHistory = clientInformation?.companyDataList || [];

  const reverseListOrder = billingHistory.reverse();

  return (
    <div className='entity-information-card'>
      <Card className='custom-card'>
        <CardBody className='credit-info-card'>
          <div className='title'>{strings.mitPractitionerAccessTitle}</div>
          <div className='content'>
            <p>{strings.mitPractitionerAccessP1}</p>
            <p>{strings.mitPractitionerAccessP2}</p>
          </div>

          <EntityTableComponent
            tableList={billingHistory}
            tableHeaders={[
              strings.mitPractitionerAccessPage,
              strings.mitBillingHistoryClinicAssociated,
            ]}
            cardClass='practitioner-access-card'
            practitionerUsage
          >
            {reverseListOrder.map((billing, index) => (
              <div
                className='table-row-div'
                id='practitioner-list-item'
                key={index}
              >
                <div className='table-cell-div'>
                  {moment(billing.createdDate)
                    .format(momentFormatLong)
                    .toLowerCase()}
                </div>

                <div className='table-cell-div '>{billing.companyName}</div>

                <div
                  className='table-cell-div'
                  onClick={(event) => event.stopPropagation()}
                >
                  <div className='actions-menu'>
                    {!buttonDisabled && (
                      <Button
                        className='btn btn-light delete-clinic'
                      // onClick={() => deleteClinic()}
                      >
                        <i className='lnil lnil-cross-circle' />
                        {strings.mitPractitionerButton}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </EntityTableComponent>
        </CardBody>
      </Card>
    </div>
  );
};

export default PractitionerAccess;
