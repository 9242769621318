import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { strings } from '../../../constants/localization';
import { formatDanishWithoutFractionDigits } from '../../../constants/utils';
// eslint-disable-next-line
import ServicesActionEntry from './ServicesActionEntry';
// eslint-disable-next-line
import { useServiceActionContext } from './ServiceActionProvider';

const ServicesActions: React.FC = () => {
    const { openPartialPaymentModal, openSettleLoanModal } = useServiceActionContext();
    return (
        <Card className='custom-card'>
            <CardBody className='credit-info-card'>
                <div className='title'>{strings.mitServicesSectionTitle}</div>
                <ServicesActionEntry headline={
                    strings.mitServicesSectionPartialRedemptionHeadline
                }
                    description={strings.mitServicesSectionPartialRedemptionDescription}
                    buttonText={strings.mitServicesSectionPartialRedemptionButton}
                    buttonCallback={openPartialPaymentModal}
                    feeText={strings.formatString(strings.mitServicesSectionFee, formatDanishWithoutFractionDigits(0))}
                />

                <ServicesActionEntry headline={
                    strings.mitServicesSectionSettleHeadline
                }
                    description={strings.mitServicesSectionSettleDescription}
                    buttonText={strings.mitServicesSectionSettleButton}
                    buttonCallback={openSettleLoanModal}
                    feeText={strings.formatString(strings.mitServicesSectionFee, formatDanishWithoutFractionDigits(0))}
                />
            </CardBody>
        </Card>
    );
};

export default ServicesActions;
