import React from 'react';
import Input from '../common/AppInput/Input';
import CustomSelect from '../common/Select/Select';
import Checkbox from '../common/SimpleCheckbox/SimpleCheckbox';
import { strings } from '../../constants/localization';
import { formatDecimalsSpace, webAddressFormat } from '../../constants/utils';

class Step2 extends React.Component {
  constructor(props) {
    super(props);

    this.props.validator.initialize('phone', 'email', 'selectedCategories');

    this.state = {
      validator: this.props.validator,
    };
  }

  buildChangeSelectionHandler(key, validadFunc) {
    let changeHandler = this.buildValidationHandler(key, validadFunc);
    return (selectionArray) => {
      return changeHandler({
        target: { name: 'selectedCategories', value: selectionArray },
      });
    };
  }

  buildValidationHandler(key, validadFunc) {
    return (value) => {
      let valid = validadFunc(value);
      this.state.validator.setValid(key, valid);
      return valid;
    };
  }

  render() {
    if (this.props.currentStep !== 2) {
      return null;
    }

    return (
      <div className='step'>
        <div className='icon'>
          <i className='lnil lnil-house-heart' />
        </div>
        <div className='title'>{strings.locationFlow_step2_title}</div>
        <div className='description'>
          {strings.locationFlow_step2_description}
        </div>

        <div className='formWrapper'>
          <div className='step_row'>
            {/* Phone input  */}
            <div className='half_area'>
              <Input
                type='tel'
                name='phone'
                label={strings.locationFlow_step2_phone}
                required={true}
                value={this.props.phone}
                format={formatDecimalsSpace}
                maxLength='11'
                onChange={this.props.handleChange}
                validation={this.buildValidationHandler('phone', (value) => {
                  return (
                    Input.DefaultValidators.isNotEmpty(value) &&
                    Input.DefaultValidators.isDanishNumber(value) &&
                    Input.DefaultValidators.isExactly8Digits(value)
                  );
                })}
                cleanStep={this.props.cleanStep}
                stepIsValid={this.props.stepIsValid}
              />
            </div>
            {/* Email input  */}
            <div className='half_area'>
              <Input
                type='email'
                name='email'
                label={strings.locationFlow_step2_email}
                required={true}
                value={this.props.email}
                onChange={this.props.handleChange}
                validation={this.buildValidationHandler('email', (value) => {
                  return (
                    Input.DefaultValidators.isNotEmpty(value) &&
                    Input.DefaultValidators.isValidEmail(value)
                  );
                })}
                cleanStep={this.props.cleanStep}
                stepIsValid={this.props.stepIsValid}
              />
            </div>
          </div>

          {/* Website input  */}
          <div className='step_row'>
            <Input
              type='text'
              name='website'
              label={strings.locationFlow_step2_website}
              placeholder='https://'
              format={webAddressFormat}
              required={false}
              value={this.props.website}
              onChange={this.props.handleChange}
              cleanStep={this.props.cleanStep}
            />
          </div>

          {/* Category input  */}
          <div className='step_row'>
            <CustomSelect
              options={this.props.allCategories}
              isMulti
              value={this.props.selectedCategories}
              label={strings.locationFlow_step2_categories}
              required={true}
              onChange={this.props.handleChange}
              validation={this.buildValidationHandler(
                'selectedCategories',
                (selectionArray) => {
                  return selectionArray.length > 0;
                }
              )}
              errorMsg={strings.locationFlow_step2_error}
              cleanStep={this.props.cleanStep}
              stepIsValid={this.props.stepIsValid}
            />
          </div>

          {/* Category input  */}
          <div className='step_row centered'>
            <Checkbox
              content={strings.formatString(
                strings.locationFlow_step2_hide,
                this.props.chainBrand || ''
              )}
              checked={!this.props.hideLocation}
              handleClick={() => {
                this.props.handleChange({
                  target: {
                    name: 'hideLocation',
                    value: !this.props.hideLocation,
                  },
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Step2;
