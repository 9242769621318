// eslint-disable-next-line
import { currencyFormatDA } from '../../../constants/utils';
import { strings } from '../../../constants/localization';
import React, { useEffect, useState } from 'react';
import creditCardBg from '../../../resources/images/creditcard_back.svg';
import { Button, Label } from 'reactstrap';
import visaCard from '../../../resources/images/visa.svg';
import dankortCard from '../../../resources/images/dankort.svg';
import masterCard from '../../../resources/images/mastercard.svg';
import moment from 'moment';

type ConfirmPaymentProps = {
  onNextStep: () => void;
  setPaymentIsLoading: (value: boolean) => void;
  handlePreviousStep: () => void;
  setIsPaymentConfirmed: (value: boolean) => void;
  isSettlement?: boolean;
  newPaymentDetails: {
    monthlyPayment: number;
    apr: number;
    firstPaymentDate: string;
    lastPaymentDate: string;
    duration: number;
    extraPayment: number;
    newRemainingBalance: number;
  };

  clientData: {
    client: {
      firstName?: string;
      lastName?: string;
    };
    creditFacility: {
      remainingBalance?: number;
      creditFacilityInterestRate?: number;
    };
    creditCard: {
      expirationDate?: string;
      maskedCardNumber?: string;
      cardType?: string;
    };
  };
};

const ConfirmPayment: React.FC<ConfirmPaymentProps> = ({
  onNextStep,
  setPaymentIsLoading,
  newPaymentDetails,
  clientData,
  handlePreviousStep,
  setIsPaymentConfirmed,
  isSettlement,
}) => {
  const [isCardType, setIsCardType] = useState<string>('visa');

  useEffect(() => {
    setPaymentIsLoading(false);
    setIsCardType(clientData.creditCard.cardType);
  }, []);

  const cardType = () => {
    switch (isCardType) {
      case 'visa':
        return visaCard;
      case 'visa_dk':
        return dankortCard;
      case 'mastercard':
        return masterCard;
      default:
        return null;
    }
  };
  const cardTypeLogo = cardType();
  useEffect(() => {
    setPaymentIsLoading(false);
  }, []);

  const { remainingBalance } = clientData.creditFacility;

  return (
    <div className='step-content'>
      <h1 className='step-title'>
        {isSettlement
          ? strings.clientPortalSetledLoanTitle
          : strings.partialRedemptionConfirmPaymentTitle}
      </h1>
      <div className='subtitle'>
        <p className='card-subtitle'>
          {strings.formatString(
            isSettlement
              ? strings.clientPortalSetledLoanP1
              : strings.partialRedemptionConfirmPaymentSubtitle,
            <b>
              {currencyFormatDA(
                isSettlement
                  ? remainingBalance
                  : newPaymentDetails?.extraPayment,
                true,
                true
              )}
            </b>,
            <b>
              {currencyFormatDA(
                newPaymentDetails?.newRemainingBalance,
                true,
                true
              )}
            </b>
          )}
        </p>
      </div>
      <Label className='card-label center mb-5'>
        {strings.formatString(
          isSettlement
            ? strings.clientPortalSetledLoanP2
            : strings.partialRedemptionConfirmPaymentCardLabel,
          <b>
            {currencyFormatDA(
              isSettlement ? remainingBalance : newPaymentDetails?.extraPayment,
              true,
              true
            )}
          </b>
        )}
      </Label>

      <div className='creditcard-details mb-0'>
        <div className='credit-card-bg'>
          <img
            src={creditCardBg}
            alt='credit card'
            className='credit-card-bg-image'
          />
          <div className='credit-card'>
            {cardTypeLogo !== null && (
              <img
                src={cardTypeLogo}
                alt='credit card'
                className='credit-card-logo'
              />
            )}
            <div className='credit-card-number'>
              {clientData.creditCard.maskedCardNumber.replace(
                /(.{4})/g,
                '$1  '
              )}
            </div>
            <div className='credit-card-expires'>
              <span className='text'>{strings.creditCardExpires}</span>
              <span className='date'>
                {moment(clientData.creditCard.expirationDate, 'MM-YY').format(
                  'MM/YY'
                )}
              </span>
            </div>
            <div className='credit-card-name'>
              {`${clientData.client.firstName} ${clientData.client.lastName}`}
            </div>
          </div>
        </div>
      </div>
      <div className='step-actions'>
        <Button color='light' className='reject' onClick={handlePreviousStep}>
          {strings.partialRedemptionButtonBack}
        </Button>
        <Button
          color='blue'
          className='continue'
          onClick={() => {
            setIsPaymentConfirmed(true);
            onNextStep();
          }}
        >
          {strings.confirm}
        </Button>
      </div>
    </div>
  );
};

export default ConfirmPayment;
